import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'

import {
    name,
    MARKETING_SITE_URL,
    HYPE_PAGE_PREFIX,
    fetchCreator,
    setQueryParams,
} from '../dependencies'

const initialState = {
    isGiftFormValid: false,
    giftModeEnabled: false,
    senderFirstName: null,
    recipientFirstName: null,
    recipientEmail: null,
    giftNote: null,
    creatorUsername: null,
}

const giftSlice = createSlice({
    name,
    initialState,
    reducers: {
        enableGiftMode: (state: any) => {
            state.giftModeEnabled = true
        },
        disableGiftMode: (state: any) => {
            state.giftModeEnabled = false
        },
        setGiftFields: (state: any, action: any) => {
            const {
                isGiftFormValid,
                senderFirstName,
                recipientFirstName,
                recipientEmail,
                giftNote,
            } = action.payload

            state.senderFirstName = senderFirstName
            state.recipientFirstName = recipientFirstName
            state.recipientEmail = recipientEmail
            state.giftNote = giftNote
            state.isGiftFormValid = isGiftFormValid
        },
    },
    extraReducers: (builder: any) => {
        builder
        .addCase(fetchCreator.fulfilled, (state: any, { payload: creator }: any) => {
            const username = creator?.username

            state.creatorUsername = username
        })
        .addCase(setQueryParams, (state: any, { payload: queryParams }: any) => {
            const {
                gift,
            } = queryParams

            if (gift) {
                state.giftModeEnabled = true
            }
        })
    }
})

const {
    enableGiftMode,
    disableGiftMode,
    setGiftFields,
} = giftSlice.actions

// SELECTORS
const getGift = (state: any) => state[name]
const getRedeemUrl = createDraftSafeSelector(
    getGift,
    (gift: any) => `${MARKETING_SITE_URL}/${HYPE_PAGE_PREFIX}${gift?.creatorUsername}/redeem-gift`,
)
const giftModeEnabled = createDraftSafeSelector(
    getGift,
    (gift: any) => gift?.giftModeEnabled,
)

const senderFirstName = createDraftSafeSelector(
    getGift,
    (gift: any) => gift?.senderFirstName,
)

const recipientFirstName = createDraftSafeSelector(
    getGift,
    (gift: any) => gift?.recipientFirstName,
)

const recipientEmail = createDraftSafeSelector(
    getGift,
    (gift: any) => gift?.recipientEmail,
)

const giftNote = createDraftSafeSelector(
    getGift,
    (gift: any) => gift?.giftNote,
)

const isGiftFormValid = createDraftSafeSelector(
    getGift,
    (gift: any) => gift?.isGiftFormValid,
)

// GENERAL
const GiftInitialState = {
    [name]: initialState,
}

const GiftReducer = {
    [name]: giftSlice.reducer,
}

// EXPORTS
export default giftSlice.reducer

export {
    GiftInitialState as initialState,
    GiftReducer as reducer,

    enableGiftMode,
    disableGiftMode,
    setGiftFields,

    getRedeemUrl,
    giftModeEnabled,
    senderFirstName,
    recipientFirstName,
    recipientEmail,
    giftNote,
    isGiftFormValid,
}
