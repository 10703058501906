import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { PicoAnalytics } from 'packages/pico-analytics/src'
import { useCreator } from '@/entities/Creator'
import { checkoutSucceeded } from '@/entities/Cart/slice'
import { selectProductType } from '@/entities/Product/slice'
import { selectedCustomPrice, selectedProductOption } from '@/entities/ProductOptions'
import { useUser } from '../AdditonalInfo/dependencies'
import { useQueryParams } from '../SuccessMessage/dependencies'

const metricsEndpoint = process.env.METRICS_ENDPOINT || 'https://metrics.pico.tools/extract'
// const segment_write_key = process.env.SEGMENT_WRITE_KEY

const useAnalytics = () => {
    const [ paymentEventFired, setPaymentEventFired ] = useState(false)
    const creator = useCreator()
    const user = useUser()
    const { conversationId } = useQueryParams()
    const _checkoutSucceeded = useSelector(checkoutSucceeded)
    const _selectedCustomPrice = useSelector(selectedCustomPrice)
    const _selectedProductOption = useSelector(selectedProductOption)

    const _productType = useSelector(selectProductType)

    const analyticInstance = PicoAnalytics({
        metrics_endpoint: metricsEndpoint,
        context: 'pay',
    })

    useEffect(() => {
        if (_checkoutSucceeded && !paymentEventFired) {
            setPaymentEventFired(true)
            track({
                eventType: 'user_payment',
                eventData: {
                    user_state_id: user?.id,
                    product_type: _productType,
                    custom_price: _selectedCustomPrice ? String(_selectedCustomPrice) : undefined,
                    currency: _selectedProductOption?.currency,
                    product_option_id: _selectedProductOption.id,
                    conversation_id: conversationId
                }
            })
            
        }
    }, [_checkoutSucceeded, _productType, _selectedCustomPrice, _selectedProductOption, user, paymentEventFired])

    const track = useCallback(({ eventType, eventData }: any) => {

        if (!creator.id) {
            return
        }

        if (!analyticInstance) {
            return null
        }
        // @ts-ignore
        analyticInstance.plugins['analytics-plugin-pico'].initPublisher(creator.id)

        analyticInstance?.track(eventType, { ...eventData })

    }, [
        analyticInstance, creator
    ])

    return { track, analyticInstance }
}

export default useAnalytics
