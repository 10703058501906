import { useSelector } from 'react-redux'

import { isAuthorized } from './slice'

const useAuthorization = () => {
    const _isAuthorized = useSelector(isAuthorized)

    return _isAuthorized
}

export default useAuthorization
