import { customFieldsEditedValues } from './index'
import { createAsyncSaga, name, endpoints } from '../dependencies'
import { select } from 'redux-saga/effects'

const saveCustomFields = createAsyncSaga(
    `${name}/saveCustomFields`,
    function* ({
        payload: {
            publisherId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            const customProperties = yield select(customFieldsEditedValues)

            const response = yield request({
                method: 'put',
                baseURL: endpoints.PICO_API_URL(),
                url: `${endpoints.ME()}/properties`,
                headers: {
                    publisherid: publisherId,
                },
                data: {
                    custom_properties: customProperties
                }
            })

            yield fulfilled(response)
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default saveCustomFields
