import { useSelector } from 'react-redux'

import { exchangeClaimInProgress, useRefreshTokenInProgress } from '@/entities/Authorization'
import { getUserInProgress } from '@/entities/User'

import { useUser } from '../dependencies'
import { oneTimeCodeSent } from '@/entities/Authorization/slice'

const useShowAuthForm = () => {
    const user = useUser()

    const _getUserInProgress = useSelector(getUserInProgress)
    const _exchangeClaimInProgress = useSelector(exchangeClaimInProgress)
    const _useRefreshTokenInProgress = useSelector(useRefreshTokenInProgress)
    const _oneTimeCodeSent = useSelector(oneTimeCodeSent)
    const showAuthForm = !_exchangeClaimInProgress && !_useRefreshTokenInProgress && !_getUserInProgress && !user?.id || _oneTimeCodeSent

    return showAuthForm
}

export default useShowAuthForm
