import {
    initialState,
    reducer,
    takes,
    getMe,
    getUserInProgress,
    user,
} from './slice'
import useUser from './useUser'

export default {
    initialState,
    reducer,
    takes,
}

export {
    getMe,
    useUser,
    getUserInProgress,
    user,
}
