import {
    initialState,
    reducer,
    takes,
    creator,
    fetchCreator,
    contributionType,
} from './slice'

import useCreator from './useCreator'

export default {
    initialState,
    reducer,
    takes,
}

export {
    useCreator,
    creator,
    fetchCreator,
    contributionType,
}
