import ErrorBoundary from '@/components/ErrorBoundry'

const SkeletonComponent = () => (
    <div className='w-100 h-100 flex items-center justify-center'>
        <div className='w-[360px]'>
            <div role='status' className='max-w-sm animate-pulse'>
                <div className='h-2.5 bg-gray-400 rounded-full w-48 mb-4'></div>
                <div className='h-2 bg-gray-400 rounded-full max-w-[360px] mb-2.5'></div>
                <div className='h-2 bg-gray-400 rounded-full mb-2.5'></div>
                <div className='h-2 bg-gray-400 rounded-full max-w-[330px] mb-2.5'></div>
                <div className='h-2 bg-gray-400 rounded-full max-w-[300px] mb-2.5'></div>
                <div className='h-2 bg-gray-400 rounded-full max-w-[360px]'></div>
                <span className='sr-only'>Loading...</span>
            </div>
        </div>
    </div>
)

const SkeletonWrapper = ({
    children = null,
    showContents = false,
} : any) => {
    return (
        <ErrorBoundary>
            <div className={`${showContents && 'hidden'}`}>
                <SkeletonComponent />
            </div>
            <div className={`${!showContents && 'hidden'}`}>
                {children}
            </div>
        </ErrorBoundary>
    )
}

export default SkeletonWrapper
