import React from 'react'
import { Field } from 'react-final-form'

const Text = (
    { id, label, required, description, options, type = 'text' }: any
) => {
    return (
        <>
            <div className='mb-3'>
                <label className='label' htmlFor={id}>{label}{required && '*'}</label>
                <p className="text-xs select-none font-normal text-gray-600  ">{description}</p>
            </div>
            <Field
                type={type}
                name={id}
            >
                {({ input, meta }) => (
                    <>
                        <input {...input} type="text" className={`input ${meta.error && meta.touched && 'input-error'}`} placeholder='Enter your name' />
                        {meta.error && meta.touched && <span className='input-error-description'>{meta.error}</span>}
                    </>
                )}
            </Field>
        </>
    )
}



export default Text
