import { useGift } from '@/entities/Gift'
import { formattedTrialValue, formattedValue, isFreeTrial, isTrial, formattedTrialDurationCta, formattedSwitchProration, isSwitchableNow } from '@/entities/ProductOptions'
import { useSelector } from 'react-redux'

import { useCart, selectedProductOption, usePaymentHistory } from '../../dependencies'

const PayButton = () => {
    const { giftModeEnabled } = useGift()
    const { executePurchase, enablePayButton, selectedSwitchNow } = useCart()
    const { currentProductOptionId, currentStatus, nextPeriodsProductOptionId } = usePaymentHistory()
    const _selectedProductOption = useSelector(selectedProductOption)

    const _isTrial = useSelector(isTrial)
    const _isFreeTrial = useSelector(isFreeTrial)
    const _isSwitchableNow = useSelector(isSwitchableNow)
    const _formattedValue = useSelector(formattedValue)
    const _formattedTrialValue = useSelector(formattedTrialValue)
    const _formattedTrialDurationCta = useSelector(formattedTrialDurationCta)
    const _formattedSwitchProration = useSelector(formattedSwitchProration)

    const hasCancelledSubscription = currentStatus === 'CANCELED'
    const willBeSubscribed = nextPeriodsProductOptionId === _selectedProductOption?.id
    const isAlreadySubscribed = currentProductOptionId === _selectedProductOption?.id && !hasCancelledSubscription
    const hasNoSubscription = !currentProductOptionId || hasCancelledSubscription
    const isSwitching = !hasNoSubscription && (currentProductOptionId !== _selectedProductOption?.id)
    const isTrialIneligible = _isTrial && hasCancelledSubscription

    let payWording = ''
    if (giftModeEnabled) {
        payWording = `Pay ${_formattedValue}`
    } else if (isAlreadySubscribed) {
        payWording = 'Already subscribed'
    } else if (hasNoSubscription) {
        if (_isTrial && !isTrialIneligible) {
            payWording = `${_formattedTrialValue}`
            if (_isFreeTrial) {
                payWording = `Start ${_formattedTrialDurationCta} free trial`
            } else {
                payWording = `Start ${_formattedTrialDurationCta} trial for ${_formattedTrialValue}`
            }
        } else {
            payWording = `Pay ${_formattedValue}`
        }
    } else if (isSwitching) {
        if (willBeSubscribed) {
            payWording = `Will be switching soon`
        } else {
            if (selectedSwitchNow) {
                if (!_isSwitchableNow) {
                    payWording = 'Unable to switch now'
                } else {
                    payWording = `Switch now for ${_formattedSwitchProration?.prorateAmount}`
                }
            } else {
                payWording = `Switch at the end of your cycle for ${_formattedValue}`
            }
        }
    } else {
        payWording = 'Choose an option'
    }

    return (
        <button
            disabled={!enablePayButton}
            className='btn bg-violet-500 my-3 disabled:bg-violet-300 text-white w-full block'
            onClick={executePurchase}
        >
            {payWording}
        </button>
    )
}

export default PayButton
