import { useClaim } from '@/entities/Authorization'
import { useCreator } from '@/entities/Creator'
import { useCustomFields } from '@/entities/CustomFields'
import { useFeatureFlags } from '@/entities/FeatureFlags'
import { useNewsletters } from '@/entities/Newsletters'
import { usePaymentHistory } from '@/entities/PaymentHistory'
import { usePaymentMethods } from '@/entities/PaymentMethods'
import { useProduct } from '@/entities/Product'
import { useProductOptions } from '@/entities/ProductOptions'
import { useQueryParams } from '@/entities/QueryParams'
import { useRefreshTokens } from '@/entities/RefreshTokens'
import { useUser } from '@/entities/User'

import Checkout from './Checkout'

function Index() {
    useQueryParams()

    useProduct(true)

    useCreator(true)

    useClaim(true)

    useRefreshTokens()

    useCustomFields(true)

    useFeatureFlags(true)

    useUser(true)

    useProductOptions(true)

    usePaymentMethods(true)

    useNewsletters(true)

    usePaymentHistory(true)

    return (
        <Checkout />
    )
}

export default Index
