import createAsyncSaga from '@/utils/createAsyncSaga'
import * as endpoints from '@piconetworks/pkg-endpoints'

import { creator } from "../Creator"

const name = 'featureFlags'

const constants = {
    PayAppRc: 'pay-app-rc',
}

export {
    name,
    createAsyncSaga,
    endpoints,
    creator,
    constants,
}