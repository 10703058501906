import { createAsyncSaga, name, endpoints } from '../dependencies'

const updatePaymentMethod = createAsyncSaga(
    `${name}/updatePaymentMethod`,
    function* ({
        payload: {
            publisherId = null,
            sourceId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId not provided')
            }
            if (!sourceId) {
                throw new Error('sourceId not provided')
            }

            const response = yield request({
                method: 'put',
                baseURL: endpoints.PICO_API_URL(),
                url: endpoints.ME_PAYMENT(),
                headers: {
                    publisherid: publisherId,
                },
                data: {
                    source_id: sourceId,
                },
            })

            yield fulfilled({
                ...response,
                payment_method_default: sourceId,
            })
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default updatePaymentMethod
