import { useSelector } from 'react-redux'
import { isTrial, formattedValue } from '@/entities/ProductOptions'
import useCheckoutMode from '@/entities/Cart/useCheckoutMode'

const TrialAfter = () => {
    const _isTrial = useSelector(isTrial)
    const _formattedValue = useSelector(formattedValue)
    const {
        checkoutMode,
        checkoutModes
    } = useCheckoutMode()

    if (!_isTrial) {
        return <></>
    }

    if (checkoutModes.switch === checkoutMode) {
        return <></>
    }

    return (
        <>
            <hr className='mt-0 w-full' />
            <div className='flex justify-between flex-wrap mt-5'>
                <h2 className="font-medium text-sm text-black text-opacity-70 font-antartica">Total after trial</h2>
                <h1 className='font-medium text-base font-antartica'>{_formattedValue}</h1>
            </div>
        </>
    )
}

export default TrialAfter
