import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { isAuthorized } from '../Authorization'

import {
    appStartFired,
    _appStart,
    // userAuthenicated,
    // userUnauthenicated,
} from './slice'

const useUserAuthenicated = () => {
    const _isAuthorized = useSelector(isAuthorized)
    // useEffect(() => {
    //     if (_isAuthorized) {
    //         userAuthenicated()
    //     } else {
    //         userUnauthenicated()
    //     }
    // }, [_isAuthorized])
}

const useAppStart = () => {
    const dispatch = useDispatch()

    const appStart = useCallback(() => {        
        // dispatch(_appStart())
    }, [])

    return appStart
}

const useEvents = () => {
    const appStart = useAppStart()
    // useUserAuthenicated()
 
    return {
        appStart,
    }
}

export default useEvents
