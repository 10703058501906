import { createAsyncSaga, name, endpoints } from '../dependencies'

const fetchCreator = createAsyncSaga(
    `${name}/fetchCreator`,
    function* ({
        payload: {
            creatorId = null
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!creatorId) {
                throw new Error('creatorId not provided')
            }

            const response = yield request({
                method: 'get',
                baseURL: endpoints.PICO_API_URL(),
                url: `/client/query/${creatorId}`,
                headers: {
                    publisherid: creatorId,
                }
            })

            yield fulfilled(response)
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default fetchCreator
