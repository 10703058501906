import {
    initialState,
    reducer,
    takes,
    fetchPaymentMethods,
    selectAll,
    selectEntities,
    getSelectedPaymentMethod,
    setSelectedPaymentMethod,
    updatePaymentMethod,
    getDefaultPaymentMethod,
} from './slice'

import usePaymentMethods from './usePaymentMethods'

export default {
    initialState,
    reducer,
    takes,
}

export {
    usePaymentMethods,
    fetchPaymentMethods,
    updatePaymentMethod,
    getSelectedPaymentMethod,
    setSelectedPaymentMethod,
    selectAll,
    selectEntities,
    getDefaultPaymentMethod,
}
