import createAsyncSaga from '@/utils/createAsyncSaga'
import * as endpoints from '@piconetworks/pkg-endpoints'

import { creatorId } from '@/entities/Product'

const name = 'creator'

export {
    name,
    createAsyncSaga,
    endpoints,
    creatorId,
}