import {
    useStripe,
    useElements,
    CardElement,
} from '@stripe/react-stripe-js'
import { useCallback, useState, useEffect } from 'react'


const CreditCardEntry = ({
    inline = false,
    show = true,
    onCreateToken = (data: any) => { },
    updateCardValidation = (data: any) => { },
}) => {
    const stripe = useStripe()
    const elements = useElements()
    const [error, setError] = useState<any>(null)

    useEffect(() => {
        // Clear elements values when unmounted
        if (elements === null) {
            return
        }

        if (show) {
            elements?.getElement(CardElement)?.focus()
        }

        if (!show) {
            elements?.getElement(CardElement)?.clear()
        }
    }, [elements, show])

    const onCardElementChange = useCallback((event: any) => {
        if (event.complete) {
            // enable payment button
            updateCardValidation({ isCardValid: true })
            if (inline) {
                handleSubmit()
            }
        } else if (event.error) {
            // show validation to customer
            updateCardValidation({ isCardValid: false })
            setError(event.error)
        } else {
            updateCardValidation({ isCardValid: false })
            setError(null)
        }
    }, [elements, inline])


    const handleSubmit = useCallback(async (event?: any) => {
        try {
            event?.preventDefault()

            if (elements === null) {
                return
            }

            if (stripe === null) {
                return
            }

            // @ts-ignore
            const data: any | null = await stripe?.createToken(elements?.getElement(CardElement))?.catch(setError)

            if (data?.error?.message) {
                // stripe error object
                throw (data?.error)
            }

            onCreateToken(data)
        } catch (error: any) {
            if (error?.message) {
                setError(error)
            }
        }
    }, [
        stripe,
        onCreateToken,
        elements,
    ])

    const stripeStyles = {
        style: {
            base: {
                color: "#120A20",
                fontSize: "16px",
                '::placeholder': {
                    color: '#CCCBCE'
                }
            }
        }
    }

    return (
        <form onSubmit={handleSubmit} id='credit-card-entry'>
            <CardElement
                className={`card-input ${error && 'error-input'}`}
                onChange={onCardElementChange}
                options={{
                    ...stripeStyles,
                }}
            />
            {error && (<p className='text-red-500 text-sm py-2.5'>{error?.message}</p>)}
        </form>
    )
}


export default CreditCardEntry
