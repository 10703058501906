import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'

import { deauthorize, name } from '../dependencies'
import fetchPaymentHistory from './fetchPaymentHistory'

const initialState = {
    fetchPaymentHistorySuccess: null,
    fetchPaymentHistoryError: null,
    fetchPaymentHistoryInProgress: false,
    current: null,
    history: null,
}

const paymentHistorySlice = createSlice({
    name,
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchPaymentHistory.pending]: (state: any, { payload }: any) => {
            state.fetchPaymentHistoryInProgress = true
            state.fetchPaymentHistoryError = initialState.fetchPaymentHistoryError
            state.fetchPaymentHistorySuccess = initialState.fetchPaymentHistorySuccess
            state.current = initialState.current
            state.history = initialState.history
        },
        [fetchPaymentHistory.fulfilled]: (state: any, { payload: paymentHistory }: any) => {
            state.fetchPaymentHistoryInProgress = false
            state.fetchPaymentHistoryError = null
            state.fetchPaymentHistorySuccess = true
            state.current = paymentHistory?.current
            state.history = paymentHistory?.history

        },
        [fetchPaymentHistory.rejected]: (state: any, { payload: error }: any) => {
            state.fetchPaymentHistoryInProgress = false
            state.fetchPaymentHistoryError = error
            state.fetchPaymentHistorySuccess = false
        },
        [deauthorize]: (state: any, { payload }: any) => {
            state.fetchPaymentHistoryInProgress = initialState.fetchPaymentHistoryInProgress
            state.fetchPaymentHistoryError = initialState.fetchPaymentHistoryError
            state.fetchPaymentHistorySuccess = initialState.fetchPaymentHistorySuccess
            state.current = initialState.current
            state.history = initialState.history
        },
    },
})

// SELECTORS
const getPaymentHistory = (state: any) => state[name]

const paymentHistory = createDraftSafeSelector(
    getPaymentHistory,
    (PaymentHistory: any) => PaymentHistory,
)

const fetchPaymentHistorySuccess = createDraftSafeSelector(
    paymentHistory,
    (PaymentHistory: any) => PaymentHistory?.fetchPaymentHistorySuccess,
)

const fetchPaymentHistoryError = createDraftSafeSelector(
    paymentHistory,
    (PaymentHistory: any) => PaymentHistory?.fetchPaymentHistoryError,
)

const fetchPaymentHistoryInProgress = createDraftSafeSelector(
    paymentHistory,
    (PaymentHistory: any) => PaymentHistory?.fetchPaymentHistoryInProgress,
)

const current = createDraftSafeSelector(
    paymentHistory,
    (PaymentHistory: any) => PaymentHistory?.current,
)

const history = createDraftSafeSelector(
    paymentHistory,
    (PaymentHistory: any) => PaymentHistory?.history,
)

const currentProductOptionId = createDraftSafeSelector(
    current,
    (_current: any) => _current?.product_option_id,
)

const currentStatus = createDraftSafeSelector(
    current,
    (_current: any) => _current?.status,
)

const nextPeriodsProductOptionId = createDraftSafeSelector(
    history,
    (_history: any) => _history?.next?.product_option_id,
)

const isNewProductOptionNextPeriod = createDraftSafeSelector(
    [currentProductOptionId, nextPeriodsProductOptionId],
    (
        _currentProductOptionId: any,
        _nextPeriodsProductOptionId: any,
    ) => _currentProductOptionId !== _nextPeriodsProductOptionId,
)

// GENERAL
const PaymentHistoryInitialState = {
    [name]: initialState,
}

const PaymentHistoryReducer = {
    [name]: paymentHistorySlice.reducer,
}

const takes = [
    ...fetchPaymentHistory.takes,
]

const PaymentHistoryActions = {
    ...paymentHistorySlice.actions
}

// EXPORTS
export default paymentHistorySlice.reducer

export {
    PaymentHistoryInitialState as initialState,
    PaymentHistoryReducer as reducer,
    PaymentHistoryActions as actions,

    takes,
    fetchPaymentHistory,
    paymentHistory,

    fetchPaymentHistorySuccess,
    fetchPaymentHistoryError,
    fetchPaymentHistoryInProgress,
    current,
    history,
    currentProductOptionId,
    currentStatus,
    isNewProductOptionNextPeriod,
    nextPeriodsProductOptionId,
}
