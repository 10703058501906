import { call, put, take } from 'redux-saga/effects'
import {
    createAsyncSaga,
    name,
    saveCustomFields,
    saveNewsletters,
    updatePaymentMethod,
} from '../dependencies'

import purchaseProduct from './purchaseProduct'

const checkoutDefault = createAsyncSaga(
    `${name}/checkoutDefault`,
    function* ({
        payload: {
            productOptionId = null,
            publisherId = null,
            customPrice = null,
            sourceId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            // update default payment method
            yield put(yield call(updatePaymentMethod, {
                sourceId,
                publisherId,
            }))

            yield take(updatePaymentMethod.pending)
            const updateDefaultPaymentResult = yield take([updatePaymentMethod.fulfilled, updatePaymentMethod.rejected])

            if (updateDefaultPaymentResult.type === updatePaymentMethod.rejected) {
                throw new Error('Update default payment method failed.')
            }

            // make purchase
            yield put(yield call(purchaseProduct, {
                productOptionId,
                publisherId,
                customPrice,
            }))

            yield take(purchaseProduct.pending)
            const purchaseResult = yield take([purchaseProduct.fulfilled, purchaseProduct.rejected])

            if (purchaseResult.type === purchaseProduct.rejected) {
                throw new Error('There was an issue trying to complete your purchase. Please try again or contact support if this persists.')
            }

            // update custom fields
            yield put(yield call(saveCustomFields, {
                publisherId,
            }))

            yield take(saveCustomFields.pending)
            yield take([saveCustomFields.fulfilled, saveCustomFields.rejected])


            // update newsletters
            yield put(yield call(saveNewsletters, {
                publisherId,
            }))

            yield take(saveNewsletters.pending)
            yield take([saveNewsletters.fulfilled, saveNewsletters.rejected])

            
            // update credit cards

            yield fulfilled()

            // redirect
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default checkoutDefault
