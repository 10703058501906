import { useEffect, useRef, useState } from 'react'
import { isEmpty, useCreator, useUser, intlTelInput } from '../../dependencies'
import useAuthForm, { FormTypes } from '../../hooks/useAuthForm'
import Otp from './Otp'
import SocialLoginOptions from './SocialLoginOptions'
import 'intl-tel-input/build/css/intlTelInput.css'
declare global {
    interface Window {
        iti: any;
    }
}

const EmailPasswordLogin = () => {
    const creator = useCreator()
    const phoneInputRef = useRef(null)
    const phoneContainer = useRef(null)
    const user = useUser()

    const [showComponent, setShowComponent] = useState(false)

    const {
        type,
        setType,
        onSubmit,
        oneTimeCodeInProgress,
        oneTimeCodeSent,
        oneTimeCodeConfirmed
    } = useAuthForm()

    useEffect(() => {
        if (!isEmpty(creator)) {
            setShowComponent(true)
        } else {
            setShowComponent(false)
        }

        if (oneTimeCodeSent) {
            setShowComponent(false)
        }

        if (oneTimeCodeConfirmed) {
            setShowComponent(false)
        }

        if (!isEmpty(user)) {
            setShowComponent(false)
        }
    }, [creator, user, oneTimeCodeSent, oneTimeCodeConfirmed])

    const hasEmailTab = ['linkedin', 'twitter', 'google', 'github', 'facebook', 'email'].some((value) => creator?.authentication_methods?.includes(value))
    const hasPhoneTab = creator?.authentication_methods?.includes('sms')

    useEffect(() => {
        if (phoneInputRef?.current && phoneContainer?.current) {
            const iti = intlTelInput(phoneInputRef.current, {
                onlyCountries: ["us", "ca"],
                dropdownContainer: phoneContainer?.current,
                preferredCountries: ['us'],
                utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@18.1.1/build/js/utils.js",
            })

            window.iti = iti
        }
    }, [phoneInputRef.current, phoneContainer.current, hasPhoneTab])


    return (
        <>
            {showComponent && <div>
                <form id='login' onSubmit={onSubmit} className=''>
                    <div>
                        
                        {hasEmailTab && hasPhoneTab && (
                            <div className='grid grid-cols-2 mb-5'>
                                <div
                                    className={`tab ${type === FormTypes.sms ? 'active' : 'inactive'}`}
                                    onClick={() => setType(FormTypes.sms)}
                                >
                                    <svg className='mr-2' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 24" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M21.25 11.5C21.25 16.1944 17.2206 20 12.25 20C11.7192 20 11.1991 19.9566 10.6935 19.8734L6.92276 21.9337C6.58957 22.1158 6.18301 21.8747 6.18301 21.495V17.7784C4.38069 16.2241 3.25 13.9867 3.25 11.5C3.25 6.80558 7.27944 3 12.25 3C17.2206 3 21.25 6.80558 21.25 11.5ZM12.25 13C13.0784 13 13.75 12.3284 13.75 11.5C13.75 10.6716 13.0784 10 12.25 10C11.4216 10 10.75 10.6716 10.75 11.5C10.75 12.3284 11.4216 13 12.25 13ZM18.25 11.5C18.25 12.3284 17.5784 13 16.75 13C15.9216 13 15.25 12.3284 15.25 11.5C15.25 10.6716 15.9216 10 16.75 10C17.5784 10 18.25 10.6716 18.25 11.5ZM7.75 13C8.57843 13 9.25 12.3284 9.25 11.5C9.25 10.6716 8.57843 10 7.75 10C6.92157 10 6.25 10.6716 6.25 11.5C6.25 12.3284 6.92157 13 7.75 13Z" fill="#834EFF" />
                                    </svg>

                                    Phone
                                </div>
                                <div
                                    className={`tab ${type === FormTypes.email ? 'active' : 'inactive'}`}
                                    onClick={() => setType(FormTypes.email)}
                                >
                                    <svg className='mr-2' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 12" fill="none">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M4.75 0.75C2.54086 0.75 0.75 2.54086 0.75 4.75V8C0.75 10.2091 2.54086 12 4.75 12H11.75C13.9591 12 15.75 10.2091 15.75 8V4.75C15.75 2.54086 13.9591 0.75 11.75 0.75H4.75ZM2.48498 3.16155C2.38545 3.09312 2.25 3.16438 2.25 3.28515V8.5C2.25 9.60457 3.14543 10.5 4.25 10.5H12.25C13.3546 10.5 14.25 9.60457 14.25 8.5V3.28515C14.25 3.16438 14.1145 3.09312 14.015 3.16155L9.38306 6.34602C8.70055 6.81524 7.79945 6.81525 7.11694 6.34602L2.48498 3.16155Z" fill="#120A20" />
                                    </svg>
                                    Email
                                </div>
                            </div>
                        )}

                        <div className={type !== FormTypes.email ? 'hidden' : ''}>
                            <input type="input" name="email" id="email" className='input' placeholder='Email Address' />
                        </div>
                        <div ref={phoneContainer} className={type !== FormTypes.sms ? 'hidden' : 'phone_input relative'}>
                            <input type='tel' ref={phoneInputRef} className='input' name='phone_number' form='login' />
                        </div>
                    </div>
                    <button type='submit' disabled={oneTimeCodeInProgress} className='btn mt-4 w-full bg-violet-500 disabled:bg-violet-300 focus:ring-violet-300 focus:border-violet-200 text-white' form='login'>
                        <div className='flex items-center justify-center'>
                            {oneTimeCodeInProgress && (
                                <svg aria-hidden="true" className="inline w-5 h-5 mr-2 text-gray-200 animate-spin   fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                </svg>
                            )}
                            <span>
                                Continue to checkout
                            </span>
                        </div>
                    </button>
                </form>
                {creator?.authentication_methods?.filter?.((item: any) => !((item === 'email') || (item === 'sms'))).length !== 0 &&
                    (
                        <>
                            <div className="inline-flex relative items-center justify-center w-full">
                                <hr className="w-full h-px my-5 bg-gray-500 border-0" />
                                <span className="absolute px-3 font-bold text-sm text-gray-700 -translate-x-1/2 bg-white left-1/2">OR USE</span>
                            </div>
                            <SocialLoginOptions />
                        </>
                    )
                }

            </div>}
            <div className="text-xs text-gray-700 mt-3">
                {type === FormTypes.sms ? (
                    <>By clicking "Continue," you agree to <a href="https://hype.co/terms/user-agreement" className="underline" target='_blank' rel='noopener noreferrer'>Hype&rsquo;s Terms</a>{' '}
                    and acknowledge the <a href="https://hype.co/terms/privacy-policy" className="underline" target='_blank' rel='noopener noreferrer'>Privacy Policy</a>.{' '}
                    You also agree that {creator?.name} may contact the phone number provided with marketing materials by text message or phone (including via an automated system). 
                    Consent is not a condition of purchase. Message & data rates may apply.
                    </>
                ) : (
                    <>By signing up, you agree to{' '}
                    <a href="https://hype.co/terms/user-agreement" className="underline" target='_blank' rel='noopener noreferrer'>Hype&rsquo;s Terms</a>
                    {' '} and acknowledge the <a href="https://hype.co/terms/privacy-policy" className="underline" target='_blank' rel='noopener noreferrer'>Privacy Policy</a>.
                    {' '} You also agree that {creator?.name} may send you marketing emails.</>
                )}
            </div>
            <Otp />
        </>
    )
}

export default EmailPasswordLogin