import { createAsyncSaga, name, endpoints } from '../dependencies'
import logger from '@/lib/logger'
import { addError } from '@/lib/rum'

const fetchPaymentHistory = createAsyncSaga(
    `${name}/fetchPaymentHistory`,
    function* ({
        payload: {
            publisherId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId not provided')
            }

            const response = yield request({
                method: 'get',
                baseURL: endpoints.PICO_API_URL(),
                url: `${endpoints.PURCHASE_HISTORY()}`,
                headers: {
                    publisherid: publisherId
                },
            })

            yield fulfilled(response)
        } catch (error) {
            logger.log(`${name}/fetchPaymentHistory`, {payload}, error)
            addError(error, {payload})
            yield rejected({
                error: error?.message,
            })
        }
    }
)



export default fetchPaymentHistory
