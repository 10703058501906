import React, { useCallback, useState } from 'react'
import { Field, useForm } from 'react-final-form'

const Component = ({ input, options, meta }: any) => {
    const [open, setOpen] = useState(false)
    const { mutators } = useForm()

    const onSelect = useCallback((value: any) => {
        mutators?.setValue(input?.name, value)
        setOpen(false)
    }, [mutators, input?.name])

    const onClose = useCallback((e: any) => {
        if (e?.relatedTarget?.id !== 'dropdown') {
            setOpen(false)
        }
    }, [open])

    return (
        <div className='relative' tabIndex={0} >
            <input type='hidden' {...input} />
            <button
                id="dropdownDefaultButton"
                onBlur={onClose}
                onClick={() => setOpen(!open)}
                className={`
                    w-full
                    text-gray-600
                    bg-white
                    focus:outline-none
                    focus:border-violet-500
                    font-medium
                    rounded-lg
                    text-sm
                    px-4
                    py-2.5
                    text-center
                    inline-flex
                    items-center
                    justify-between
                    border-1
                    border-gray-500 
                    ${meta.error && open && ' text-red-600 border-red-500 focus:border-red-500'}
                `}
                type="button"
            >
                {input.value || 'Select'}
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path fillRule="evenodd" clipRule="evenodd" d="M4.41063 6.9106C4.73594 6.5853 5.25985 6.5853 5.58515 6.9106L9.99999 11.3241L14.4148 6.9106C14.7401 6.5853 15.264 6.5853 15.5893 6.9106C15.9146 7.23591 15.9146 7.76814 15.5893 8.09345L10.589 13.0912C10.2637 13.4165 9.73628 13.4165 9.41098 13.0912L4.41063 8.09345C4.08533 7.76814 4.08533 7.23591 4.41063 6.9106Z" fill="#120A20" />
                </svg>
            </button>
            {meta.error && open && <span className='input-error-description'>{meta.error}</span>}

            <div tabIndex={0} id="dropdown" className={`cursor-pointer z-10 mt-2 w-full absolute ${open ? 'block' : 'hidden'} bg-white divide-y border-1 border-gray-500 rounded-lg shadow`}>
                <ul className="w-full py-1 text-sm text-gray-700  " aria-labelledby="dropdownDefaultButton">
                    {options?.map((option: any) => {
                        return (
                            <li>
                                <span onClick={() => onSelect(option.value)} className="block px-4 py-2 text-picoblack hover:bg-violet-500 hover:text-white">{option.label}</span>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}


const Dropdown = ({ id, label, required, description, options }: any) => {
    return (
        <>
            <div className='mb-3'>
                <h2 className='label'>{label}{required && '*'}</h2>
                <p className="text-xs select-none font-normal text-gray-600  ">{description}</p>
            </div>
            <Field
                options={options}
                name={id}
                component={Component}
            />
        </>
    )
}

export default Dropdown