import {
    initialState,
    reducer,
    productOptionId,
    claim,
    setQueryParams,
    customPrice,
    conversationId
} from './slice'

import useQueryParams from './useQueryParams'

export default {
    initialState,
    reducer,
}

export {
    useQueryParams,
    productOptionId,
    claim,
    setQueryParams,
    customPrice,
    conversationId
}
