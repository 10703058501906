import {
    createDraftSafeSelector,
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit'

import { name, deauthorize } from '../dependencies'
import fetchNewsletters from './fetchNewsletters'
import fetchUserNewsletters from './fetchUserNewsletters'
import saveNewsletters from './saveNewsletters'

const newslettersAdapter = createEntityAdapter({
    selectId: (newsletter: any) => newsletter?.id,
    sortComparer: (a: any, b: any) => parseInt(a.newsletter_order) - parseInt(b.newsletter_order),
})

const initialState = newslettersAdapter.getInitialState({
    fetchNewslettersError: null,
    fetchNewslettersInProgress: false,

    newslettersEditedValues: [],
    isNewslettersFormValid: false,
})

const newsletterSlice = createSlice({
    name,
    initialState,
    reducers: {
        updateEditedNewsletters: (state: any, { payload: {
            newslettersEditedValues,
            isNewslettersFormValid,
        } }: any) => {
            state.newslettersEditedValues = newslettersEditedValues
            state.isNewslettersFormValid = isNewslettersFormValid
        },
    },
    extraReducers: {
        [fetchNewsletters.pending]: (state: any, { payload }: any) => {
            state.fetchNewslettersInProgress = true
            state.fetchNewslettersError = null
        },
        [fetchNewsletters.fulfilled]: (state: any, { payload }: any) => {
            newslettersAdapter.setAll(state, payload || [])
            state.fetchNewslettersInProgress = false
            state.fetchNewslettersError = null
        },
        [fetchNewsletters.rejected]: (state: any, { payload: error }: any) => {
            state.fetchNewslettersInProgress = false
            state.fetchNewslettersError = error
            newslettersAdapter.removeAll(state)
        },
        [fetchUserNewsletters.pending]: (state: any, { payload }: any) => {
            state.fetchNewslettersInProgress = true
            state.fetchNewslettersError = null
        },
        [fetchUserNewsletters.fulfilled]: (state: any, { payload }: any) => {
            const currentState = JSON.parse(JSON.stringify(state))
            const newslettersEditedValues = currentState.newslettersEditedValues
            
            const userNewsletters = payload?.map((userNewsletter: any) => {
                return ({ id: userNewsletter?.id, changes: { subscribed: userNewsletter?.subscribed || newslettersEditedValues?.includes(userNewsletter?.id) } })
            })

            newslettersAdapter.updateMany(state, userNewsletters || [])
            state.fetchNewslettersInProgress = false
            state.fetchNewslettersError = null
        },
        [fetchUserNewsletters.rejected]: (state: any, { payload: error }: any) => {
            state.fetchNewslettersInProgress = false
            state.fetchNewslettersError = error
            newslettersAdapter.removeAll(state)
        },
        [deauthorize]: (state: any, { payload }: any) => {
            state.isNewslettersFormValid = initialState.isNewslettersFormValid
            state.newslettersEditedValues = initialState.newslettersEditedValues
            state.fetchNewslettersInProgress = initialState.fetchNewslettersInProgress
            state.fetchNewslettersError = initialState.fetchNewslettersError
        }
    },
})

const {
    updateEditedNewsletters,
} = newsletterSlice.actions

// SELECTORS
const getNewsletters = (state: any) => state[name]
const {
    selectAll,
    selectById,
    selectEntities,
    selectIds,
    selectTotal,
} = newslettersAdapter.getSelectors((state: any) => state[name])

const newsletters = createDraftSafeSelector(
    getNewsletters,
    (newsletters: any) => newsletters,
)

const newslettersEditedValues = createDraftSafeSelector(
    newsletters,
    (newsletters: any) => newsletters?.newslettersEditedValues,
)

const freeNewsletters = createDraftSafeSelector(
    selectAll,
    (newsletters: any) => newsletters?.filter?.(({ type = null } = {}) => type === 'free') || [],
)

const paidNewsletters = createDraftSafeSelector(
    selectAll,
    (newsletters: any) => newsletters?.filter?.(({ type = null } = {}) => type === 'paid') || [],
)

const subscribedNewsletters = createDraftSafeSelector(
    selectAll,
    (newsletters: any) => newsletters?.filter?.(({ subscribed = null } = {}) => subscribed === true) || [],
)

// GENERAL
const NewslettersInitialState = {
    [name]: initialState,
}

const NewslettersReducer = {
    [name]: newsletterSlice.reducer,
}

const takes = [
    ...fetchNewsletters.takes,
    ...saveNewsletters.takes,
    ...fetchUserNewsletters.takes
]

// EXPORTS
export default newsletterSlice.reducer

export {
    NewslettersInitialState as initialState,
    NewslettersReducer as reducer,

    takes,

    fetchNewsletters,
    fetchUserNewsletters,
    saveNewsletters,
    newsletters,
    updateEditedNewsletters,

    selectAll,
    selectById,
    selectEntities,
    selectIds,
    selectTotal,
    newslettersEditedValues,

    freeNewsletters,
    paidNewsletters,
    subscribedNewsletters,
}
