import { productTypeSuccessMessages } from "@/entities/Product/dependencies";
import { selectedProductOptionId } from "@/entities/ProductOptions";
import { useProduct } from "@/entities/Product"
import {
    checkoutDefaultSucceeded,
    checkoutGiftSucceeded,
    checkoutSwitchSucceeded,
} from "@/entities/Cart"
import { useCreator } from "@/entities/Creator"
import {
    generateClaim, outboundClaim,
    generateClaimError
} from "@/entities/Authorization";
import { fetchPaymentHistory } from '@/entities/PaymentHistory'
import { useQueryParams } from '@/entities/QueryParams'

import { useUser } from "../AdditonalInfo/dependencies"

export {
    useProduct,
    useCreator,
    useUser,

    checkoutDefaultSucceeded,
    checkoutGiftSucceeded,
    checkoutSwitchSucceeded,
    selectedProductOptionId,
    productTypeSuccessMessages,

    generateClaim,
    outboundClaim,
    generateClaimError,
    fetchPaymentHistory,
    useQueryParams,
}