const ShowMore = ({ showMore, setShowMore }: any) => {
    return (
        <div
            className={'text-xs flex items-center justify-start cursor-pointer text-violet-500 my-2'}
            onClick={() => setShowMore(!showMore)}
        >
            <span className='font-medium'>{(`Show ${ showMore ? 'more' : 'less' }`)}</span>
            <svg className={`${showMore ? '' : 'rotate-180'} ml-1`} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                <g fill="none">
                    <g fill='#834EFF'>
                        <path d="M5.3 8.3C5.7 7.9 6.3 7.9 6.7 8.3L12 13.6 17.3 8.3C17.7 7.9 18.3 7.9 18.7 8.3 19.1 8.7 19.1 9.3 18.7 9.7L12.7 15.7C12.3 16.1 11.7 16.1 11.3 15.7L5.3 9.7C4.9 9.3 4.9 8.7 5.3 8.3Z" />
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default ShowMore
