import { useUser } from './dependencies'

import AuthForm from './components/AuthForm'

import useShowAuthForm from './hooks/useShowAuthForm'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { deauthorize } from '@/entities/Authorization'

const ContactInfo = () => {
    const dispatch = useDispatch()
    const user = useUser()

    const showAuthForm = useShowAuthForm()

    const onClickChange = useCallback(() => {
        dispatch(deauthorize())
    }, [dispatch])

    return (
        <>
            <h1 className='heading-1 pb-4'>Contact Info</h1>
            {showAuthForm ? <AuthForm /> : (
                <>
                    {user?.id && (
                        <div className='overflow-hidden'>
                            <input className='input' disabled value={user?._phone?.value || user?._email?.value} />
                        </div>
                    )}
                    {user?.id && <button onClick={onClickChange} className='text-xs mt-5 text-violet-500 font-medium'>Change</button>}
                </>
            )}

        </>
    )
}


export default ContactInfo

export {
    useShowAuthForm,
}
