import { configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'

import rootSaga from '@/utils/sagas'
import networkMiddleware from '@/utils/middleware/networkMiddleware'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

import rootReducer from './rootReducer'

const SagaMiddleware = createSagaMiddleware();

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [
        'refreshTokens',
    ]
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware: any) => {
        return getDefaultMiddleware({
            thunk: false,
            serializableCheck: false,
        }).prepend(
            SagaMiddleware,
            networkMiddleware,
        )
    },
})

SagaMiddleware.run(rootSaga);

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
