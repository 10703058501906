import {
    createDraftSafeSelector,
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit'

import { name, deauthorize } from '../dependencies'

import fetchPaymentMethods from './fetchPaymentMethods'
import addPaymentMethod from './addPaymentMethod'
import updatePaymentMethod from './updatePaymentMethod'

const paymentMethodsAdapter = createEntityAdapter({
    selectId: (paymentMethod: any) => paymentMethod?.id,
    sortComparer: (a: any, b: any) => parseInt(a.id) - parseInt(b.id),
})

const initialState = paymentMethodsAdapter.getInitialState({
    isCardValid: null,
    isNewPaymentMethodUpdated: null,
    addPaymentInProgress: false,
    addPaymentMethodSuccess: false,
    addPaymentMethodError: null,
    selectedPaymentMethod: null,
    defaultPaymentMethod: null,
    updatePaymentInProgress: false,
    updatePaymentError: null,
})

const paymentMethodsSlice = createSlice({
    name,
    initialState,
    reducers: {
        updateCardValidation: (state: any, { payload }: any) => {
            state.isCardValid = payload?.isCardValid || false
        },
        setSelectedPaymentMethod: (state: any, { payload }: any) => {
            state.selectedPaymentMethod = payload?.id || null
        },
    },
    extraReducers: {
        [fetchPaymentMethods.fulfilled]: (state: any, { payload }: any) => {
            const paymentMethods = payload?.payment_methods || []
            paymentMethodsAdapter.setAll(state, paymentMethods)
            state.selectedPaymentMethod = payload?.payment_method_default
            state.defaultPaymentMethod = payload?.payment_method_default
        },

        [addPaymentMethod.pending]: (state: any, { payload }: any) => {
            state.addPaymentInProgress = true
            state.addPaymentMethodSuccess = false
            state.addPaymentMethodError = null
            state.isNewPaymentMethodUpdated = false
        },
        [addPaymentMethod.fulfilled]: (state: any, { payload }: any) => {
            const paymentMethods = payload?.paymentMethods?.map((method: any) => ({
                id: method?.sourceId,
                brand: method.cardType,
                exp_month: method.expirationMonth,
                exp_year: method.expirationYear,
                ...method
            }))
            const hasDefaultPayment = payload?.paymentMethods.find((method: any) => method.isDefault)
            paymentMethodsAdapter.upsertMany(state, paymentMethods || [])
            state.selectedPaymentMethod = hasDefaultPayment || payload?.paymentMethods?.[0]?.sourceId
            state.isNewPaymentMethodUpdated = true
            state.addPaymentInProgress = false
            state.addPaymentMethodSuccess = true
            state.addPaymentMethodError = null
        },
        [addPaymentMethod.rejected]: (state: any, { payload: error }: any) => {
            state.addPaymentInProgress = false
            state.addPaymentMethodSuccess = false
            state.addPaymentMethodError = error
        },
        [deauthorize]: (state: any, { payload }: any) => {
            state.isCardValid = initialState.isCardValid
            state.isNewPaymentMethodUpdated = initialState.isNewPaymentMethodUpdated
            state.addPaymentInProgress = initialState.addPaymentInProgress
            state.addPaymentMethodError = initialState.addPaymentMethodError
            paymentMethodsAdapter.removeAll(state)
        },
        [updatePaymentMethod.pending]: (state: any, { payload }: any) => {
            state.updatePaymentInProgress = true
            state.updatePaymentError = null
        },
        [updatePaymentMethod.fulfilled]: (state: any, { payload }: any) => {
            const paymentMethods = payload?.paymentMethods?.map((method: any) => ({
                id: method?.sourceId,
                brand: method.cardType,
                exp_month: method.expirationMonth,
                exp_year: method.expirationYear,
                ...method
            }))
            paymentMethodsAdapter.upsertMany(state, paymentMethods || [])
            state.updatePaymentInProgress = false
            state.defaultPaymentMethod = payload?.payment_method_default
        },
        [updatePaymentMethod.rejected]: (state: any, { payload: error }: any) => {
            state.updatePaymentInProgress = false
            state.updatePaymentError = error
        },
    },
})

const {
    updateCardValidation,
    setSelectedPaymentMethod,
} = paymentMethodsSlice.actions

// SELECTORS
const getPaymentMethods = (state: any) => state[name]
const {
    selectAll,
    selectById,
    selectEntities,
    selectIds,
    selectTotal,
} = paymentMethodsAdapter.getSelectors((state: any) => state[name])

const paymentMethods = createDraftSafeSelector(
    getPaymentMethods,
    (PaymentMethods: any) => PaymentMethods,
)

const isCardValid = createDraftSafeSelector(
    paymentMethods,
    (paymentMethods: any) => {
        return paymentMethods?.isCardValid
    },
)

const getSelectedPaymentMethod = createDraftSafeSelector(
    paymentMethods,
    (PaymentMethods: any) => PaymentMethods?.selectedPaymentMethod,
)


const isNewPaymentMethodUpdated = createDraftSafeSelector(
    getPaymentMethods,
    (PaymentMethods: any) => PaymentMethods?.isNewPaymentMethodUpdated,
)

const addPaymentInProgress = createDraftSafeSelector(
    getPaymentMethods,
    (PaymentMethods: any) => PaymentMethods?.addPaymentInProgress,
)

const updatePaymentInProgress = createDraftSafeSelector(
    getPaymentMethods,
    (PaymentMethods: any) => PaymentMethods?.updatePaymentInProgress,
)

const addPaymentMethodSuccess = createDraftSafeSelector(
    getPaymentMethods,
    (PaymentMethods: any) => PaymentMethods?.addPaymentMethodSuccess,
)

const addPaymentMethodError = createDraftSafeSelector(
    getPaymentMethods,
    (PaymentMethods: any) => PaymentMethods?.addPaymentMethodError,
)

const getDefaultPaymentMethod = createDraftSafeSelector(
    getPaymentMethods,
    (PaymentMethods: any) => PaymentMethods?.defaultPaymentMethod,
)

// GENERAL
const PaymentMethodsInitialState = {
    [name]: initialState,
}

const PaymentMethodsReducer = {
    [name]: paymentMethodsSlice.reducer,
}

const takes = [
    ...fetchPaymentMethods.takes,
    ...addPaymentMethod.takes,
    ...updatePaymentMethod.takes,
]

// EXPORTS
export default paymentMethodsSlice.reducer

export {
    PaymentMethodsInitialState as initialState,
    PaymentMethodsReducer as reducer,
    takes,

    fetchPaymentMethods,
    addPaymentMethod,
    addPaymentMethodSuccess,
    addPaymentMethodError,
    updatePaymentMethod,
    updateCardValidation,
    isCardValid,
    isNewPaymentMethodUpdated,
    addPaymentInProgress,
    updatePaymentInProgress,
    getSelectedPaymentMethod,
    setSelectedPaymentMethod,
    getDefaultPaymentMethod,

    paymentMethods,
    selectAll,
    selectById,
    selectEntities,
    selectIds,
    selectTotal,
}
