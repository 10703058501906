import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'
import { name } from '../dependencies'

const initialState = {
    claim: null,
    productOptionId: null,
    gift: null,
    customPrice: null,
    redirectUrl: null,
    conversationId: null
}


const queryParamsSlice = createSlice({
    name,
    initialState,
    reducers: {
        setQueryParams(state: any, { payload: queryParams = {} }) {
            const {
                claim,
                productOptionId,
                gift,
                customPrice,
                redirectUrl,
                conversationId
            }: any = queryParams

            if (claim) {
                state.claim = claim
            }

            if (productOptionId) {
                state.productOptionId = productOptionId
            }

            if (gift) {
                state.gift = gift
            }

            if (customPrice) {
                state.customPrice = customPrice
            }

            if (redirectUrl) {
                state.redirectUrl = redirectUrl
            }

            if (conversationId) {
                state.conversationId = conversationId
            }

            return state
        },
    },
})

const {
    setQueryParams,
} = queryParamsSlice.actions

// SELECTORS
const getQueryParams = (state: any) => state[name]
const claim = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.claim,
)

const productOptionId = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.productOptionId,
)

const gift = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.gift,
)

const customPrice = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.customPrice,
)

const redirectUrl = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.redirectUrl,
)

const conversationId = createDraftSafeSelector(
    getQueryParams,
    (QueryParams: any) => QueryParams?.conversationId,
)

// GENERAL
const QueryParamsInitialState = {
    [name]: initialState,
}

const QueryParamsReducer = {
    [name]: queryParamsSlice.reducer,
}

// EXPORTS
export default queryParamsSlice.reducer

export {
    QueryParamsInitialState as initialState,
    QueryParamsReducer as reducer,

    setQueryParams,

    claim,
    productOptionId,
    gift,
    customPrice,
    redirectUrl,
    conversationId
}
