import isEmpty from 'lodash/isEmpty'

import { PICO_API_URL } from '@piconetworks/pkg-endpoints'
import SocialLoginButton from '@piconetworks/pkg-social-login-button'

import { useUser } from '@/entities/User'
import { useCreator } from '@/entities/Creator'

import {
    isNewRegistration,
    authWithEmailPassword,
    authWithSocial,
    authWithPhonePassword,
    useCheckHasPassword,
} from '@/entities/Authorization'

import intlTelInput from 'intl-tel-input'

export {
    PICO_API_URL,

    isEmpty,
    isNewRegistration,

    useCheckHasPassword,
    useCreator,
    useUser,

    authWithEmailPassword,
    authWithSocial,
    authWithPhonePassword,

    SocialLoginButton,
    intlTelInput,
}