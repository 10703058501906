import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { creator } from '@/entities/Creator'
import { STORE_TOKENS } from '@piconetworks/pkg-network-middleware'

import { selectAll, selectById } from './slice'

const useRefreshTokens = () => {
    const _creator = useSelector(creator)
    const refreshTokens = useSelector((state: any) => selectById(state, _creator?.id))
    const currentRefreshToken = useSelector((state: any) => state?._network?.default?.refresh_token)

    const dispatch = useDispatch()

    useEffect(() => {
        if (currentRefreshToken === refreshTokens?.value) {
            return
        }

        dispatch({
            type: STORE_TOKENS,
            refresh_token: refreshTokens?.value,
        })
    }, [refreshTokens?.value, currentRefreshToken])

    return refreshTokens
}

export default useRefreshTokens
