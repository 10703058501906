import React from 'react'
import Dropdown from '../Dropdown'
import { Field, useField } from 'react-final-form'
import US from './US'
import CA from './CA'
import Other from './Other'

const Address = ({ field }: any) => {

    const { input } = useField(field?.connected_property?.id)

    const renderAddressFields = (type: any) => {

        switch (type) {
            case 'US':
                return <US id={field?.connected_property?.id} required={field.required} />
                break;
            case 'CA':
                return <CA id={field?.connected_property?.id} required={field.required} />
                break;
            case 'Other':
                return <Other id={field?.connected_property?.id} required={field.required} />
                break;

            default:
                return <div></div>
                break;
        }

    }


    return (
        <>

            <Dropdown
                {...field?.connected_property}
                required={field.required}
                label={field?.connected_property.class === 'standard' ? field?.connected_property.label : field?.label}
                description={field.description}
                id={`${field?.connected_property?.id}.country`}
                options={['US', 'CA', 'Other'].map((item) => ({ value: item, label: item }))}

            />

            {renderAddressFields(input.value.country)}
        </>
    )
}

export default Address
