import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { product, useCreator } from './dependencies'
import { fetchCustomFields, selectAll, actions } from './slice'

const useCustomFields = (fetchData = false) => {
    const dispatch = useDispatch()
    const creator = useCreator()
    const customFields = useSelector(selectAll)
    const _product = useSelector(product)

    useEffect(() => {
        if (!fetchData) {
            return
        }

        if (!creator?.id) {
            return
        }

        if (!_product?.id) {
            return
        }

        dispatch(fetchCustomFields({
            publisherId: creator?.id,
            productId: _product?.id,
        }))
    }, [_product, creator?.id])

    return customFields
}

export default useCustomFields
