import { createNetworkMiddleware } from '@piconetworks/pkg-network-middleware'
import { OAUTH_USER_TOKEN, PICO_API_URL } from '@piconetworks/pkg-endpoints'
import { _network } from '@piconetworks/pkg-network-middleware'

import { store } from '../store'
import { creator } from '@/entities/Creator'

const request = ({ refresh_token }) => {
    const state = store.getState()
    const _creator = creator(state)

    const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        refreshtoken: refresh_token,
        ...(_creator?.id && { publisherid: _creator?.id }),
    }

    return {
        method: 'get',
        baseURL: PICO_API_URL(),
        url: `${OAUTH_USER_TOKEN()}/${refresh_token}`,
        headers,
        timeout: 30000,
    }
}

const networkMiddleware = createNetworkMiddleware({
    concurrentRequests: 8,
    accessTokenAdapters: {
        response: ({ data }) => ({
            access_token: data.token,
            refresh_token: data.refresh_token,
        }),
    },
    refreshTokenAdapters: {
        request,
        response: ({ data }) => ({
            access_token: data.token,
            refresh_token: data.refresh_token,
        }),
    },
})

export default networkMiddleware

export {
    _network,
}
