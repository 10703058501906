import createAsyncSaga from '@/utils/createAsyncSaga'
import * as endpoints from '@piconetworks/pkg-endpoints'

import { saveNewsletters } from '@/entities/Newsletters'
import { saveCustomFields } from '@/entities/CustomFields'
import { creator } from '@/entities/Creator'
import { updatePaymentMethod } from '@/entities/PaymentMethods'
import { current, usePaymentHistory } from '@/entities/PaymentHistory'
import { useGift } from "@/entities/Gift"

const name = 'cart'

export {
    name,
    createAsyncSaga,
    endpoints,
    creator,
    saveCustomFields,
    saveNewsletters,
    updatePaymentMethod,
    current,
    useGift,
    usePaymentHistory,
}
