import { useEffect, useCallback } from 'react'
import {
    checkoutDefaultSucceeded,
    checkoutGiftSucceeded,
    checkoutSwitchSucceeded,
    productTypeSuccessMessages,
    useProduct,
    useCreator,
    generateClaim,
    outboundClaim,
    generateClaimError,
    fetchPaymentHistory,
    useUser,
    useQueryParams,
} from "../dependencies"
import { useSelector, useDispatch } from 'react-redux'

const MARKETING_SITE_URL = process.env.MARKETING_SITE_URL
const HYPE_PAGE_PREFIX = process.env.HYPE_PAGE_PREFIX || ''

const useSuccessMessage = (fetch = false) => {
    let text = ''
    const { redirectUrl } = useQueryParams()
    const product = useProduct()
    const creator = useCreator()
    const user = useUser()
    const dispatch = useDispatch()

    const _checkoutDefaultSucceeded = useSelector(checkoutDefaultSucceeded)
    const _checkoutGiftSucceeded = useSelector(checkoutGiftSucceeded)
    const _checkoutSwitchSucceeded = useSelector(checkoutSwitchSucceeded)
    const _checkoutSucceeded = _checkoutDefaultSucceeded || _checkoutGiftSucceeded || _checkoutSwitchSucceeded
    const _outboundClaim = useSelector(outboundClaim)
    const _generateClaimError = useSelector(generateClaimError)

    useEffect(() => {

        if (!fetch) {
            return
        }

        if (!creator?.id) {
            return
        }

        if (!_checkoutSucceeded) {
            return
        }

        // dispatch(generateClaim({
        //     publisherId: creator?.id
        // }))

        dispatch(fetchPaymentHistory({
            publisherId: creator?.id,
        }))
    }, [creator?.id, _checkoutSucceeded])

    try {
        text = productTypeSuccessMessages?.[product?.type]?.regular()

        if (text === null || text === '') {
            text = productTypeSuccessMessages?.['unknown']?.regular()
        }

    } catch (error) {
        text = productTypeSuccessMessages?.['unknown']?.regular()
    }


    const handleContinue = useCallback(() => {
        const redirectUrlVerified = redirectUrl && !!creator?.primary_urls?.find((url: string) => redirectUrl.startsWith(url))
        const _redirectUrl = redirectUrlVerified ? redirectUrl : null

        let redirectPath = _redirectUrl || `${MARKETING_SITE_URL}/${HYPE_PAGE_PREFIX}${creator?.username}`
        const claimQuery = _redirectUrl ? 'pico_claim' : 'claim'

        if (_outboundClaim && user?.verified) {
            redirectPath = redirectPath + `?${claimQuery}=${_outboundClaim}`
        }

        if (_generateClaimError) {
            // not do anything
        }

        window.location.href = redirectPath
    }, [_outboundClaim, _generateClaimError, user, redirectUrl, creator?.primary_urls])

    return {
        show: _outboundClaim && _checkoutSucceeded,
        success: text,
        handleContinue,
    }
}


export default useSuccessMessage
