import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Form, useFormState } from 'react-final-form'

import { useNewsletters, updateEditedNewsletters, newslettersEditedValues } from './dependencies'

import FreeNewsletters from "./Components/FreeNewsletters";
import PaidNewsletters from "./Components/PaidNewsletters";
import { useSelector } from 'react-redux';

const NewslettersForm = () => {
    const formState = useFormState()
    const isNewslettersFormValid = formState.valid
    const formValues = formState.values
    const dispatch = useDispatch()
    const _newslettersEditedValues = useSelector(newslettersEditedValues)

    useEffect(() => {
        if(_newslettersEditedValues === formValues?.newsletters) {
            return
        }

        dispatch(updateEditedNewsletters({
            isNewslettersFormValid,
            newslettersEditedValues: formValues?.newsletters,
        }))
    }, [isNewslettersFormValid, formValues?.newsletters, _newslettersEditedValues])

    return (
        <div>
            <h1 className='heading-1 mb-3'>Newsletters</h1>
            <FreeNewsletters />
            <PaidNewsletters />
        </div>
    )
}

const FormWrapper = ({ handleSubmit }: any) => {
    return (
        <form onSubmit={handleSubmit} id='newsletters'>
            <NewslettersForm />
        </form>
    )
}

const Newsletters = (_: any) => {
    const { all, subscribedNewsletters, paidNewsletters } = useNewsletters()

    const initialNewslettersMap = {}
    paidNewsletters.forEach(({ id = null } = {}) => id && (initialNewslettersMap[id] = id))
    subscribedNewsletters.forEach(({ id = null } = {}) => id && (initialNewslettersMap[id] = id))

    const _initialNewsletters = Object.keys(initialNewslettersMap)

    const [ initialValues, setInitialValues ] = useState<any>(_initialNewsletters)

    useEffect(() => {
        if (JSON.stringify(initialValues) !== JSON.stringify(_initialNewsletters)) {
            setInitialValues(_initialNewsletters)
        }
    }, [ _initialNewsletters, initialValues, setInitialValues ])

    if (all.length === 0) {
        return null
    }

    return (
        <Form
            component={FormWrapper}
            onSubmit={() => {}}
            initialValues={{
                newsletters: initialValues
            }}
        />
    )
}


export default Newsletters
