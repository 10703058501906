import { useState } from 'react'
import Newsletter from './Newsletter'
import ShowMore from './ShowMore'
import { useNewsletters } from '../dependencies'

const PaidNewsletters = () => {
    const newsletters = useNewsletters()
    const paidNewsletters = newsletters?.paidNewsletters
    const [showMore, setShowMore] = useState(paidNewsletters?.length > 3)

    return (
        <>
            {paidNewsletters?.length > 0 && <p className='text-picoblack text-opacity-70 text-sm font-normal mb-4 mt-5'>Premium</p>}

            {paidNewsletters?.map((newsletter: any, index: any) => {
                return <div key={index} className={`flex items-start ${(index + 1) !== paidNewsletters.length ? 'mb-5' : ''} ${showMore && index > 2 ? 'hidden' : ''}`}>
                    <Newsletter newsletter={newsletter} />
                </div>
            })}
            {paidNewsletters?.length > 3 ? <ShowMore setShowMore={setShowMore} showMore={showMore} /> : <></>}
        </>
    )
}

export default PaidNewsletters
