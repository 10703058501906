import { useSelector } from 'react-redux'
import { format } from '@piconetworks/pkg-currency'

import { selectById, selectedCustomPrice } from '@/entities/ProductOptions'
import { useCallback, useEffect, useState } from 'react'

const DonationCustomProductOption = ({
    id = null,
    hide = false,
    onChange = ({id, customValue = null, autoClose}: any) => {},
    checked = false,
    minValue = '1.00',
}) => {
    const _selectedCustomPrice = useSelector(selectedCustomPrice)
    const productOption = useSelector((state: any) => selectById(state, id))

    const amount = productOption?.price
    const currency = productOption?.currency
    const formattedValue = format({ amount: _selectedCustomPrice, currency, symbol: false })

    const customValueMountedValue = formattedValue > minValue ? formattedValue : minValue
    const [customValue, setCustomValue] = useState(customValueMountedValue)

    useEffect(() => {
        if (!checked) {
            return
        }
        if (parseInt(customValue) < parseInt(minValue)) {
            onChange({autoClose: false, id, customValue: minValue})
        } else {
            onChange({autoClose: false, id, customValue})
        }
    }, [customValue, minValue, id, onChange, checked])

    const _onChange = useCallback((e: any) => setCustomValue(e.target.value), [])

    const _onFocus = useCallback((e: any) => {
        onChange({autoClose: false, id, customValue: e.target.value})
    }, [id, onChange])

    return (
        <div
            onClick={() => {
                if (!amount) {
                    onChange({autoClose: false, id, customValue})
                } else {
                    onChange({autoClose: false, id})
                }
            }}
            key={id}
            data-ui={checked && 'checked'}
            className={`
                items-center
                flex
                justify-center
                ${hide && 'hidden'}
                bg-white
                rounded-lg
                p-0
                font-medium
                border
                border-picoblack
                border-opacity-5
                data-checked:bg-violet-100
                data-checked:text-violet-500
                data-checked:border
                data-checked:border-violet-500
                w-40
            `}
        >
            {!amount && (
                <div className='relative w-full flex'>
                    <div className='pointer-events-none inset-y-0 w-10 justify-center flex items-center'>
                        <span className='text-gray-500 sm:text-sm'>$</span>
                    </div>
                    <input
                        min={minValue}
                        step='0.01'
                        type='number'
                        value={customValue}
                        onFocus={_onFocus}
                        onChange={_onChange}
                        className='w-full px-3 py-4 bg-transparent focus:outline-none'
                        placeholder={'1.00'}
                    />
                </div>
            )}
        </div>
    )
}

export default DonationCustomProductOption
