import PayProductOptionView from "./page_view.model"
import PayUserPayment from "./user_payment.model"


const pay_schemas = {
   page_view: PayProductOptionView,
   user_payment: PayUserPayment
}

export default pay_schemas
