import {
    initialState,
    reducer,
    takes,
    fetchNewsletters,
    saveNewsletters,
    updateEditedNewsletters,
    fetchUserNewsletters,
    subscribedNewsletters,
    newslettersEditedValues,
} from './slice'

import useNewsletters from './useNewsletters'

export default {
    initialState,
    reducer,
    takes,
}

export {
    useNewsletters,
    fetchNewsletters,
    saveNewsletters,
    updateEditedNewsletters,
    subscribedNewsletters,
    newslettersEditedValues,
}
