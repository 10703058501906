import { createAsyncSaga, name, endpoints } from '../dependencies'

const getSwitchable = createAsyncSaga(
    `${name}/getSwitchable`,
    function* ({
        payload: {
            publisherId = null,
            productId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId not provided')
            }

            const results = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: `/query`,
                headers: {
                    publisherid: publisherId,
                },
                data: {
                    query: `{
                        products(
                            publisher_id:"${publisherId}"
                            product_id: "${productId}"
                        )
                        {   
                            products{
                                id,
                                publisher_id,
                                type, 
                                title, 
                                pledge_mode, 
                                match_type, 
                                product_label, 
                                custom_text{
                                    filename
                                },
                                options{
                                    id,
                                    type,
                                    price,
                                    currency,
                                    recurring,
                                    per_seat,
                                    interval,
                                    user_created,
                                    interval_quantity,
                                    short_code,
                                    trial_interval,
                                    trial_quantity,
                                    trial_price
                                }
                            }
                        }
                    }`
                }
            })

            const products = results?.data?.products?.products || []

            yield fulfilled(products)
            yield fulfilled([])
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default getSwitchable
