import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useCreator, useUser } from './dependencies'
import { 
    fetchPaymentHistory, 
    current, 
    history, 
    fetchPaymentHistorySuccess,
    currentProductOptionId,
    isNewProductOptionNextPeriod,
    nextPeriodsProductOptionId,
    currentStatus,
} from './slice'

const usePaymentHistory = (fetchData = false) => {
    const dispatch = useDispatch()
    const user = useUser()
    const creator = useCreator()
    const _current = useSelector(current)
    const _history = useSelector(history)
    const _fetchPaymentHistorySuccess = useSelector(fetchPaymentHistorySuccess)
    const _currentProductOptionId = useSelector(currentProductOptionId)
    const _currentStatus = useSelector(currentStatus)
    const _isNewProductOptionNextPeriod = useSelector(isNewProductOptionNextPeriod)
    const _nextPeriodsProductOptionId = useSelector(nextPeriodsProductOptionId)

    useEffect(() => {
        if (!fetchData) {
            return
        }

        if (!user?.id) {
            return
        }

        if (!creator?.id) {
            return
        }

        if (_current) {
            return
        }

        dispatch(fetchPaymentHistory({
            publisherId: creator?.id,
        }))
    }, [
        _current,
        _fetchPaymentHistorySuccess,
        creator?.id,
        user?.id,
    ])

    return {
        current: _current,
        history: _history,
        currentProductOptionId: _currentProductOptionId,
        isNewProductOptionNextPeriod: _isNewProductOptionNextPeriod,
        nextPeriodsProductOptionId: _nextPeriodsProductOptionId,
        currentStatus: _currentStatus,
    }
}

export default usePaymentHistory
