import Joi from 'joi'

const PayUserPayment = Joi.object()
    .keys({
        conversation_id: Joi.string().guid({ version: ['uuidv4'] }).optional().allow(null).description('The id of the conversation'),
        user_state_id: Joi.string().guid({ version: ['uuidv4'] }).allow(null).description('The Hype-assigned id of the user'),
        product_type: Joi.string().optional().description('The type of product the user paid for'),
        custom_price: Joi.string().optional().description('The custom price of the user\'s purchase'),
        currency: Joi.string().default('USD').optional().description('Currency the user used to pay for product'),
        product_option_id: Joi.string().guid({ version: ['uuidv4'] }).optional().description('The Hype-assigned id for the particular product option')
    })
    .description('pay:user_payment event attributes ')
    .meta({ name: 'PayUserPayment', filename: 'pay:user_payment.model' })

export default PayUserPayment
