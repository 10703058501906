import createAsyncSaga from '@/utils/createAsyncSaga'
import * as endpoints from '@piconetworks/pkg-endpoints'
import { product } from '@/entities/Product'
import { useCreator } from '@/entities/Creator'
import { deauthorize } from '@/entities/Authorization'
import { useUser } from '@/entities/User'

const name = 'paymentHistory'

export {
    name,
    createAsyncSaga,
    endpoints,
    useCreator,
    product,
    deauthorize,
    useUser,
}