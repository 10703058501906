import { useState } from 'react'
import Newsletter from './Newsletter'
import ShowMore from "@/features/Newsletters/Components/ShowMore";
import { useNewsletters } from '../dependencies'

const FreeNewsletters = () => {
    const newsletters = useNewsletters()
    const freeNewsletters = newsletters?.freeNewsletters
    const preSelectedNewsletters = newsletters?.freeNewsletters?.filter((item: any) => item.subscribed)
    const showMoreCount = preSelectedNewsletters?.length || 3
    const [showMore, setShowMore] = useState(freeNewsletters?.length >= showMoreCount)
    return (
        <>
            {freeNewsletters?.length > 0 && <p className='text-picoblack text-opacity-70 text-sm font-normal mb-4'>Free</p>}
            {freeNewsletters?.sort((a: any, b: any) => b.subscribed - a.subscribed)?.map((newsletter: any, index: any) => {
                return (
                    <div
                        key={index}
                        className={`
                            flex 
                            items-start 
                            mb-5
                            ${showMore && index + 1 > showMoreCount ? 'hidden' : ''}
                        `}
                    >
                        <Newsletter newsletter={newsletter} />
                    </div>
                )
            })}

            {freeNewsletters?.length !== showMoreCount ? <ShowMore setShowMore={setShowMore} showMore={showMore} /> : <></>}
        </>
    )
}


export default FreeNewsletters
