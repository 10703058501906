import Events from "@/entities/Events"
import { takeEvery } from "redux-saga/effects"

const analyticTakes = [
    takeEvery(Events.actions.appStart, function* () {
        // console.log('event123', 'Events.actions.appStart ')
    }),
    takeEvery(Events.actions.productOptionChange, function* () {
        // console.log('event123', 'Events.actions.productOptionChange ')
    }),
    takeEvery(Events.actions.userAuthenicated, function* () {
        // console.log('event123', 'Events.actions.userAuthenicated ')
    }),
    takeEvery(Events.actions.newPurchaseSuccess, function* () {
        // console.log('event123', 'Events.actions.newPurchaseSuccess ')
    }),
]

export default analyticTakes