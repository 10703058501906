import { getSwitchable } from '@/entities/Product/slice'
import {
    createDraftSafeSelector,
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { format } from '@piconetworks/pkg-currency'

import { name, fetchProduct, addDays, addMonths, addWeeks, addYears, intervals } from '../dependencies'

import getSwitchProration from './getSwitchProration'

const productOptionsAdapter = createEntityAdapter({
    selectId: (productOption: any) => productOption?.id,
    sortComparer: (a: any, b: any) => parseInt(a.price) - parseInt(b.price),
})

const initialState = productOptionsAdapter.getInitialState({
    id: null,
    price: null,
    selectedCustomPrice: null,
    selectedProductOptionId: null,
    isProductOptionsFormValid: false,
})

const productOptionSlice = createSlice({
    name,
    initialState,
    reducers: {
        setProductOption: (state: any, { payload: {
            selectedCustomPrice = initialState.selectedCustomPrice,
            selectedProductOptionId = initialState.selectedProductOptionId,
            isProductOptionsFormValid = initialState.isProductOptionsFormValid,
        } }: any) => {
            state.selectedCustomPrice = selectedCustomPrice
            state.selectedProductOptionId = selectedProductOptionId
            state.isProductOptionsFormValid = isProductOptionsFormValid
        },
    },
    extraReducers: {
        [fetchProduct.fulfilled]: (state: any, { payload }: any) => {
            productOptionsAdapter.upsertMany(state, payload?.product?.options || [])
        },
        [getSwitchable.fulfilled]: (state: any, { payload: products }: any) => {
            const options = products.reduce((acc: any = [], product: any) => {
                return [
                    ...acc,
                    ...(product?.options || []),
                ]
            }, [])
            productOptionsAdapter.upsertMany(state, options)
        },
        [getSwitchProration.fulfilled]: (state: any, { payload }: any) => {
            productOptionsAdapter.updateOne(state, {
                id: state.selectedProductOptionId,
                changes: {
                    ...payload
                }
            })
        },
    },
})

// SELECTORS
const getProductOptions = (state: any) => state[name]
const {
    selectAll,
    selectById,
    selectEntities,
    selectIds,
    selectTotal,
} = productOptionsAdapter.getSelectors((state: any) => state[name])

const {
    setProductOption,
} = productOptionSlice.actions

const productOptions = createDraftSafeSelector(
    getProductOptions,
    (ProductOptions: any) => ProductOptions?.options,
)

const selectedCustomPrice = createDraftSafeSelector(
    getProductOptions,
    (ProductOptions: any) => ProductOptions?.selectedCustomPrice,
)

const selectedProductOptionId = createDraftSafeSelector(
    getProductOptions,
    (ProductOptions: any) => ProductOptions?.selectedProductOptionId,
)

const selectedProductOption = createDraftSafeSelector(
    [
        selectedProductOptionId,
        (state: any) => (id = null) => selectById(state, id),
    ],
    (productOptionId: any, _selectById: any) => {
        return _selectById?.(productOptionId)
    },
)

const isTrial = createDraftSafeSelector(
    selectedProductOption,
    (_selectedProductOption: any) => {
        return !!_selectedProductOption?.trial_quantity
    },
)

const isFreeTrial = createDraftSafeSelector(
    selectedProductOption,
    (_selectedProductOption: any) => {
        return !!_selectedProductOption?.trial_quantity && !_selectedProductOption?.trial_price
    },
)

const isProductOptionsFormValid = createDraftSafeSelector(
    getProductOptions,
    (ProductOptions: any) => ProductOptions?.isProductOptionsFormValid,
)

const formattedValue = createDraftSafeSelector(
    [
        selectedProductOption,
        selectedCustomPrice,
    ],
    (_selectedProductOption: any, _selectedCustomPrice: any) => {
        const amount = _selectedProductOption?.price || _selectedCustomPrice
        const currency = _selectedProductOption?.currency
        const formattedValue = format({ amount, currency, symbol: true })

        return formattedValue
    }
)

const formattedTrialDuration = createDraftSafeSelector(
    selectedProductOption,
    (_selectedProductOption: any) => {
        const trialInterval = _selectedProductOption?.trial_quantity === 1 ? _selectedProductOption?.trial_interval : `${_selectedProductOption?.trial_interval}s`
        const trialDuration = `${_selectedProductOption?.trial_quantity} ${trialInterval}`

        return trialDuration
    }
)

const formattedTrialDurationCta = createDraftSafeSelector(
    selectedProductOption,
    (_selectedProductOption: any) => {
        const trialWording = `${_selectedProductOption?.trial_quantity}-${_selectedProductOption?.trial_interval}`
        return trialWording
    }
)

const formattedTrialValue = createDraftSafeSelector(
    [
        selectedProductOption,
    ],
    (_selectedProductOption: any) => {
        const amount = _selectedProductOption?.trial_price
        const currency = _selectedProductOption?.trial_currency
        const formattedValue = format({ amount, currency, symbol: true })

        return !amount
            ? `Free`
            : formattedValue
    }
)

const formattedTrialValueAndDuration = createDraftSafeSelector(
    [
        selectedProductOption,
        formattedTrialDuration,
    ],
    (_selectedProductOption: any, _formattedTrialDuration: any) => {
        const amount = _selectedProductOption?.trial_price
        const currency = _selectedProductOption?.trial_currency
        const formattedValue = format({ amount, currency, symbol: true })

        return !amount
            ? `${_formattedTrialDuration} free`
            : formattedValue
    }
)
const formattedTrialEndDate = createDraftSafeSelector(
    [
        selectedProductOption,
    ],
    (_selectedProductOption: any) => {
        if (_selectedProductOption?.trial_quantity) {
            let trialEndDate = null
            const today = new Date()
            switch (_selectedProductOption?.trial_interval) {
                case intervals.day:
                    trialEndDate = addDays({ date: today, days: _selectedProductOption?.trial_quantity })
                    break;
                case intervals.week:
                    trialEndDate = addWeeks({ date: today, weeks: _selectedProductOption?.trial_quantity })
                    break;
                case intervals.month:
                    trialEndDate = addMonths({ date: today, months: _selectedProductOption?.trial_quantity })
                    break;
                case intervals.year:
                    trialEndDate = addYears({ date: today, years: _selectedProductOption?.trial_quantity })

                    break;

                default:
                    trialEndDate = today
                    break;
            }

            return `Trial ends at ${trialEndDate}`
        }

        return ''
    }
)


const formattedPassDuration = createDraftSafeSelector(
    selectedProductOption,
    (_selectedProductOption: any) => {
        const passDuration = `${_selectedProductOption?.interval_quantity} ${_selectedProductOption?.interval}`

        return passDuration
    }
)

const isSwitchableNow = createDraftSafeSelector(
    selectedProductOption,
    (_selectedProductOption: any) => {
        return _selectedProductOption?.pay_now
    }
)

const formattedSwitchProration = createDraftSafeSelector(
    selectedProductOption,
    (_selectedProductOption: any) => {
        const amount = _selectedProductOption?.prorate_amount
        const currency = _selectedProductOption?.currency
        const formattedValue = format({ amount, currency, symbol: true })

        return {
            prorateAmount: formattedValue,
            nextPrice: _selectedProductOption?.next_price,
            endDate: _selectedProductOption?.end_date
        }
    }
)

// GENERAL
const takes = [
    ...getSwitchProration.takes,
]

// GENERAL
const ProductOptionInitialState = {
    [name]: initialState,
}

const ProductOptionReducer = {
    [name]: productOptionSlice.reducer,
}

// EXPORTS
export default productOptionSlice.reducer

export {
    takes,
    ProductOptionInitialState as initialState,
    ProductOptionReducer as reducer,

    productOptions,
    selectAll,
    selectById,
    selectEntities,
    selectIds,
    selectTotal,

    setProductOption,
    selectedCustomPrice,
    selectedProductOptionId,
    selectedProductOption,
    isProductOptionsFormValid,
    getSwitchProration,
    isTrial,
    isFreeTrial,
    isSwitchableNow,

    formattedValue,
    formattedTrialValue,
    formattedTrialDuration,
    formattedTrialDurationCta,
    formattedTrialValueAndDuration,
    formattedPassDuration,
    formattedTrialEndDate,
    formattedSwitchProration
}
