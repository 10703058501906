const required = (val) => !val ? `This field is required` : undefined 

const email = (val) => !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(val) ? 'Invalid email address' : undefined

const composeValidators = (...validators) => (value, allValues, meta) =>
    validators.reduce((error, validator) => error || validator(value, allValues, meta), undefined)

export {
    composeValidators,
    required,
    email,
}