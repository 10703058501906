import { createAsyncSaga, name, endpoints } from '../dependencies'

const purchaseGift = createAsyncSaga(
    `${name}/purchaseGift`,
    function* ({
        payload: {
            productOptionId,
            publisherId,
            redeemUrl,
            senderFirstName,
            recipientFirstName,
            recipientEmail,
            giftNote,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId not provided')
            }

            if (!productOptionId) {
                throw new Error('productOptionId not provided')
            }

            if (!senderFirstName) {
                throw new Error('senderFirstName not provided')
            }

            if (!recipientFirstName) {
                throw new Error('recipientFirstName not provided')
            }

            if (!recipientEmail) {
                throw new Error('recipientEmail not provided')
            }

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: `${endpoints.PURCHASE_GIFT()}`,
                headers: {
                    publisherid: publisherId
                },
                data: {
                    sender: {
                        first_name: senderFirstName,
                    },
                    recipient: {
                        first_name: recipientFirstName,
                        email: recipientEmail,
                    },
                    product_option_id: productOptionId,
                    ...(redeemUrl && {redeem_url: redeemUrl}),
                    ...(giftNote && {note: giftNote}),
                },
            })

            yield fulfilled(response)
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default purchaseGift
