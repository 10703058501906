import Joi from 'joi'

const PayProductOptionView = Joi.object()
    .keys({
        conversation_id: Joi.string().guid({ version: ['uuidv4'] }).optional().allow(null).description('The id of the conversation'),
        product_option_id: Joi.string().guid({ version: ['uuidv4'] }).optional().allow(null).description('The id of the product option'),
    })
    .description('pay:page_view event attributes ')
    .meta({ name: 'PayProductOptionView', filename: 'pay:page_view.model' })

export default PayProductOptionView
