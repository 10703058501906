import { createAsyncSaga, name, endpoints } from '../dependencies'

const authWithEmailPassword = createAsyncSaga(
    `${name}/authWithEmailPassword`,
    function* ({
        payload: {
            email = null,
            password = null,
            publisherId = null
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!email) {
                throw Error('email must be provided')
            }
    
            if (!password) {
                throw Error('password must be provided')
            }
    
            if (!publisherId) {
                throw Error('publisher id must be provided')
            }

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: `${endpoints.OAUTH_USER_LOGIN()}`,
                headers: {
                    publisherid: publisherId
                },
                data: {
                    email,
                    password,
                    publisher_id: publisherId,
                },
            })

            yield fulfilled(response)
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default authWithEmailPassword

