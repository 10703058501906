import {
    initialState,
    reducer,
    takes,
    actions,
    saveCustomFields,
    updateEditedCustomFields,
    isCustomFieldsFormValid,
} from './slice'

import useCustomFields from './useCustomFields'

export default {
    initialState,
    reducer,
    takes,
    actions,
}

export {
    saveCustomFields,
    useCustomFields,
    updateEditedCustomFields,
    isCustomFieldsFormValid,
}
