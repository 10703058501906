import {
    createEntityAdapter,
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'

import { name, productTypes } from '../dependencies'

import fetchProduct from './fetchProduct'
import getSwitchable from './getSwitchable'

const deriveProductType = (product: any = {}) => {
    if (product?.pledge_mode) {
        return productTypes.pledgeMode
    }

    if (product?.type === 'pass') {
        return productTypes.pass
    }

    if (product?.type === 'group') {
        return productTypes.group
    }

    if (product?.type === 'subscription' && product?.options?.[0]?.trial_quantity) {
        return productTypes.trial
    }

    if (product?.type === 'subscription') {
        return productTypes.subscription
    }

    return productTypes.unknown
}

const productAdapter = createEntityAdapter({
    selectId: (product: any) => product?.id,
    sortComparer: (a: any, b: any) => parseInt(a.price) - parseInt(b.price),
})

const initialState = productAdapter.getInitialState({
    product: null,
    productType: null,
    fetchProductInProgress: false,
    fetchProductError: null,

    fetchSwitchableInProgress: false,
    fetchSwitchableError: null,
})


const productSlice = createSlice({
    name,
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchProduct.pending]: (state: any, { payload }: any) => {
            state.product = null
            state.fetchProductInProgress = true
            state.fetchProductError = null
        },
        [fetchProduct.fulfilled]: (state: any, { payload }: any) => {
            state.product = payload?.product
            state.fetchProductInProgress = false
            state.fetchProductError = null
            state.productType = deriveProductType(product)
        },
        [fetchProduct.rejected]: (state: any, { payload: error }: any) => {
            state.productId = null
            state.fetchProductInProgress = false
            state.fetchProductError = error
        },
        [getSwitchable.pending]: (state: any, { payload }: any) => {
            state.fetchSwitchableInProgress = true
            state.fetchSwitchableError = null
        },
        [getSwitchable.fulfilled]: (state: any, { payload: products }: any) => {
            state.fetchSwitchableInProgress = false
            state.fetchSwitchableError = null

            productAdapter.upsertMany(state, products || [])
        },
        [getSwitchable.rejected]: (state: any, { payload: error }: any) => {
            state.fetchSwitchableInProgress = false
            state.fetchSwitchableError = error
        },
    },
})


// SELECTORS
const getProduct = (state: any) => state[name]

const { selectAll } = productAdapter.getSelectors(getProduct)

const product = createDraftSafeSelector(
    getProduct,
    (Product: any) => Product?.product,
)

const getProductByOptionId = createDraftSafeSelector(
    [
        selectAll,
        (state: any, optionId: any) => optionId,
    ],
    (allProducts: any, productOptionId: any) => allProducts.find((product: any) => (
        product?.options?.find((option: any) => option?.id === productOptionId)
    )),
)

const productId = createDraftSafeSelector(
    product,
    (Product: any) => Product?.id,
)

const creatorId = createDraftSafeSelector(
    product,
    (product: any) => product?.publisher_id,
)

const fetchProductInProgress = createDraftSafeSelector(
    getProduct,
    (Product: any) => Product?.fetchProductInProgress,
)

const fetchProductError = createDraftSafeSelector(
    getProduct,
    (Product: any) => Product?.fetchProductInProgress,
)

const selectProductType = createDraftSafeSelector(
    product,
    (Product: any) => Product?.type,
)

const isPledgeMode = createDraftSafeSelector(
    product,
    (Product: any) => Product?.type === productTypes.pledgeMode,
)

const isPass = createDraftSafeSelector(
    product,
    (Product: any) => Product?.type === productTypes.pass,
)

const isGroup = createDraftSafeSelector(
    product,
    (Product: any) => Product?.type === productTypes.group,
)

const isSubscription = createDraftSafeSelector(
    product,
    (Product: any) => Product?.type === productTypes.subscription,
)

const isTrial = createDraftSafeSelector(
    product,
    (Product: any) => Product?.type === productTypes.trial,
)

const isDonation = createDraftSafeSelector(
    product,
    (Product: any) => Product?.type === productTypes.donation,
)

const isUnknown = createDraftSafeSelector(
    product,
    (Product: any) => Product?.type === productTypes.unknown,
)

const changeWording = createDraftSafeSelector(
    [
        isSubscription,
        isPass,
        isDonation,
    ],
    (
        isSubscription: any,
        isPass: any,
        isDonation: any,
    ) => (
        isSubscription ?
            'subscription' :
            isPass ?
                'pass' :
                isDonation ?
                    'donation' :
                    'product'
    )
)


// GENERAL
const takes = [
    ...fetchProduct.takes,
    ...getSwitchable.takes,
]

const ProductInitialState = {
    [name]: initialState,
}

const ProductReducer = {
    [name]: productSlice.reducer,
}

// EXPORTS
export default productSlice.reducer

export {
    takes,
    ProductInitialState as initialState,
    ProductReducer as reducer,

    fetchProduct,
    getSwitchable,

    selectAll,
    product,
    creatorId,
    fetchProductInProgress,
    fetchProductError,

    productId,
    selectProductType,
    isPledgeMode,
    isPass,
    isGroup,
    isSubscription,
    isTrial,
    isDonation,
    isUnknown,
    changeWording,
    getProductByOptionId,
}
