import { useRouter } from "next/router"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { claim, productOptionId, setQueryParams, gift, customPrice, redirectUrl, conversationId } from "./slice"

const useQueryParams = () => {
    const router = useRouter()
    const dispatch = useDispatch()
    const query = router?.query

    const _claim = useSelector(claim)
    const _productOptionId = useSelector(productOptionId)
    const _gift = useSelector(gift)
    const _customPrice = useSelector(customPrice)
    const _redirectUrl = useSelector(redirectUrl)
    const _conversationId = useSelector(conversationId)

    const claimQuery = query?.claim
    const productOptionIdQuery = query?.product_option_id
    const giftQuery = query?.gift
    const customPriceQuery = query?.custom_price
    const redirectUrlQuery = query?.redirect_url
    const conversationIdQuery = query?.conv_id

    const {
        utm_campaign,
        utm_medium,
        utm_source,
        utm_term,
        utm_content,
    } = query

    useEffect(() => {
        if (
            (!claimQuery)
            && ((productOptionIdQuery === _productOptionId) || !productOptionIdQuery)
            && ((_gift === giftQuery) || !giftQuery)
            && ((_customPrice === customPriceQuery) || !customPriceQuery)
            && ((_redirectUrl === redirectUrlQuery) || !redirectUrlQuery)
            && ((_conversationId === conversationIdQuery) || !conversationIdQuery)
        ) {
            return
        }

        dispatch(setQueryParams({
            claim: claimQuery,
            productOptionId: productOptionIdQuery,
            gift: giftQuery,
            customPrice: customPriceQuery,
            redirectUrl: redirectUrlQuery,
            conversationId: conversationIdQuery
        }))

        const queryToKeep = {
            ...({ gift: giftQuery }),
            ...({ custom_price: customPriceQuery }),
            ...({ redirect_url: redirectUrlQuery }),
            ...({ conv_id: conversationIdQuery }),
            ...(utm_campaign && { utm_campaign }),
            ...(utm_medium && { utm_medium }),
            ...(utm_source && { utm_source }),
            ...(utm_term && { utm_term }),
            ...(utm_content && { utm_content }),
        }

        let queryToKeepString = Object.entries(queryToKeep)
            .reduce((acc, [key, value]) => {
                if (!value) {
                    return acc
                }

                const ampersand = acc !== '' ? '&' : ''

                return `${acc}${ampersand}${key}=${value}`
            }, '')

  

        queryToKeepString = queryToKeepString ? `?${queryToKeepString}` : ''

        router.replace(`/${productOptionIdQuery}${queryToKeepString}`)
    }, [
        _gift,
        giftQuery,
        _customPrice,
        customPriceQuery,
        _productOptionId,
        productOptionIdQuery,
        conversationIdQuery,
        router.replace,
        claimQuery,
        dispatch,
    ])

    return {
        claim: _claim,
        productOptionId: _productOptionId,
        gift: _gift,
        customPrice: _customPrice,
        redirectUrl: _redirectUrl,
        conversationId: _conversationId
    }
}

export default useQueryParams
