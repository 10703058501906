import { Form } from 'react-final-form'
import GiftForm from './components/Form'

const FormWrapper = ({ handleSubmit }: any) => {
    return (
        <form onSubmit={handleSubmit} id='giftForm'>
            <GiftForm />
        </form>
    )
}


const GiftWrapper = () => {


    return (
        <Form
            component={FormWrapper}
            onSubmit={() => { }}
            initialValues={{}}
            validate={values => {
                const errors: any = {}


                return errors
            }}
        />
    )
}


export default GiftWrapper
