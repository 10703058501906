import {
    initialState,
    reducer,
    takes,
    actions,
    current,
    fetchPaymentHistory,
} from './slice'

import usePaymentHistory from './usePaymentHistory'

export default {
    initialState,
    reducer,
    takes,
    actions,
}

export {
    usePaymentHistory,
    fetchPaymentHistory,
    current,
}
