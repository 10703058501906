import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'

import { name } from '../dependencies'

import purchaseProduct from './purchaseProduct'
import switchProduct from './switchProduct'
import purchaseGift from './purchaseGift'

import checkoutDefault from './checkoutDefault'
import checkoutSwitch from './checkoutSwitch'
import checkoutGift from './checkoutGift'

const initialState = {
    purchasedProduct: null,
    purchaseProductInProgress: false,
    purchaseProductError: null,

    switchedProduct: null,
    switchProductInProgress: false,
    switchProductError: null,

    purchasedGift: null,
    purchaseGiftInProgress: false,
    purchaseGiftError: null,

    checkoutDefaultInProgress: false,
    checkoutDefaultSucceeded: false,
    checkoutDefaultError: null,

    checkoutSwitchInProgress: false,
    checkoutSwitchSucceeded: false,
    checkoutSwitchError: null,

    checkoutGiftInProgress: false,
    checkoutGiftSucceeded: false,
    checkoutGiftError: null,

    selectedSwitchNow: null,
}

const cartSlice = createSlice({
    name,
    initialState,
    reducers: {
        setSelectedSwitchNow: (state: any, { payload: selectedSwitchNow }: any) => {
            state.selectedSwitchNow = selectedSwitchNow
        },
    },
    extraReducers: {
        [purchaseProduct.pending]: (state: any) => {
            state.purchasedProduct = null
            state.purchaseProductInProgress = true
            state.purchaseProductError = null
        },
        [purchaseProduct.fulfilled]: (state: any, { payload: product }: any) => {
            state.purchasedProduct = product
            state.purchaseProductInProgress = false
            state.purchaseProductError = null
        },
        [purchaseProduct.rejected]: (state: any, { payload: error }: any) => {
            state.purchasedProduct = null
            state.purchaseProductInProgress = false
            state.purchaseProductError = error
        },

        [switchProduct.pending]: (state: any) => {
            state.switchedProduct = null
            state.switchProductInProgress = true
            state.switchProductError = null
        },
        [switchProduct.fulfilled]: (state: any, { payload: product }: any) => {
            state.switchedProduct = product
            state.switchProductInProgress = false
            state.switchProductError = null
        },
        [switchProduct.rejected]: (state: any, { payload: error }: any) => {
            state.switchedProduct = null
            state.switchProductInProgress = false
            state.switchProductError = error
        },

        [purchaseGift.pending]: (state: any) => {
            state.purchasedGift = null
            state.purchaseGiftInProgress = true
            state.purchaseGiftError = null
        },
        [purchaseGift.fulfilled]: (state: any, { payload: gift }: any) => {
            state.purchasedGift = gift
            state.purchaseGiftInProgress = false
            state.purchaseGiftError = null
        },
        [purchaseGift.rejected]: (state: any, { payload: error }: any) => {
            state.purchasedGift = null
            state.purchaseGiftInProgress = false
            state.purchaseGiftError = error
        },

        [checkoutDefault.pending]: (state: any) => {
            state.checkoutDefaultInProgress = true
            state.checkoutDefaultSucceeded = initialState.checkoutDefaultSucceeded
            state.checkoutDefaultError = initialState.checkoutDefaultSucceeded
        },
        [checkoutDefault.fulfilled]: (state: any, { payload: product }: any) => {
            state.checkoutDefaultInProgress = false
            state.checkoutDefaultSucceeded = true
        },
        [checkoutDefault.rejected]: (state: any, { payload: error }: any) => {
            state.checkoutDefaultInProgress = false
            state.checkoutDefaultError = error
        },

        [checkoutSwitch.pending]: (state: any) => {
            state.checkoutSwitchInProgress = true
            state.checkoutSwitchSucceeded = initialState.checkoutSwitchSucceeded
            state.checkoutSwitchError = initialState.checkoutSwitchSucceeded
        },
        [checkoutSwitch.fulfilled]: (state: any, { payload: product }: any) => {
            state.checkoutSwitchInProgress = false
            state.checkoutSwitchSucceeded = true
        },
        [checkoutSwitch.rejected]: (state: any, { payload: error }: any) => {
            state.checkoutSwitchInProgress = false
            state.checkoutSwitchError = error
        },

        [checkoutGift.pending]: (state: any) => {
            state.checkoutGiftInProgress = true
            state.checkoutGiftSucceeded = initialState.checkoutGiftSucceeded
            state.checkoutGiftError = initialState.checkoutGiftSucceeded
        },
        [checkoutGift.fulfilled]: (state: any, { payload: product }: any) => {
            state.checkoutGiftInProgress = false
            state.checkoutGiftSucceeded = true
        },
        [checkoutGift.rejected]: (state: any, { payload: error }: any) => {
            state.checkoutGiftInProgress = false
            state.checkoutGiftError = error
        },
    },
})

const {
    setSelectedSwitchNow,
} = cartSlice.actions


// SELECTORS
const getCart = (state: any) => state[name]

const selectedSwitchNow = createDraftSafeSelector(
    getCart,
    (cart: any) => cart?.selectedSwitchNow,
)

const purchasedProduct = createDraftSafeSelector(
    getCart,
    (cart: any) => cart?.purchasedProduct,
)
const purchaseProductInProgress = createDraftSafeSelector(
    getCart,
    (cart: any) => cart?.purchaseProductInProgress,
)
const purchaseProductError = createDraftSafeSelector(
    getCart,
    (cart: any) => cart?.purchaseProductError,
)

const switchedProduct = createDraftSafeSelector(
    getCart,
    (cart: any) => cart?.switchedProduct,
)
const switchProductInProgress = createDraftSafeSelector(
    getCart,
    (cart: any) => cart?.switchProductInProgress,
)
const switchProductError = createDraftSafeSelector(
    getCart,
    (cart: any) => cart?.switchProductError,
)

const purchasedGift = createDraftSafeSelector(
    getCart,
    (cart: any) => cart?.purchasedGift,
)
const purchaseGiftInProgress = createDraftSafeSelector(
    getCart,
    (cart: any) => cart?.purchaseGiftInProgress,
)
const purchaseGiftError = createDraftSafeSelector(
    getCart,
    (cart: any) => cart?.purchaseGiftError,
)

const checkoutDefaultInProgress = createDraftSafeSelector(
    getCart,
    (cart: any) => cart?.checkoutDefaultInProgress,
)
const checkoutDefaultSucceeded = createDraftSafeSelector(
    getCart,
    (cart: any) => cart?.checkoutDefaultSucceeded,
)
const checkoutDefaultError = createDraftSafeSelector(
    getCart,
    (cart: any) => cart?.checkoutDefaultError,
)

const checkoutSwitchInProgress = createDraftSafeSelector(
    getCart,
    (cart: any) => cart?.checkoutSwitchInProgress,
)
const checkoutSwitchSucceeded = createDraftSafeSelector(
    getCart,
    (cart: any) => cart?.checkoutSwitchSucceeded,
)
const checkoutSwitchError = createDraftSafeSelector(
    getCart,
    (cart: any) => cart?.checkoutSwitchError,
)

const checkoutGiftInProgress = createDraftSafeSelector(
    getCart,
    (cart: any) => cart?.checkoutGiftInProgress,
)
const checkoutGiftSucceeded = createDraftSafeSelector(
    getCart,
    (cart: any) => cart?.checkoutGiftSucceeded,
)
const checkoutGiftError = createDraftSafeSelector(
    getCart,
    (cart: any) => cart?.checkoutGiftError,
)

// const _checkoutSucceeded = _checkoutDefaultSucceeded || _checkoutGiftSucceeded || _checkoutSwitchSucceeded
const checkoutSucceeded = createDraftSafeSelector(
    [checkoutDefaultSucceeded, checkoutGiftSucceeded, checkoutSwitchSucceeded],
    (checkoutDefaultSucceeded: any, checkoutGiftSucceeded: any, checkoutSwitchSucceeded: any) => {
        return checkoutDefaultSucceeded || checkoutGiftSucceeded || checkoutSwitchSucceeded
    },
)

const checkoutError = createDraftSafeSelector(
    [checkoutDefaultError, checkoutGiftError, checkoutSwitchError],
    (checkoutDefaultError: any, checkoutGiftError: any, checkoutSwitchError: any) => {
        return checkoutDefaultError || checkoutGiftError || checkoutSwitchError
    },
)


// GENERAL
const takes = [
    ...checkoutDefault.takes,
    ...purchaseProduct.takes,

    ...checkoutSwitch.takes,
    ...switchProduct.takes,

    ...checkoutGift.takes,
    ...purchaseGift.takes,
]

const PurchaseProductInitialState = {
    [name]: initialState,
}

const PurchaseProductReducer = {
    [name]: cartSlice.reducer,
}

// EXPORTS
export default cartSlice.reducer

export {
    takes,
    PurchaseProductInitialState as initialState,
    PurchaseProductReducer as reducer,

    selectedSwitchNow,
    setSelectedSwitchNow,

    purchaseProduct,
    purchasedProduct,
    purchaseProductInProgress,
    purchaseProductError,

    switchProduct,
    switchedProduct,
    switchProductInProgress,
    switchProductError,

    purchasedGift,
    purchaseGiftInProgress,
    purchaseGiftError,

    checkoutDefault,
    checkoutDefaultInProgress,
    checkoutDefaultSucceeded,
    checkoutDefaultError,

    checkoutSwitch,
    checkoutSwitchInProgress,
    checkoutSwitchSucceeded,
    checkoutSwitchError,

    checkoutGift,
    checkoutGiftInProgress,
    checkoutGiftSucceeded,
    checkoutGiftError,

    checkoutSucceeded,
    checkoutError,
}
