import React, { useCallback, useEffect, useState } from 'react'

import OneTimePasscode from '@piconetworks/pkg-otp'
import { useCreator } from '../../../dependencies'
import { useOneTimeCode } from '@/entities/Authorization'
import { useDispatch } from 'react-redux'
import logger from '@/lib/logger'
import { addError } from '@/lib/rum'

const Otp = () => {
    const creator = useCreator()
    const dispatch = useDispatch()
    const [currentPasscode, setCurrentPasscode] = useState('')
    const [showNotification, setShowNotification] = useState(false)

    const [showComponent, setShowComponent] = useState(false)
    const { oneTimeCodeSent, resetOTP, oneTimeCodeError, requestOneTimeCode, oneTimeCodeConfirmed, confirmOneTimeCode, email, phoneNumber } = useOneTimeCode({
        publisherId: creator?.id
    })

    useEffect(() => {
        if (oneTimeCodeSent) {
            setShowComponent(true)
        }
        if (!oneTimeCodeSent) {
            setShowComponent(false)
        }
        if (oneTimeCodeConfirmed) {
            setShowComponent(false)
        }

        return () => {
            setCurrentPasscode('')
        }

    }, [oneTimeCodeSent, oneTimeCodeConfirmed])


    const processCode = useCallback(() => {
        try {
            confirmOneTimeCode({ code: currentPasscode })
        } catch (error) {
            logger.error('processCode', {}, error)
            addError(error)
        }
    }, [currentPasscode, confirmOneTimeCode])


    const resendOTP = useCallback(() => {
        if (email) {
            requestOneTimeCode({ email })
        }

        if (phoneNumber) {
            requestOneTimeCode({ phone_number: phoneNumber })
        }

    }, [email, phoneNumber, requestOneTimeCode])

    useEffect(() => {
        if(currentPasscode.length === 6){
            processCode()
        }
    },[currentPasscode, processCode])

    if (!showComponent) {
        return null
    }

    return (
        <div >
            <div >
                <div className='mb-3'>
                    <label className='text-picoblack text-opacity-70 text-sm font-normal '>
                        {`Enter the verification code sent to ${email || phoneNumber}.`}
                    </label>
                </div>
                <OneTimePasscode
                    isErrored={false}
                    isInputNum={true}
                    className='otp'
                    shouldAutoFocus={true}
                    numInputs={6}
                    currentPasscode={currentPasscode}
                    setCurrentPasscode={setCurrentPasscode}
                    errorText={'something went wrong'}
                />

            </div>
            <div className='mt-3' >
                <p className='text-xs text-gray-700 font-medium'>
                    Didn’t receive a code? <span className='cursor-pointer text-violet-500' onClick={resendOTP}>Resend</span> or <span className='cursor-pointer text-violet-500' onClick={() => dispatch(resetOTP())}>change contact info.</span>
                </p>

            </div>
            {oneTimeCodeError && (
                <div className="mt-4 text-sm text-red-500" role="alert">
                    <span className="font-medium">{oneTimeCodeError.error}</span>
                </div>
            )}
        </div>
    )
}



export default Otp
