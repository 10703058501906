import { createAsyncSaga, name, endpoints } from '../dependencies'

const addPaymentMethod = createAsyncSaga(
    `${name}/addPaymentMethod`,
    function* ({
        payload: {
            id = null,
            type = null,
            publisherId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId not provided')
            }

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: endpoints.ME_PAYMENT(),
                headers: {
                    publisherid: publisherId,
                },
                data: {
                    token: id,
                    source_type: type,
                },
            })

            yield fulfilled(response)
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default addPaymentMethod
