import useSuccessMessage from "./hooks/useSuccessMessage"

const SuccessMessage = () => {

    const { success, handleContinue } = useSuccessMessage()


    return (
        <>
            <div className='flex justify-center'>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M24 43.9999C35.0457 43.9999 44 35.0456 44 23.9999C44 12.9542 35.0457 3.99991 24 3.99991C12.9543 3.99991 4 12.9542 4 23.9999C4 35.0456 12.9543 43.9999 24 43.9999ZM12.637 21.7466C11.7842 22.6347 11.7909 24.0592 12.6387 24.9421L19.6182 32.3354C20.4729 33.2256 21.8409 33.2179 22.6892 32.3344L35.3665 18.8827C36.2058 18.0086 36.216 16.5631 35.3691 15.6749C34.5164 14.7805 33.1538 14.777 32.3124 15.6532L21.1532 27.5238L15.7129 21.7518C14.8632 20.8693 13.4838 20.8646 12.637 21.7466Z" fill="#28BF6F" />
                </svg>
            </div>
            <div className='flex flex-col items-center justify-center mt-5 font-medium'>
                <h1 className='text-base text-picoblack'>Thank you!</h1>
                <p className='text-gray-800 text-sm font-normal'>{success}</p>
            </div>
            <div className='flex justify-center mt-6'>
                <button
                    type='button'
                    className='
                        focus:ring-4
                        font-medium
                        rounded-lg
                        text-sm
                        px-6
                        py-2.5
                        focus:outline-none
                        h-10
                        border-1    
                        text-picoblack
                        capitalize
                        flex
                        items-center
                        justify-center
                        shadow-button
                    '
                    onClick={handleContinue}
                >
                    Continue
                </button>
            </div>
        </>
    )
}


export default SuccessMessage

