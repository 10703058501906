const intervals = {
    day: 'day',
    week: 'week',
    month: 'month',
    year: 'year'
}

const format = (date: any) => {

    const newDate = new Date(date)
    return newDate.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })

}

const addDays = ({ date, days }: any) => {
    const nextDate = new Date(date)

    const value = nextDate?.setDate(nextDate.getDate() + days)
    return format(value)
}

const addWeeks = ({ date, weeks }: any) => {
    const nextDate = new Date(date)
    const value = nextDate?.setDate(nextDate.getDate() + 7 * weeks)
    return format(value)
}

const addMonths = ({ date, months }: any) => {
    const nextDate = new Date(date)

    const value = nextDate?.setMonth(nextDate.getMonth() + months)
    return format(value)
}

const addYears = ({ date, years }: any) => {
    const nextDate = new Date(date)

    const value = nextDate?.setFullYear(nextDate.getFullYear() + years)
    return format(value)
}


export {
    intervals,
    
    addDays,
    addWeeks,
    addMonths,
    addYears
}