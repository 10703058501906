import Checkbox from "./Checkbox"
import Dropdown from "./Dropdown"
import Text from './Text'
import Boolean from './Boolean'
import DateInput from './DateInput'
import Address from './Address'

const Field = ({ ...field }: any) => {


    if (field?.connected_property?.type === 'checkbox') {
        return (
            <Checkbox
                {...field?.connected_property}
                required={field.required}
                id={field?.connected_property?.id}
                label={field?.connected_property.class === 'standard' ? field?.connected_property.label : field?.label}
                description={field.description}
            />
        )
    }

    if (field?.connected_property?.type === 'short_answer') {
        return (
            <Text
                {...field?.connected_property}
                required={field.required}
                id={field?.connected_property?.id}
                label={field?.connected_property.class === 'standard' ? field?.connected_property.label : field?.label}
                description={field.description}
            />
        )
    }

    if (field?.connected_property?.type === 'url') {
        return (
            <Text
                {...field?.connected_property}
                required={field.required}
                id={field?.connected_property?.id}
                label={field?.connected_property.class === 'standard' ? field?.connected_property.label : field?.label}
                description={field.description}
            />
        )
    }


    if (field?.connected_property?.type === 'number') {
        return (
            <Text
                type='number'
                {...field?.connected_property}
                required={field.required}
                id={field?.connected_property?.id}
                label={field?.connected_property.class === 'standard' ? field?.connected_property.label : field?.label}
                description={field.description}
            />
        )
    }



    if (field?.connected_property?.type === 'dropdown') {
        return (
            <Dropdown
                {...field?.connected_property}
                required={field.required}
                id={field?.connected_property?.id}
                label={field?.connected_property.class === 'standard' ? field?.connected_property.label : field?.label}
                description={field.description}
            />
        )
    }

    if (field?.connected_property?.type === 'address') {
        return (
            <Address
                field={field}
            />
        )
    }

    if (field?.connected_property?.type === 'boolean') {
        return (
            <Boolean
                {...field?.connected_property}
                required={field.required}
                id={field?.connected_property?.id}
                label={field?.connected_property.class === 'standard' ? field?.connected_property.label : field?.label}
                description={field.description}
                options={['yes', 'no'].map((item) => ({ value: item, label: item }))}
            />
        )
    }

    if (field?.connected_property?.type === 'radio') {
        return (
            <Boolean
                {...field?.connected_property}
                required={field.required}
                id={field?.connected_property?.id}
                label={field?.connected_property.class === 'standard' ? field?.connected_property.label : field?.label}
                description={field.description}
            />
        )
    }

    if (field?.connected_property?.type === 'date') {
        return (
            <DateInput
                {...field?.connected_property}
                required={field.required}
                id={field?.connected_property?.id}
                label={field?.connected_property.class === 'standard' ? field?.connected_property.label : field?.label}
                description={field.description}
            />
        )
    }
    return <div></div>

}


export default Field