import dynamic from 'next/dynamic'

import React, { useCallback } from 'react'
import { SocialLoginButton, PICO_API_URL, authWithSocial, useCreator } from '../../../dependencies'
import { useDispatch } from 'react-redux'
import {
    GithubIcon,
    FacebookIcon,
    LinkedInIcon,
    GoogleIcon,
    TwitterIcon,
    AppleIcon
} from './icons'

import logger from '@/lib/logger'
import { addError } from '@/lib/rum'



const renderSocialIcon = (provider: any) => {

    switch (provider) {
        case 'github':
            return <GithubIcon />
        case 'facebook':
            return <FacebookIcon />
        case 'linkedin':
            return <LinkedInIcon />
        case 'google':
            return <GoogleIcon />
        case 'twitter':
            return <TwitterIcon />
        case 'apple':
            return <AppleIcon />

        default:
            return <div></div>
    }
}

const SocialLoginOptions = () => {
    const creator = useCreator()

    const dispatch = useDispatch()

    const onSocialAuthenticate = useCallback(({ token = null, refresh_token = null }) => {
        dispatch(authWithSocial({
            token,
            refresh_token
        }))
    }, [dispatch])


    const onSocialAuthenticateFailure = useCallback((error = {}) => {
        logger.error('onSocialAuthenticateFailure', {}, error)
        addError(error)
    }, [])

    const { id, authentication_methods } = creator

    return (
        <div className='grid grid-cols-2 gap-3'>
            {authentication_methods?.map((provider: any) => {
                if (provider === 'email' || provider === 'sms') {
                    return null
                }
                return (
                    <SocialLoginButton
                        key={provider}
                        publisherId={id}
                        params={{
                            is_combined: true
                        }}
                        type={provider}
                        api_url={PICO_API_URL()}
                        button={
                            <button className='btn-social border-1 text-picoblack capitalize flex items-center justify-between shadow-button'>
                                {renderSocialIcon(provider)}
                                <span className='w-full text-base '>{provider}</span>
                            </button>
                        }
                        onClick={() => {
                            console.log('clicked')
                        }}
                        onLogin={onSocialAuthenticate}
                        onFailure={onSocialAuthenticateFailure}

                    />
                )
            })}
        </div>
    )

}

export default SocialLoginOptions
