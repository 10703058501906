import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import {
    giftModeEnabled,
    getRedeemUrl,
    senderFirstName,
    recipientFirstName,
    recipientEmail,
    giftNote,
    setGiftFields,
    isGiftFormValid,
} from "./slice"

const useGift = () => {
    const dispatch = useDispatch()
    const _giftModeEnabled = useSelector(giftModeEnabled)

    const _isGiftFormValid = useSelector(isGiftFormValid)
    const _getRedeemUrl = useSelector(getRedeemUrl)
    const _senderFirstName = useSelector(senderFirstName)
    const _recipientFirstName = useSelector(recipientFirstName)
    const _recipientEmail = useSelector(recipientEmail)
    const _giftNote = useSelector(giftNote)

    const _setGiftFields = useCallback(({
        senderFirstName,
        recipientFirstName,
        recipientEmail,
        giftNote,
        isGiftFormValid,
    }: any) => {
        dispatch(setGiftFields({
            senderFirstName,
            recipientFirstName,
            recipientEmail,
            giftNote,
            isGiftFormValid,
        }))
    }, [
        dispatch,
    ])

    return {
        setGiftFields: _setGiftFields,
        giftModeEnabled: _giftModeEnabled,
        redeemUrl: _getRedeemUrl,
        senderFirstName: _senderFirstName,
        recipientFirstName: _recipientFirstName,
        recipientEmail: _recipientEmail,
        giftNote: _giftNote,
        isGiftFormValid: _isGiftFormValid,
    }
}

export default useGift
