import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'


import { name } from '../dependencies'
import requestFeatureFlags from './requestFeatureFlags'

const initialState = {
   featureFlags: null,
   loading: false,
   error: null,
   isFetched: false,
}

const featureFlagsSlice = createSlice({
    name,
    initialState,
    reducers: {},
    extraReducers: {
        [requestFeatureFlags.pending]: (state: any) => {
            state.loading = true
            state.isFetched = initialState.isFetched
            state.error = null
            state.featureFlags = null
        },
        [requestFeatureFlags.fulfilled]: (state: any, { payload: featureFlags }: any) => {
            state.loading = false
            state.isFetched = true
            state.error = null
            state.featureFlags = featureFlags
        },
        [requestFeatureFlags.rejected]: (state: any, { payload: error }: any) => {
            state.loading = false
            state.isFetched = true
            state.error = error
            state.featureFlags = null
        },
    },
})

// SELECTORS
const getFeatureFlags = (state: any) => state[name]

const featureFlags = createDraftSafeSelector(
    getFeatureFlags,
    (FeatureFlags: any) => FeatureFlags?.featureFlags,
)
const getFeatureFlagsEnabled = createDraftSafeSelector(
    getFeatureFlags,
    (_: any, flag = '') => flag,
    (FeatureFlags: any, flag: any) => FeatureFlags?.featureFlags?.includes(flag?.toLowerCase())
)

const isFetched = createDraftSafeSelector(
    getFeatureFlags,
    (FeatureFlags: any) => FeatureFlags?.isFetched,
)

// GENERAL
const takes = [
    ...requestFeatureFlags.takes
]

const FeatureFlagsInitialState = {
    [name]: initialState,
}

const FeatureFlagsReducer = {
    [name]: featureFlagsSlice.reducer,
}

const FeatureFlagsActions = {
    ...featureFlagsSlice.actions
}



// EXPORTS
export default featureFlagsSlice.reducer

export {
    takes,
    FeatureFlagsInitialState as initialState,
    FeatureFlagsReducer as reducer,
    FeatureFlagsActions as actions,

    featureFlags,
    requestFeatureFlags,
    getFeatureFlagsEnabled,

    isFetched,
}
