import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { isAuthorized, useCreator } from './dependencies'
import { getMe, user } from './slice'

const useUser = (fetch = false) => {
    const creator = useCreator()
    const publisherId = creator?.id
    const dispatch = useDispatch()

    const _isAuthorized = useSelector(isAuthorized)
    const _user = useSelector(user)

    useEffect(() => {
        if (!_isAuthorized) {
            return
        }

        if(!publisherId) {
            return
        }

        if (!fetch) {
            return
        }

        dispatch(getMe({
            publisherId,
        }))
    }, [_isAuthorized, publisherId])

    return _user
}

export default useUser
