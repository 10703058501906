import { combineReducers } from 'redux'
import { _network } from '@piconetworks/pkg-network-middleware'

import Authorization from '@/entities/Authorization'
import QueryParams from '@/entities/QueryParams'
import Creator from '@/entities/Creator'
import Product from '@/entities/Product'
import ProductOptions from '@/entities/ProductOptions'
import PaymentMethods from '@/entities/PaymentMethods'
import Cart from '@/entities/Cart'
import User from '@/entities/User'
import FeatureFlags from '@/entities/FeatureFlags'
import CustomFields from '@/entities/CustomFields'
import RefreshTokens from '@/entities/RefreshTokens'
import Newsletters from '@/entities/Newsletters'
import PaymentHistory from '@/entities/PaymentHistory'
import Gift from '@/entities/Gift'
import Events from '@/entities/Events'

export const initialAppState = {
    ...Authorization.initialState,
    ...QueryParams.initialState,
    ...Creator.initialState,
    ...Product.initialState,
    ...ProductOptions.initialState,
    ...PaymentMethods.initialState,
    ...Cart.initialState,
    ...User.initialState,
    ...FeatureFlags.initialState,
    ...CustomFields.initialState,
    ...RefreshTokens.initialState,
    ...Newsletters.initialState,
    ...PaymentHistory.initialState,
    ...Gift.initialState,
    ...Events.initialState,
}

const rootReducer = combineReducers({
    _network,
    ...Authorization.reducer,
    ...QueryParams.reducer,
    ...Creator.reducer,
    ...Product.reducer,
    ...ProductOptions.reducer,
    ...PaymentMethods.reducer,
    ...Cart.reducer,
    ...User.reducer,
    ...FeatureFlags.reducer,
    ...CustomFields.reducer,
    ...RefreshTokens.reducer,
    ...Newsletters.reducer,
    ...PaymentHistory.reducer,
    ...Gift.reducer,
    ...Events.reducer,
})

export default rootReducer
