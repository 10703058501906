import createAsyncSaga from '@/utils/createAsyncSaga'
import * as endpoints from '@piconetworks/pkg-endpoints'

import { productId } from '@/entities/Product'
import { useCreator } from "@/entities/Creator";
import { isAuthorized, deauthorize } from '@/entities/Authorization'
const name = 'newsletters'

export {
    name,
    createAsyncSaga,
    endpoints,
    useCreator,
    isAuthorized,
    
    deauthorize,
    productId,
}
