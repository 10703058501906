import { useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import {
    selectedSwitchNow,
    setSelectedSwitchNow,
} from "@/entities/Cart"
import { usePaymentHistory } from '@/entities/PaymentHistory'
import {
    selectedProductOptionId,
    selectedCustomPrice,
} from '@/entities/ProductOptions'
import { useCreator } from '@/entities/Creator'

const SwitchPicker = () => {
    const { currentProductOptionId } = usePaymentHistory()
    const _selectedProductOptionId = useSelector(selectedProductOptionId)
    const _selectedCustomPrice = useSelector(selectedCustomPrice)
    const creator = useCreator()

    const showSwitchPicker = !!currentProductOptionId && currentProductOptionId !== _selectedProductOptionId

    const _selectedSwitchNow = useSelector(selectedSwitchNow)
    const dispatch = useDispatch()

    const _setSelectedSwitchNow = useCallback((value = false) => {
        if (_selectedSwitchNow === value) {
            return
        }

        dispatch(setSelectedSwitchNow(value))
    }, [dispatch, setSelectedSwitchNow, _selectedSwitchNow])

    if (!showSwitchPicker) {
        return null
    }

    return (
        <div className="grid grid-cols-2 gap-3 mb-4">
            <button
                data-ui={!!_selectedSwitchNow && 'checked'}
                className="text-xs py-2 rounded-lg font-semibold bg-picoblack bg-opacity-5 data-checked:bg-violet-100 data-checked:text-violet-500 data-checked:bg-opacity-100"
                onClick={() => _setSelectedSwitchNow(true)}
            >Switch Now</button>
            <button
                data-ui={!_selectedSwitchNow && 'checked'}
                className="text-xs py-2 rounded-lg font-semibold bg-picoblack bg-opacity-5 data-checked:bg-violet-100 data-checked:text-violet-500 data-checked:bg-opacity-100"
                onClick={() => _setSelectedSwitchNow(false)}
            >Switch Later</button>
        </div>
    )
}

export default SwitchPicker
