import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { selectedSwitchNow } from "@/entities/Cart"

const pillStatuses = {
    prorated: 'PRORATED',
    default: null,
}

const useIsProrated = () => {
    const [ isProrated, setIsProrated ]: any = useState(false)

    const _selectedSwitchNow = useSelector(selectedSwitchNow)

    useEffect(() => {
        if (_selectedSwitchNow) {
            setIsProrated(true)
        } else {
            setIsProrated(false)
        }
    }, [_selectedSwitchNow])

    return isProrated
}



const InfomaticPill = () => {
    const [ pillStatus, setPillStatus ]: any = useState(pillStatuses.default)

    const isProrated = useIsProrated()

    useEffect(() => {
        let newPillStatus
        if (isProrated) {
            newPillStatus = pillStatuses.prorated
        }

        if (pillStatus !== newPillStatus) {
            setPillStatus(newPillStatus)
        }
    }, [
        pillStatus,
        setPillStatus,
        isProrated,
    ])

    if (!pillStatus) {
        return null
    }

    return (
        <div className="rounded-lg text-xs bg-gray-400 inline-block py-1 px-2 mb-2 font-medium text-gray-700 tracking-pill">
            {pillStatus}
        </div>
    )
}

export default InfomaticPill
