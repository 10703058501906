import { createAsyncSaga, name, endpoints } from '../dependencies'

const getMe = createAsyncSaga(
    `${name}/getMe`,
    function* ({
        payload: {
            publisherId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
         
            const response = yield request({
                method: 'get',
                baseURL: endpoints.PICO_API_URL(),
                url: `${endpoints.ME()}`,
                headers: {
                    publisherid: publisherId,
                }
            })

            yield fulfilled(response)
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default getMe
