import {
    createDraftSafeSelector,
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit'

import { deauthorize, name } from '../dependencies'
import fetchCustomFields from './fetchCustomFields'
import saveCustomFields from './saveCustomFields'

const customFieldsAdapter = createEntityAdapter({
    selectId: (customField: any) => customField?.id,
    sortComparer: (a: any, b: any) => parseInt(a.order) - parseInt(b.order),
})

const initialState = customFieldsAdapter.getInitialState({
    fetchCustomFieldsError: null,
    fetchCustomFieldsInProgress: false,
    customFieldsEditedValues: {},
    isCustomFieldsFormValid: false,
})

const customFieldsSlice = createSlice({
    name,
    initialState,
    reducers: {
        updateEditedCustomFields: (state: any, { payload : {
            isCustomFieldsFormValid,
            customFieldsEditedValues,
        } }: any) => {
            state.customFieldsEditedValues = customFieldsEditedValues
            state.isCustomFieldsFormValid = isCustomFieldsFormValid
        },
    },
    extraReducers: {
        [fetchCustomFields.pending]: (state: any, { payload }: any) => {
            state.fetchCustomFieldsInProgress = true
            state.fetchCustomFieldsError = null
        },
        [fetchCustomFields.fulfilled]: (state: any, { payload }: any) => {
            customFieldsAdapter.setAll(state, payload?.field || [])
            state.fetchCustomFieldsInProgress = false
            state.fetchCustomFieldsError = null

        },
        [fetchCustomFields.rejected]: (state: any, { payload: error }: any) => {
            state.fetchCustomFieldsInProgress = false
            state.fetchCustomFieldsError = error
            customFieldsAdapter.removeAll(state)
        },
        [deauthorize]: (state: any, { payload }: any) => {
            state.isCustomFieldsFormValid = initialState.isCustomFieldsFormValid
            state.customFieldsEditedValues = initialState.customFieldsEditedValues
            state.fetchCustomFieldsInProgress = initialState.fetchCustomFieldsInProgress
            state.fetchCustomFieldsError = initialState.fetchCustomFieldsError
        },
    },
})

// SELECTORS
const getCustomFields = (state: any) => state[name]
const {
    selectAll,
    selectById,
    selectEntities,
    selectIds,
    selectTotal,
} = customFieldsAdapter.getSelectors((state: any) => state[name])

const {
    updateEditedCustomFields,
} = customFieldsSlice.actions

const customFields = createDraftSafeSelector(
    getCustomFields,
    (CustomFields: any) => CustomFields,
)

const isCustomFieldsFormValid = createDraftSafeSelector(
    customFields,
    (CustomFields: any) => CustomFields?.isCustomFieldsFormValid,
)

const customFieldsEditedValues = createDraftSafeSelector(
    customFields,
    (CustomFields: any) => CustomFields?.customFieldsEditedValues,
)

// GENERAL
const CustomFieldsInitialState = {
    [name]: initialState,
}

const CustomFieldsReducer = {
    [name]: customFieldsSlice.reducer,
}

const takes = [
    ...fetchCustomFields.takes,
    ...saveCustomFields.takes
]

const CustomFieldsActions = {
    ...customFieldsSlice.actions
}

// EXPORTS
export default customFieldsSlice.reducer

export {
    CustomFieldsInitialState as initialState,
    CustomFieldsReducer as reducer,
    CustomFieldsActions as actions,

    takes,
    fetchCustomFields,
    saveCustomFields,
    customFields,
    selectAll,
    selectById,
    selectEntities,
    selectIds,
    selectTotal,

    updateEditedCustomFields,
    isCustomFieldsFormValid,
    customFieldsEditedValues,
}
