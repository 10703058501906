import { useEffect } from 'react'
import { useUser, useCustomFields, updateEditedCustomFields, useAnalytics } from './dependencies'

import { Form, useFormState } from 'react-final-form'
import isEmpty from 'lodash/isEmpty'
import { useDispatch } from 'react-redux'

import Property from './Properties'

const CustomFieldsForm = () => {
    const customFields = useCustomFields()
    const formState = useFormState()
    const isCustomFieldsFormValid = formState.valid
    const formValues = formState.values
    const dispatch = useDispatch()

    useEffect(() => {
        const customFieldsEditedValues = (isCustomFieldsFormValid) ? formValues : {}

        dispatch(updateEditedCustomFields({
            isCustomFieldsFormValid,
            customFieldsEditedValues,
        }))
    }, [isCustomFieldsFormValid, formValues])

    return (
        <div>
            <h1 className='heading-1 mb-3'>Additional Info</h1>
            {customFields?.map?.((field: any) =>
                <div className='mt-3' key={field.id}>
                    <Property {...field} />
                </div>
            )}
        </div>
    )
}

const FormWrapper = ({ handleSubmit }: any) => {
    return (
        <form onSubmit={handleSubmit} id='customFields'>
            <CustomFieldsForm />
        </form>
    )
}


const CustomFields = () => {
    const user = useUser()
    const customFields = useCustomFields()
    const requiredCustomFields = customFields?.filter?.((field: any) => field.required)

    return (
        <Form
            component={FormWrapper}
            onSubmit={() => {}}
            mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                    changeValue(state, field, () => value)
                }
            }}
            initialValues={user?.custom_properties || {}}
            validate={values => {
                const errors: any = {}
                requiredCustomFields.forEach((field: any) => {
                    if (isEmpty(values?.[field?.connected_property?.id])) {
                        errors[field?.connected_property?.id] = 'Required'
                    }
                })

                return errors
            }}
        />
    )
}


export default CustomFields