import {
    initialState,
    reducer,
    takes,
    actions,
    featureFlags,
    requestFeatureFlags,
    getFeatureFlagsEnabled,
} from './slice'

import {
    constants,
} from './dependencies'

import useFeatureFlags from './useFeatureFlags'

export default {
    initialState,
    reducer,
    takes,
    actions,
}

export {
    featureFlags,
    requestFeatureFlags,
    getFeatureFlagsEnabled,
    useFeatureFlags,
    constants,
}
