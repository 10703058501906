import createAsyncSaga from '@/utils/createAsyncSaga'
import * as endpoints from '@piconetworks/pkg-endpoints'
import { isAuthorized } from '@/entities/Authorization'
import { deauthorize } from '@/entities/Authorization'

import { useCreator } from '@/entities/Creator'

const name = 'user'

export {
    name,
    useCreator,
    createAsyncSaga,
    isAuthorized,
    endpoints,
    deauthorize,
}