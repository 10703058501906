import {
    REMOVE_TOKENS_SUCCESS,
    REMOVE_TOKENS_ERROR,
} from '@piconetworks/pkg-network-middleware'

import { deauthorize, isAuthorized } from './index'
import { createAsyncSaga, name, endpoints } from '../dependencies'
import { select, put, call, take } from 'redux-saga/effects'
import logger from '@/lib/logger'
import { addError } from '@/lib/rum'


const exchangeClaim = createAsyncSaga(
    `${name}/exchangeClaim`,
    function* ({
        payload: {
            claim = null
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!claim) {
                throw new Error('claim not provided')
            }

            const isAuthorizedOnStart = yield select(isAuthorized)

            if (isAuthorizedOnStart) {
                yield put(yield call(deauthorize))

                const removeTokenResults = yield take([REMOVE_TOKENS_SUCCESS, REMOVE_TOKENS_ERROR])

                if (removeTokenResults.type === REMOVE_TOKENS_ERROR) {
                    throw new Error('update default payment method failed')
                }
            }

            const response = yield request({
                method: 'get',
                baseURL: endpoints.PICO_API_URL(),
                url: `${endpoints.AUTH_USER_CLAIM()}/${claim}`,
            })

            yield fulfilled(response)
        } catch (error) {
            logger.error(`${name}/exchangeClaim`, {payload}, error)
            addError(error, {payload})
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default exchangeClaim
