import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { selectedSwitchNow } from "@/entities/Cart"


const SwitchNowError = () => {



    return (
        <div className="rounded-lg text-xs text-center inline-block py-1 px-2 mb-2">
            <h1 className='heading-1 text-picoblack'>
                Unable to switch now
            </h1>
            <p className='text-sm text-gray-700'>
                The current state of your active subscription only allows you to switch to this new plan at the end of your billing period.
            </p>
        </div>
    )
}

export default SwitchNowError
