import { useCallback, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useRouter } from "next/router"

import { normalize } from '@piconetworks/pkg-currency'

import {
    useProductOptions,
    setProductOption,
    selectedProductOptionId,
    selectedProductOption,
    selectedCustomPrice,
} from "@/entities/ProductOptions"
import IconCrossmarkEncircled from "@/components/SVG/IconCrossmarkEncircled"

import { isDonation, changeWording, getProductByOptionId } from "@/entities/Product"
import { contributionType } from "@/entities/Creator"

import DonationProductOption from "./ProductOption/DonationProductOption"
import GenericProductOption from "./ProductOption/GenericProductOption"
import DonationCustomProductOption from "./ProductOption/DonationCustomProductOption"

const ProductOptionPicker = ({
    onClickCloseChangeSubscription = () => {},
}) => {
    const _selectedProductOption = useSelector(selectedProductOption)

    const [selectedInterval, setSelectedInterval]: any = useState(null)

    const dispatch = useDispatch()

    const _selectedProductOptionId = useSelector(selectedProductOptionId)
    const _selectedCustomPrice = useSelector(selectedCustomPrice)
    const _contributionType = useSelector(contributionType)
    const __changeWording = useSelector(changeWording)
    const _changeWording = _contributionType === 'tip' ? 'tip' : __changeWording
    const _isDonation = useSelector(isDonation)

    const productOptions = useProductOptions()

    const hasOneTimeOption = productOptions.reduce((acc = false, { interval = null} = {}) => {
        if (interval === null) {
            return true
        }

        return acc
    }, false)

    const hasDayOption = productOptions.reduce((acc = false, { interval = null} = {}) => {
        if (interval === 'day') {
            return true
        }

        return acc
    }, false)

    const hasMonthlyOption = productOptions.reduce((acc = false, { interval = null} = {}) => {
        if (interval === 'month') {
            return true
        }

        return acc
    }, false)

    const hasAnnualOption = productOptions.reduce((acc = false, { interval = null} = {}) => {
        if (interval === 'year') {
            return true
        }

        return acc
    }, false)

    const sortedProductOptions = productOptions
        ?.sort(({ price: a = 0 }, { price: b = 0 }) => (
            a - b
        ))

    useEffect(() => {
        if (_selectedProductOption?.interval === 'day') {
            setSelectedInterval('day')
        } else if (_selectedProductOption?.interval === 'month') {
            setSelectedInterval('month')
        } else if (_selectedProductOption?.interval === 'year') {
            setSelectedInterval('year')
        } else if (_selectedProductOption?.interval === null) {
            setSelectedInterval('oneTime')
        }
    }, [])

    const onClickDay = useCallback(() => setSelectedInterval('day'), [setSelectedInterval])
    const onClickMonthly = useCallback(() => setSelectedInterval('month'), [setSelectedInterval])
    const onClickAnnual = useCallback(() => setSelectedInterval('year'), [setSelectedInterval])
    const onClickOneTime = useCallback(() => setSelectedInterval('oneTime'), [setSelectedInterval])

    const router = useRouter()
    const onClickProductOption = useCallback(({
        id = null,
        customValue = null,
        autoClose = true,
    }) => {
        if (!id) {
            return
        }

        const chosenProductOption = productOptions.find(({ id: _id = null} = {}) => _id === id)
        const chosenCustomPrice = normalize({
            currencyCode: chosenProductOption?.currency,
            amount: customValue,
        })

        if (
            (_selectedProductOptionId !== chosenProductOption?.id)
            || (_selectedCustomPrice !== chosenCustomPrice)
        ) {
            dispatch(setProductOption({
                selectedProductOptionId: chosenProductOption?.id,
                selectedCustomPrice: chosenCustomPrice,
                isProductOptionsFormValid: true,
            }))
        }

        if (_selectedProductOptionId !== chosenProductOption?.id) {
            router.replace(`/${chosenProductOption?.id}`)
        }

        if (autoClose) {
            onClickCloseChangeSubscription()
        }
    }, [
        dispatch,
        productOptions,
        onClickCloseChangeSubscription,
        _selectedProductOptionId,
        _selectedCustomPrice,
    ])

    const _onClickCloseChangeSubscription = useCallback(() => {
        router.replace(`/${_selectedProductOptionId}`)
        onClickCloseChangeSubscription()
    }, [onClickCloseChangeSubscription, _selectedProductOptionId])

    let optionDurationCount = 0
    optionDurationCount = hasOneTimeOption ? optionDurationCount + 1 : optionDurationCount
    optionDurationCount = hasDayOption ? optionDurationCount + 1 : optionDurationCount
    optionDurationCount = hasMonthlyOption ? optionDurationCount + 1 : optionDurationCount
    optionDurationCount = hasAnnualOption ? optionDurationCount + 1 : optionDurationCount

    const hasOneOptionDuration = optionDurationCount === 1

    return (
        <div>
            <hr className='mb-4 w-full' />
            <div className='flex justify-between mb-4'>
                <h2 className="font-medium">Change {_changeWording}</h2>
                <button onClick={_onClickCloseChangeSubscription}><IconCrossmarkEncircled /></button>
            </div>
            {!hasOneOptionDuration && <div className={`flex mb-4 ${!(hasAnnualOption && hasAnnualOption) && 'hide'}`}>
                {hasOneTimeOption && (
                    <button
                        data-ui={selectedInterval === 'oneTime' && 'checked'}
                        className="text-xs w-full mr-1.5 py-2 rounded-lg font-semibold bg-picoblack bg-opacity-5 data-checked:bg-violet-100 data-checked:text-violet-500 data-checked:bg-opacity-100"
                        onClick={onClickOneTime}
                    >One Time</button>
                )}
                {hasDayOption && (
                    <button
                        data-ui={selectedInterval === 'day' && 'checked'}
                        className="text-xs w-full mx-1.5 py-2 rounded-lg font-semibold bg-picoblack bg-opacity-5 data-checked:bg-violet-100 data-checked:text-violet-500 data-checked:bg-opacity-100"
                        onClick={onClickDay}
                    >Day</button>
                )}
                {hasMonthlyOption && (
                    <button
                        data-ui={selectedInterval === 'month' && 'checked'}
                        className="text-xs w-full mx-1.5 py-2 rounded-lg font-semibold bg-picoblack bg-opacity-5 data-checked:bg-violet-100 data-checked:text-violet-500 data-checked:bg-opacity-100"
                        onClick={onClickMonthly}
                    >Monthly</button>
                )}
                {hasAnnualOption && (
                    <button
                        data-ui={selectedInterval === 'year' && 'checked'}
                        className="text-xs w-full ml-1.5 py-2 rounded-lg font-semibold bg-picoblack bg-opacity-5 data-checked:bg-violet-100 data-checked:text-violet-500 data-checked:bg-opacity-100"
                        onClick={onClickAnnual}
                    >Annual</button>
                )}
            </div>}
            <div className='grid grid-cols-1 mb-4'>
                <div className='w-full'>
                {sortedProductOptions.filter(({ price = null, type = null }) => price !== null && type === _selectedProductOption.type).map(({ id = null, interval = null }) => (
                    <GenericProductOption
                        key={id}
                        id={id}
                        hide={selectedInterval !== interval}
                        onChange={onClickProductOption}
                        checked={_selectedProductOptionId === id}
                    />
                ))}
                </div>
            </div>
        </div>
    )
}

const DonationOptionPicker = ({
    onClickCloseChangeSubscription = () => {},
    
}) => {
    const _selectedProductOption = useSelector(selectedProductOption)

    const [selectedInterval, setSelectedInterval]: any = useState(null)

    const dispatch = useDispatch()

    const _selectedProductOptionId = useSelector(selectedProductOptionId)
    const _selectedCustomPrice = useSelector(selectedCustomPrice)
    const _contributionType = useSelector(contributionType)
    const __changeWording = useSelector(changeWording)
    const _changeWording = _contributionType === 'tip' ? 'tip' : __changeWording

    const _state = useSelector((state: any) => state)
    const _getProductByOptionId = useSelector((state: any) => getProductByOptionId(state, _selectedProductOptionId))

    const productOptions = useProductOptions()
    const relatedProducts = productOptions.reduce((acc: any = {}, { id }: any = {}) => {
        const product = getProductByOptionId(_state, id)

        if (!acc[product?.id]) {
            acc[product?.id] = product
        }

        return acc
    }, {})

    const hasOneTimeOption = productOptions.reduce((acc = false, { interval = null} = {}) => {
        if (interval === null) {
            return true
        }

        return acc
    }, false)

    const hasDayOption = productOptions.reduce((acc = false, { interval = null} = {}) => {
        if (interval === 'day') {
            return true
        }

        return acc
    }, false)

    const hasMonthlyOption = productOptions.reduce((acc = false, { interval = null} = {}) => {
        if (interval === 'month') {
            return true
        }

        return acc
    }, false)

    const hasAnnualOption = productOptions.reduce((acc = false, { interval = null} = {}) => {
        if (interval === 'year') {
            return true
        }

        return acc
    }, false)

    const sortedProductOptions = productOptions
        ?.sort(({ price: a = 0 }, { price: b = 0 }) => (
            a - b
        ))

    useEffect(() => {
        if (_selectedProductOption?.interval === 'day') {
            setSelectedInterval('day')
        } else if (_selectedProductOption?.interval === 'month') {
            setSelectedInterval('month')
        } else if (_selectedProductOption?.interval === 'year') {
            setSelectedInterval('year')
        } else if (_selectedProductOption?.interval === null) {
            setSelectedInterval('oneTime')
        }
    }, [])

    const onClickDay = useCallback(() => setSelectedInterval('day'), [setSelectedInterval])
    const onClickMonthly = useCallback(() => setSelectedInterval('month'), [setSelectedInterval])
    const onClickAnnual = useCallback(() => setSelectedInterval('year'), [setSelectedInterval])
    const onClickOneTime = useCallback(() => setSelectedInterval('oneTime'), [setSelectedInterval])

    const router = useRouter()
    const onClickProductOption = useCallback(({
        id = null,
        customValue = null,
        autoClose = true,
    }) => {
        if (!id) {
            return
        }

        const chosenProductOption = productOptions.find(({ id: _id = null} = {}) => _id === id)
        const chosenCustomPrice = normalize({
            currencyCode: chosenProductOption?.currency,
            amount: customValue,
        })

        if (
            (_selectedProductOptionId !== chosenProductOption?.id)
            || (_selectedCustomPrice !== chosenCustomPrice)
        ) {
            dispatch(setProductOption({
                selectedProductOptionId: chosenProductOption?.id,
                selectedCustomPrice: chosenCustomPrice,
                isProductOptionsFormValid: true,
            }))
        }

        if (_selectedProductOptionId !== chosenProductOption?.id || chosenCustomPrice !== _selectedCustomPrice) {
            if (chosenCustomPrice) {
                router.replace(`/${chosenProductOption?.id}?custom_price=${chosenCustomPrice}`)
            } else {
                router.replace(`/${chosenProductOption?.id}`)
            }
        }

        if (autoClose) {
            onClickCloseChangeSubscription()
        }
    }, [
        dispatch,
        productOptions,
        onClickCloseChangeSubscription,
        _selectedProductOptionId,
        _selectedCustomPrice,
    ])

    const _onClickCloseChangeSubscription = useCallback(() => {
        if (!_selectedCustomPrice) {
            router.replace(`/${_selectedProductOptionId}`)
        } else {
            router.replace(`/${_selectedProductOptionId}?custom_price=${_selectedCustomPrice}`)
        }
        onClickCloseChangeSubscription()
    }, [onClickCloseChangeSubscription, _selectedProductOptionId, _selectedCustomPrice])

    let optionDurationCount = 0
    optionDurationCount = hasOneTimeOption ? optionDurationCount + 1 : optionDurationCount
    optionDurationCount = hasDayOption ? optionDurationCount + 1 : optionDurationCount
    optionDurationCount = hasMonthlyOption ? optionDurationCount + 1 : optionDurationCount
    optionDurationCount = hasAnnualOption ? optionDurationCount + 1 : optionDurationCount

    const hasOneOptionDuration = optionDurationCount === 1

    return (
        <div>
            <hr className='mb-4 w-full' />
            <div className='flex justify-between mb-4'>
                <h2 className="font-medium">Change {_changeWording}</h2>
                <button onClick={_onClickCloseChangeSubscription}><IconCrossmarkEncircled /></button>
            </div>
            {!hasOneOptionDuration && <div className={`flex mb-4 ${!(hasAnnualOption && hasAnnualOption) && 'hide'}`}>
                {hasOneTimeOption && (
                    <button
                        data-ui={selectedInterval === 'oneTime' && 'checked'}
                        className="text-xs w-full mr-1.5 py-2 rounded-lg font-semibold bg-picoblack bg-opacity-5 data-checked:bg-violet-100 data-checked:text-violet-500 data-checked:bg-opacity-100"
                        onClick={onClickOneTime}
                    >One Time</button>
                )}
                {hasDayOption && (
                    <button
                        data-ui={selectedInterval === 'day' && 'checked'}
                        className="text-xs w-full mx-1.5 py-2 rounded-lg font-semibold bg-picoblack bg-opacity-5 data-checked:bg-violet-100 data-checked:text-violet-500 data-checked:bg-opacity-100"
                        onClick={onClickDay}
                    >Day</button>
                )}
                {hasMonthlyOption && (
                    <button
                        data-ui={selectedInterval === 'month' && 'checked'}
                        className="text-xs w-full mx-1.5 py-2 rounded-lg font-semibold bg-picoblack bg-opacity-5 data-checked:bg-violet-100 data-checked:text-violet-500 data-checked:bg-opacity-100"
                        onClick={onClickMonthly}
                    >Monthly</button>
                )}
                {hasAnnualOption && (
                    <button
                        data-ui={selectedInterval === 'year' && 'checked'}
                        className="text-xs w-full ml-1.5 py-2 rounded-lg font-semibold bg-picoblack bg-opacity-5 data-checked:bg-violet-100 data-checked:text-violet-500 data-checked:bg-opacity-100"
                        onClick={onClickAnnual}
                    >Annual</button>
                )}
            </div>}

            {Object.entries(relatedProducts).map(([key, value]: any) => {
                const thisProductTitle = value?.title
                const theseProductOptions = [...(value?.options || [])]
                const _sortedProductOptions = theseProductOptions
                    ?.sort(({ price: a = 0 }, { price: b = 0 }) => (
                        a - b
                    )) || []

                const matchedOptionCount = _sortedProductOptions.filter(({ interval} = {}) => (
                    interval === selectedInterval
                    || (interval === null && selectedInterval === 'oneTime')
                )).length

                if (matchedOptionCount === 0) {
                    return null
                }

                return (
                    <>
                        <hr className='my-4 w-full' />
                        <h2 className="font-medium mt-4 mb-2">{thisProductTitle}</h2>
                        {selectedInterval === 'oneTime' && (
                            <div className='flex w-full mb-3'>
                                {_sortedProductOptions.filter(({ price = null, interval = null }) => price !== null && interval === null).map(({ id = null, interval = null }) => (
                                    <DonationProductOption
                                        key={id}
                                        id={id}
                                        hide={selectedInterval !== 'oneTime'}
                                        onChange={onClickProductOption}
                                        checked={_selectedProductOptionId === id}
                                    />
                                ))}
                            </div>
                        )}
                        {selectedInterval === 'oneTime' && (
                            <div className='flex'>
                                {_sortedProductOptions.filter(({ price = null, interval = null }) => price === null && interval === null).map(({ id = null, interval = null }) => (
                                    <DonationCustomProductOption
                                        key={id}
                                        id={id}
                                        hide={selectedInterval !== 'oneTime'}
                                        onChange={onClickProductOption}
                                        checked={_selectedProductOptionId === id}
                                    />
                                ))}
                            </div>
                        )}
                        {selectedInterval === 'month' && (
                            <div className='flex w-full mb-3'>
                                {_sortedProductOptions.filter(({ price = null, interval = null }) => price !== null && interval === 'month').map(({ id = null, interval = null }) => (
                                    <DonationProductOption
                                        key={id}
                                        id={id}
                                        hide={selectedInterval !== interval}
                                        onChange={onClickProductOption}
                                        checked={_selectedProductOptionId === id}
                                    />
                                ))}
                            </div>
                        )}
                        {selectedInterval === 'month' && (
                            <div className='flex'>
                                {_sortedProductOptions.filter(({ price = null }) => price === null).map(({ id = null, interval = 'month' }) => (
                                    <DonationCustomProductOption
                                        key={id}
                                        id={id}
                                        hide={selectedInterval !== interval}
                                        onChange={onClickProductOption}
                                        checked={_selectedProductOptionId === id}
                                    />
                                ))}
                            </div>
                        )}
                        {selectedInterval === 'year' && (
                            <div className='flex w-full mb-3'>
                                {_sortedProductOptions.filter(({ price = null, interval = null }) => price !== null && interval === 'year').map(({ id = null, interval = null }) => (
                                    <DonationProductOption
                                        key={id}
                                        id={id}
                                        hide={selectedInterval !== interval}
                                        onChange={onClickProductOption}
                                        checked={_selectedProductOptionId === id}
                                    />
                                ))}
                            </div>
                        )}
                        {selectedInterval === 'year' && (
                            <div className='flex'>
                                {_sortedProductOptions.filter(({ price = null }) => price === null).map(({ id = null, interval = 'year' }) => (
                                    <DonationCustomProductOption
                                        key={id}
                                        id={id}
                                        hide={selectedInterval !== interval}
                                        onChange={onClickProductOption}
                                        checked={_selectedProductOptionId === id}
                                    />
                                ))}
                            </div>
                        )}
                    </>
                )
            })}
        </div>
    )
}

const ProductOptionPickerIndex = ({
    onClickCloseChangeSubscription = () => {},
}) => {
    const _isDonation = useSelector(isDonation)

    if (_isDonation) {
        return <DonationOptionPicker onClickCloseChangeSubscription={onClickCloseChangeSubscription} />
    } else {
        return <ProductOptionPicker onClickCloseChangeSubscription={onClickCloseChangeSubscription} />
    }
}

export default ProductOptionPickerIndex
