import { creator} from '@/entities/Creator'
import { select } from 'redux-saga/effects'
import { selectAll } from './index'
import { createAsyncSaga, name, endpoints } from '../dependencies'
import logger from '@/lib/logger'
import { addError } from '@/lib/rum'


const fetchProduct = createAsyncSaga(
    `${name}/fetchProduct`,
    function* ({
        payload: {
            productId = null,
            productOptionId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!productId && !productOptionId) {
                throw new Error('neither productId nor productOptionId provided')
            }

            const _creator = yield select(creator)
            const allProducts = yield select(selectAll)

            let productInMemory = null
            if (productId) {
                productInMemory = allProducts.find((product) => (
                    product?.id === productId
                ))
            } else if (productOptionId) {
                productInMemory = allProducts.find((product) => (
                    product?.options.find((option) => option?.id === productOptionId)
                ))
            }

            if (productInMemory) {
                yield fulfilled({
                    product: productInMemory,
                })
            } else if (!productInMemory) {
                let response = null
                if (productId) {
                    response = yield request({
                        method: 'post',
                        baseURL: endpoints.PICO_API_URL(),
                        url: `/query`,
                        headers: {
                            ...(_creator?.id && {publisherid: _creator?.id,})
                        },
                        data: {
                            query: `{
                                product(id:"${productId}")
                                {
                                    id,
                                    publisher_id,
                                    type,
                                    title,
                                    pledge_mode,
                                    match_type,
                                    product_label,
                                    custom_text{
                                        filename
                                    },
                                    options{
                                        id,
                                        type,
                                        price,
                                        currency,
                                        recurring,
                                        per_seat,
                                        interval,
                                        user_created,
                                        interval_quantity,
                                        short_code,
                                        trial_interval,
                                        trial_quantity,
                                        trial_price
                                    }
                                }
                            }`
                        }
                    })
                } else if (productOptionId) {
                    response = yield request({
                        method: 'post',
                        baseURL: endpoints.PICO_API_URL(),
                        url: `/query`,
                        headers: {
                            ...(_creator?.id && {publisherid: _creator?.id,})
                        },
                        data: {
                            query: `{
                                product(product_option_id:"${productOptionId}")
                                {
                                    id,
                                    publisher_id,
                                    type,
                                    title,
                                    pledge_mode,
                                    match_type,
                                    product_label,
                                    custom_text{
                                        filename
                                    },
                                    options{
                                        id,
                                        type,
                                        price,
                                        currency,
                                        recurring,
                                        per_seat,
                                        interval,
                                        user_created,
                                        interval_quantity,
                                        short_code,
                                        trial_interval,
                                        trial_quantity,
                                        trial_price
                                    }
                                }
                            }`
                        }
                    })
                }
                yield fulfilled(response?.data)
            }
        } catch (error) {
            logger.log(`${name}/fetchProduct`, {payload}, error)
            addError(error, {payload})
            yield rejected({
                error: error?.message,
            })
        }
    }
)



export default fetchProduct
