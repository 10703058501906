export const AppleIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M21.2773 17.924C20.9295 18.7275 20.5178 19.4672 20.0408 20.1472C19.3905 21.0743 18.8581 21.716 18.4478 22.0724C17.8118 22.6573 17.1304 22.9569 16.4006 22.9739C15.8767 22.9739 15.245 22.8248 14.5096 22.5224C13.7717 22.2214 13.0937 22.0724 12.4737 22.0724C11.8235 22.0724 11.1261 22.2214 10.3802 22.5224C9.63315 22.8248 9.03133 22.9824 8.5712 22.998C7.87143 23.0278 7.17392 22.7198 6.4777 22.0724C6.03333 21.6848 5.47751 21.0204 4.81167 20.0791C4.09727 19.0739 3.50994 17.9084 3.04981 16.5795C2.55703 15.1442 2.31 13.7543 2.31 12.4087C2.31 10.8673 2.64306 9.5379 3.31018 8.42385C3.83448 7.52901 4.53199 6.82313 5.40496 6.30493C6.27795 5.78674 7.2212 5.52268 8.237 5.50578C8.79282 5.50578 9.5217 5.67771 10.4275 6.0156C11.3307 6.35462 11.9106 6.52655 12.1649 6.52655C12.355 6.52655 12.9993 6.32552 14.0915 5.92474C15.1243 5.55306 15.996 5.39916 16.7101 5.45978C18.6452 5.61595 20.099 6.37876 21.0658 7.75304C19.3352 8.80164 18.4791 10.2703 18.4961 12.1544C18.5117 13.622 19.0441 14.8432 20.0904 15.8129C20.5646 16.2629 21.0942 16.6108 21.6834 16.8578C21.5556 17.2283 21.4207 17.5833 21.2773 17.924ZM16.8393 0.460131C16.8393 1.61039 16.4191 2.68439 15.5814 3.67847C14.5706 4.86024 13.348 5.54312 12.0221 5.43536C12.0052 5.29737 11.9954 5.15213 11.9954 4.99951C11.9954 3.89526 12.4761 2.71349 13.3298 1.74724C13.756 1.25801 14.298 0.851218 14.9554 0.526715C15.6113 0.207053 16.2317 0.0302728 16.8152 0C16.8322 0.153772 16.8393 0.307554 16.8393 0.460116V0.460131Z" fill="black" />
    </svg>
)


export const FacebookIcon = () => (

    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M23 12.0018C23 5.92568 18.0751 1 12 1C5.92486 1 1 5.92568 1 12.0018C1 17.4932 5.02254 22.0446 10.2812 22.87V15.182H7.48828V12.0018H10.2812V9.57799C10.2812 6.82065 11.9235 5.29759 14.4361 5.29759C15.6396 5.29759 16.8984 5.51247 16.8984 5.51247V8.21995H15.5114C14.1449 8.21995 13.7188 9.06801 13.7188 9.93806V12.0018H16.7695L16.2818 15.182H13.7188V22.87C18.9775 22.0446 23 17.4932 23 12.0018Z" fill="#1877F2" />
    </svg>
)


export const TwitterIcon = () => (

    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M22 5.92167C21.251 6.25302 20.4567 6.47042 19.6433 6.56667C20.4997 6.0551 21.1407 5.24943 21.4467 4.3C20.6415 4.7778 19.7604 5.11405 18.8417 5.29417C18.0652 4.46728 16.981 3.99879 15.8467 4C14.595 3.99887 13.4113 4.56958 12.6327 5.54966C11.8541 6.52974 11.5658 7.81181 11.85 9.03084C8.55215 8.8665 5.47888 7.31063 3.39417 4.75C3.02893 5.37504 2.83678 6.08608 2.8375 6.81C2.8375 8.23167 3.5625 9.485 4.6625 10.2192C4.01121 10.1987 3.37417 10.0233 2.80417 9.70751V9.75917C2.80613 11.711 4.18197 13.3917 6.095 13.7792C5.49075 13.9441 4.85674 13.9683 4.24167 13.85C4.77994 15.5204 6.32034 16.664 8.075 16.6958C6.62095 17.835 4.82629 18.4525 2.97917 18.4492C2.64833 18.4492 2.32083 18.4308 2 18.3925C3.87634 19.5969 6.05952 20.2362 8.28917 20.2342C15.8358 20.2342 19.9625 13.99 19.9625 8.57584C19.9625 8.39834 19.9583 8.22251 19.95 8.04667C20.753 7.46713 21.4461 6.74893 21.9967 5.92584" fill="#1DA1F2" />
    </svg>
)

export const GoogleIcon = () => (

    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M22 12.2273C22 11.5182 21.9351 10.8364 21.8145 10.1818H12.2041V14.05H17.6957C17.4592 15.3 16.7403 16.3591 15.6596 17.0682V19.5773H18.9573C20.8868 17.8364 22 15.2727 22 12.2273Z" fill="#4285F4" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.2041 22C14.9592 22 17.269 21.1046 18.9573 19.5773L15.6596 17.0682C14.7458 17.6682 13.577 18.0227 12.2041 18.0227C9.54638 18.0227 7.29685 16.2636 6.49443 13.9H3.08534V16.4909C4.76438 19.7591 8.21521 22 12.2041 22Z" fill="#34A853" />
        <path fillRule="evenodd" clipRule="evenodd" d="M6.49444 13.9C6.29035 13.3 6.1744 12.6591 6.1744 12C6.1744 11.3409 6.29035 10.7 6.49444 10.1V7.50909H3.08534C2.39425 8.85909 2 10.3864 2 12C2 13.6136 2.39425 15.1409 3.08534 16.4909L6.49444 13.9Z" fill="#FBBC05" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.2041 5.97727C13.7022 5.97727 15.0473 6.48182 16.1048 7.47273L19.0315 4.60455C17.2644 2.99091 14.9545 2 12.2041 2C8.21521 2 4.76438 4.24091 3.08534 7.50909L6.49443 10.1C7.29685 7.73636 9.54638 5.97727 12.2041 5.97727Z" fill="#EA4335" />
    </svg>
)


export const LinkedInIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M20.4 2H3.70833C2.7975 2 2 2.65583 2 3.5575V20.2858C2 21.1924 2.7975 21.9999 3.70833 21.9999H20.3958C21.3124 21.9999 22 21.1875 22 20.2858V3.5575C22.0049 2.65666 21.3116 2 20.4 2ZM8.19998 18.6708H5.33416V9.76248H8.19915L8.19998 18.6708ZM6.86582 8.40832H6.84499C5.92832 8.40832 5.33416 7.72582 5.33416 6.87165C5.33416 6.00082 5.94332 5.33416 6.88165 5.33416C7.81915 5.33416 8.39248 5.99582 8.41332 6.87165C8.41332 7.72582 7.81915 8.40832 6.86582 8.40832ZM18.6708 18.6708H15.8058V13.8C15.8058 12.6333 15.3891 11.8358 14.3516 11.8358C13.56 11.8358 13.0916 12.3725 12.8833 12.8933C12.805 13.0808 12.7841 13.3366 12.7841 13.5966V18.6708H9.91831V9.76248H12.7841V11.0025C13.2008 10.4083 13.8516 9.55415 15.3675 9.55415C17.2491 9.55415 18.6708 10.7941 18.6708 13.4666V18.6708Z" fill="#0A66C2" />
    </svg>
)


export const GithubIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path fillRule="evenodd" clipRule="evenodd" d="M12 1C5.92571 1 1 5.92479 1 12C1 16.8602 4.15196 20.9833 8.52263 22.4381C9.07263 22.5398 9.27429 22.1998 9.27429 21.9087C9.27429 21.6465 9.26375 20.7798 9.25917 19.8609C6.19888 20.5264 5.55308 18.5629 5.55308 18.5629C5.05258 17.2915 4.33162 16.9537 4.33162 16.9537C3.33383 16.2708 4.40725 16.2845 4.40725 16.2845C5.51183 16.3624 6.09346 17.4184 6.09346 17.4184C7.07429 19.1 8.66654 18.6138 9.29446 18.3328C9.393 17.6219 9.67808 17.1365 9.9925 16.8615C7.54958 16.5838 4.98108 15.6405 4.98108 11.4257C4.98108 10.2249 5.41054 9.24312 6.11454 8.47313C5.99996 8.19583 5.62367 7.07704 6.22087 5.56179C6.22087 5.56179 7.14488 5.26617 9.24679 6.68929C10.124 6.44592 11.0655 6.32354 12 6.31942C12.935 6.32354 13.8769 6.44592 14.756 6.68929C16.8551 5.26617 17.7778 5.56179 17.7778 5.56179C18.3763 7.07704 17.9996 8.19583 17.8855 8.47313C18.5913 9.24312 19.0175 10.2244 19.0175 11.4257C19.0175 15.6506 16.4445 16.5806 13.9947 16.8533C14.3893 17.1943 14.7408 17.8639 14.7408 18.8901C14.7408 20.3618 14.7285 21.5466 14.7285 21.9087C14.7285 22.202 14.9265 22.5444 15.4838 22.4367C19.8522 20.9806 23 16.8583 23 12C23 5.92433 18.0752 1 12 1Z" fill="#151513" />
    </svg>
)


