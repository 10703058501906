import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { checkHasPassword, hasPassword } from './slice'

const useCheckHasPassword = () => {
    const dispatch = useDispatch()

    const _hasPassword = useSelector(hasPassword)

    const _checkHasPassword = useCallback((values = {}) => {
        dispatch(checkHasPassword(values))
    }, [dispatch])

    return [_hasPassword, _checkHasPassword]
}

export default useCheckHasPassword
