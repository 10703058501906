import { useSelector } from 'react-redux'
import { isTrial, formattedTrialValue, formattedValue, isSwitchableNow, formattedSwitchProration } from '@/entities/ProductOptions'
import { selectedSwitchNow } from '@/entities/Cart'
import useCheckoutMode from '@/entities/Cart/useCheckoutMode'

const DueToday = () => {
    const { checkoutMode, checkoutModes } = useCheckoutMode()
    const _selectedSwitchNow = useSelector(selectedSwitchNow)


    const _isTrial = useSelector(isTrial)
    const _isSwitchableNow = useSelector(isSwitchableNow)
    const _isSwitchMode = checkoutMode === checkoutModes.switch

    const _formattedValue = useSelector(formattedValue)
    const _formattedTrialValue = useSelector(formattedTrialValue)
    const _formattedSwitchProration = useSelector(formattedSwitchProration)

    let payWording = ''

    if (_isTrial) {
        payWording = _formattedTrialValue
    } else {
        payWording = _formattedValue
    }

    if (_isSwitchMode) {
        if (_isTrial) {
            payWording = _formattedTrialValue
        }
        if (_selectedSwitchNow && _isSwitchableNow) {
            payWording = _formattedSwitchProration.prorateAmount
        }
        if (!_selectedSwitchNow) {
            payWording = _formattedValue
        }
    }

    if (_isSwitchMode) {
        if (_selectedSwitchNow && !_isSwitchableNow) {
            return <></>
        }
    }

    return (
        <>
            <hr className='mt-0 w-full' />
            <div className='flex justify-between flex-wrap mt-5'>
                <h2 className="font-medium text-sm text-black text-opacity-70 font-antartica">Due today</h2>
                <h1 className='font-medium text-base font-antartica'>{payWording}</h1>
            </div>
        </>
    )
}

export default DueToday
