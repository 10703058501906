import { select } from 'redux-saga/effects'
import { createAsyncSaga, name, endpoints } from '../dependencies'
import { newslettersEditedValues } from './index'

const saveNewsletters = createAsyncSaga(
    `${name}/saveNewsletters`,
    function* ({
        payload: {
            publisherId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            const newsletters = yield select(newslettersEditedValues)

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: `${endpoints.NEWSLETTERS_SUBSCRIBE()}`,
                headers: {
                    publisherid: publisherId,
                },
                data: {
                    newsletters,
                    only_add_newsletters: true,
                    sync: true
                }
            })

            yield fulfilled(response)
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default saveNewsletters
