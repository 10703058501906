import React from 'react'
import { Field } from 'react-final-form'


const US = ({ id, required }: any) => {
    return (
        <>
            <div className='mb-3'>
                <label className='label' htmlFor={`${id}.address1`}>Address Line 1{required && '*'}</label>
            </div>
            <Field
                name={`${id}.address1`}
                component={'input'}
                type="text"
                className='input'
            />
            <div className='mb-3'>
                <label className='label' htmlFor={`${id}.address2`}>Address Line 2{required && '*'}</label>
            </div>
            <Field
                name={`${id}.address2`}
                component={'input'}
                type="text"
                className='input'
            />
            <div className='mb-3'>
                <label className='label' htmlFor={`${id}.city`}>City{required && '*'}</label>
            </div>
            <Field
                name={`${id}.city`}
                component={'input'}
                type="text"
                className='input'
            />
            <div className='mb-3'>
                <label className='label' htmlFor={`${id}.state`}>State{required && '*'}</label>
            </div>
            <Field
                name={`${id}.state`}
                component={'input'}
                type="text"
                className='input'
            />
            <div className='mb-3'>
                <label className='label' htmlFor={`${id}.post_code`}>Postal Code{required && '*'}</label>
            </div>
            <Field
                name={`${id}.post_code`}
                component={'input'}
                type="number"
                className='input'
            />

        </>
    )
}

export default US
