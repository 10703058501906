import { datadogLogs } from '@datadog/browser-logs'

const allowedStages = ['production', 'staging', 'qa']

const STAGE = process.env.STAGE ?? 'development'
const APP_VERSION = process.env.VERSION ?? 'local'

const DD_ENABLED = process.env.DATADOG_CLIENT_TOKEN && allowedStages.includes(STAGE)

const loggerHandler = DD_ENABLED ? 'http' : 'console'
const loggerLevel = DD_ENABLED ? 'error' : 'debug'

let logger = console

// datadogLogs.init will fail on the server side, so we need to wrap it in a try/catch
try {
    datadogLogs.init({
        clientToken: process.env.DATADOG_CLIENT_TOKEN ?? '',
        service: 'pay',
        env: STAGE,
        version: APP_VERSION,
        forwardErrorsToLogs: true,
        forwardConsoleLogs: ['error'],
        forwardReports: [],
        silentMultipleInit: true,
        trackSessionAcrossSubdomains: true,
    })

    datadogLogs.logger.setHandler(loggerHandler)
    datadogLogs.logger.setLevel(loggerLevel)
    logger = datadogLogs.logger
} catch (e) {
    console.error('Error initializing datadog logger', e)
    logger = console
}


export default logger
