import { useSelector } from 'react-redux';

import { useCreator } from '@/entities/Creator'
import { outboundClaim } from "@/entities/Authorization";

const MARKETING_SITE_URL = process.env.MARKETING_SITE_URL
const HYPE_PAGE_PREFIX = process.env.HYPE_PAGE_PREFIX || ''

const OrderSummary = ({
    orderSummaryVisible = false,
    showToggle = false,
    onClickToggle = () => { },
}) => {
    const creator = useCreator()
    const creatorIcon = creator?.icon
    const creatorUsername = creator?.username
    const creatorName = creator?.name
    const _outboundClaim = useSelector(outboundClaim)
    const queryParam = _outboundClaim ? `?claim=${_outboundClaim}` : ''
    const creatorHomepageLink = `${MARKETING_SITE_URL}/${HYPE_PAGE_PREFIX}${creatorUsername}${queryParam}`

    return (
        <div className='flex flex-wrap items-center justify-between'>
            <div className='flex items-center'>
                {creatorIcon && (
                    <div className='w-11'>
                        <a href={creatorHomepageLink} target='_blank'>
                            <img className='w-11 h-11 rounded-full ring-2 ring-gray-500' src={creatorIcon} />
                        </a>
                    </div>
                )}
                {creatorName && <div className='text-sm ml-2.5 font-medium leading-5 font-antartica'>{creatorName}</div>}
            </div>
            {!showToggle && (
                <button className='text-violet-500 text-xs font-medium flex items-center justify-between' onClick={() => onClickToggle()}>
                    <span className='mr-2'>
                        {orderSummaryVisible ? 'Hide' : 'Show'} summary
                    </span>
                    {!orderSummaryVisible ? (
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M3.52856 5.52849C3.7888 5.26825 4.20793 5.26825 4.46817 5.52849L8.00004 9.05932L11.5319 5.52849C11.7922 5.26825 12.2113 5.26825 12.4715 5.52849C12.7318 5.78874 12.7318 6.21453 12.4715 6.47477L8.47125 10.4729C8.21101 10.7332 7.78907 10.7332 7.52883 10.4729L3.52856 6.47477C3.26831 6.21453 3.26831 5.78874 3.52856 5.52849Z" fill="#834EFF" />
                        </svg>
                    ) : (
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M12.4716 10.4749C12.2113 10.7352 11.7922 10.7352 11.532 10.4749L8.00008 6.9441L4.46821 10.4749C4.20797 10.7352 3.78884 10.7352 3.5286 10.4749C3.26835 10.2147 3.26835 9.78889 3.5286 9.52865L7.52887 5.53047C7.78911 5.27023 8.21105 5.27023 8.47129 5.53047L12.4716 9.52865C12.7318 9.78889 12.7318 10.2147 12.4716 10.4749Z" fill="#834EFF" />
                        </svg>
                    )}
                </button>
            )}
        </div>
    )
}

export default OrderSummary
