import { createAsyncSaga, name, endpoints } from '../dependencies'

const confirmOneTimeCode = createAsyncSaga(
    `${name}/confirmOneTimeCode`,
    function* ({
        payload: {
            code = null,
            email = null,
            phone = null,
            publisherId = null
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
             
            if (!publisherId) {
                throw Error('publisher id must be provided')
            }

            if(!code) {
                throw Error('code must be provided')
            }

            if (!(!email || !phone)) {
                throw new Error('email or phone_number not provided')
            }

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: `${endpoints.AUTH_USER_CODE_CONFIRM()}`,
                headers: {
                    publisherid: publisherId
                },
                data: {
                    code,
                    ...(email ? { email } : {}),
                    ...(phone ? { phone } : {}),
                },
            })

            yield fulfilled(response)
        } catch (error) {
            yield rejected({
                error: error?.response?.data?.message || 'Could not confirm code.'
            })
        }
    }
)

export default confirmOneTimeCode
