import pay_schemas from './pay'

const pay = {
    ...pay_schemas
}

const eventSchemas = {
    pay,
}

export default eventSchemas
