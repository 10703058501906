const IconCrossmarkEncircled = ({scale = '0.8'}) => (
    <svg style={{
        transform: `scale(${scale})`,
    }} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="icon_crossmark_encircled">
            <path id="Shape" fillRule="evenodd" clipRule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM16.7075 15.2953L13.4123 12L16.7075 8.70474C17.0975 8.31476 17.0975 7.68247 16.7075 7.29249C16.3175 6.9025 15.6852 6.9025 15.2953 7.29249L12 10.5877L8.70474 7.29249C8.31476 6.9025 7.68247 6.9025 7.29249 7.29249C6.9025 7.68247 6.9025 8.31476 7.29249 8.70474L10.5877 12L7.29249 15.2953C6.9025 15.6852 6.9025 16.3175 7.29249 16.7075C7.68247 17.0975 8.31476 17.0975 8.70474 16.7075L12 13.4123L15.2953 16.7075C15.6852 17.0975 16.3175 17.0975 16.7075 16.7075C17.0975 16.3175 17.0975 15.6852 16.7075 15.2953Z" fill="#834EFF"/>
        </g>
    </svg>
)

export default IconCrossmarkEncircled
