import { createAsyncSaga, name, endpoints } from '../dependencies'

const checkHasPassword = createAsyncSaga(
    `${name}/checkHasPassword`,
    function* ({
        payload: {
            email = null,
            phone_number = null,
            publisherId = null
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
             
            if (!publisherId) {
                throw Error('publisher id must be provided')
            }

            if (!(!email || !phone_number)) {
                throw new Error('email or phone_number not provided')
            }

            const response = yield request({
                method: 'get',
                baseURL: endpoints.PICO_API_URL(),
                url: `${endpoints.OAUTH_USER()}`,
                headers: {
                    publisherid: publisherId
                },
                params: {
                    ...(email ? { email } : {}),
                    ...(phone_number ? { phone_number } : {}),
                }
            })

            yield fulfilled(response)
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default checkHasPassword
