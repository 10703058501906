import { createAsyncSaga, name, endpoints } from '../dependencies'

const fetchPaymentMethods = createAsyncSaga(
    `${name}/fetchPaymentMethods`,
    function* ({
        payload: {
            publisherId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId not provided')
            }

            const response = yield request({
                method: 'get',
                baseURL: endpoints.PICO_API_URL(),
                url: `/me/payment`,
                headers: {
                    publisherid: publisherId,
                }
            })

            yield fulfilled(response)
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default fetchPaymentMethods
