import {
    initialState,
    reducer,
    takes,
    purchaseProduct,
    // purchasedProduct,
    checkoutDefaultSucceeded,
    checkoutGiftSucceeded,
    checkoutSwitchSucceeded,
    checkoutDefault,
    selectedSwitchNow,
    setSelectedSwitchNow,
    purchasedGift,
    switchedProduct,
} from './slice'

const purchasedProduct = 'tests'

import useCart from './useCart'

const Cart = {
    initialState,
    reducer,
    takes,
}

export default Cart

export {
    useCart,
    checkoutDefault,
    checkoutDefaultSucceeded,
    checkoutGiftSucceeded,
    checkoutSwitchSucceeded,
    purchaseProduct,
    purchasedProduct,
    selectedSwitchNow,
    setSelectedSwitchNow,
    purchasedGift,
    switchedProduct,
}
