import { fetchCreator } from '@/entities/Creator'
import { setQueryParams } from '@/entities/QueryParams'

const MARKETING_SITE_URL = process.env.MARKETING_SITE_URL
const HYPE_PAGE_PREFIX = process.env.HYPE_PAGE_PREFIX || ''
const name = 'gift'

export {
    name,
    MARKETING_SITE_URL,
    HYPE_PAGE_PREFIX,
    fetchCreator,
    setQueryParams,
}
