import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    oneTimeCodeError,
    oneTimeCodeInProgress,
    oneTimeCodeSent,
    requestOneTimeCode,
    oneTimeCodeConfirmed,
    verifyFlowId,
    confirmOneTimeCode,
    email,
    phoneNumber,
    exchangeClaim,
    claim,
    actions
} from './slice'

const useOneTimeCode = ({
    publisherId = null
}) => {
    const dispatch = useDispatch()
    const _oneTimeCodeError = useSelector(oneTimeCodeError)
    const _oneTimeCodeInProgress = useSelector(oneTimeCodeInProgress)
    const _oneTimeCodeSent = useSelector(oneTimeCodeSent)
    const _verifyFlowId = useSelector(verifyFlowId)
    const _oneTimeCodeConfirmed = useSelector(oneTimeCodeConfirmed)
    const _email = useSelector(email)
    const _phoneNumber = useSelector(phoneNumber)
    const _claim = useSelector(claim)

    const _requestOneTimeCode = useCallback(({ email = null, phone_number = null }: any) => {

        dispatch(requestOneTimeCode({
            email,
            phone_number,
            verifyFlowId: _verifyFlowId,
            publisherId
        }))
    }, [
        dispatch,
        publisherId,
        _oneTimeCodeError,
        _oneTimeCodeInProgress,
        _oneTimeCodeSent,
        _verifyFlowId
    ])

    const _confirmOneTimeCode = useCallback(({ code = null }: any) => {

        dispatch(confirmOneTimeCode({
            code,
            email: _email,
            phone: _phoneNumber,
            publisherId
        }))
    }, [
        dispatch,
        publisherId,
        _email,
        _phoneNumber,
        _oneTimeCodeSent,
    ])

    useEffect(() => {

        if (_claim) {
            dispatch(exchangeClaim({
                claim: _claim
            }))
        }
    }, [_claim])

    return {
        oneTimeCodeError: _oneTimeCodeError,
        oneTimeCodeSent: _oneTimeCodeSent,
        oneTimeCodeInProgress: _oneTimeCodeInProgress,
        oneTimeCodeConfirmed: _oneTimeCodeConfirmed,
        email: _email,
        phoneNumber: _phoneNumber,
        
        resetOTP: actions.resetOTP,
        requestOneTimeCode: _requestOneTimeCode,
        confirmOneTimeCode: _confirmOneTimeCode,
    }
}

export default useOneTimeCode
