import { v4 as uuid } from 'uuid';
import {
    createDraftSafeSelector,
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit'
import { name, events } from '../dependencies'
import { takeLatest } from 'redux-saga/effects'
import { delay } from 'redux-saga/effects'
import { put } from 'redux-saga/effects'
import { call } from 'redux-saga/effects'
import { select } from 'redux-saga/effects'
import { user, getMe } from '@/entities/User'
import { take } from 'redux-saga/effects'
import { requestFeatureFlags } from '@/entities/FeatureFlags'
import { setQueryParams, productOptionId as productOptionIdQP } from '@/entities/QueryParams'
import {
    STORE_TOKENS_SUCCESS,
} from '@piconetworks/pkg-network-middleware'
import { checkoutDefault } from '@/entities/Cart'

const eventsAdapter = createEntityAdapter({
    selectId: (event: any) => event?.id,
    sortComparer: (a: any, b: any) => parseInt(a.order) - parseInt(b.order),
})

const initialState = eventsAdapter.getInitialState({
    appStartFired: false,
})

const eventsSlice = createSlice({
    name,
    initialState,
    reducers: {
        _appStart: (state: any, { payload }: any) => {},
        appStart: (state: any, { payload }: any) => {
            const id = uuid()
            eventsAdapter.addOne(state, {
                id,
                type: events.appStart,
            })
        },
        _appExit: (state: any, { payload }: any) => {},
        _productOptionChange: (state: any, { payload }: any) => {},
        productOptionChange: (state: any, { payload }: any) => {
            const id = uuid()
            eventsAdapter.addOne(state, {
                id,
                type: events.productOptionChange,
            })
        },
        _productChange: (state: any, { payload }: any) => {},
        _userAuthenicated: (state: any, { payload }: any) => {},
        userAuthenicated: (state: any, { payload }: any) => {
            const id = uuid()
            eventsAdapter.addOne(state, {
                id,
                type: events.userAuthenicated,
            })
        },
        _userUnauthenicated: (state: any, { payload }: any) => {},
        _newPurchaseSuccess: (state: any, { payload }: any) => {},
        newPurchaseSuccess: (state: any, { payload }: any) => {
            const id = uuid()
            eventsAdapter.addOne(state, {
                id,
                type: events.newPurchaseSuccess,
            })
        },
        _switchProductOptionSuccess: (state: any, { payload }: any) => {},
        _purchaseGiftSuccess: (state: any, { payload }: any) => {},
        _successMessageDisplayed: (state: any, { payload }: any) => {},
        _paymentMethodAdded: (state: any, { payload }: any) => {},
    },
})

const {
    _appStart,
    _appExit,
    _productOptionChange,
    _productChange,
    _userAuthenicated,
    _userUnauthenicated,
    _newPurchaseSuccess,
    _switchProductOptionSuccess,
    _purchaseGiftSuccess,
    _successMessageDisplayed,
    _paymentMethodAdded,
} = eventsSlice.actions

// SELECTORS
const getEvents = (state: any) => state[name]
const appStartFired = createDraftSafeSelector(
    getEvents,
    (_events: any) => _events?.appStartFired,
)

// GENERAL
const EventsInitialState = {
    [name]: initialState,
}

const EventsReducer = {
    [name]: eventsSlice.reducer,
}

const actions = eventsSlice.actions

const takes = [
    takeLatest(requestFeatureFlags.fulfilled, function* (action: any): any {
        yield delay(1000)

        yield put(
            yield call(actions.appStart)
        )
    }),
    takeLatest(setQueryParams, function* (action: any): any {
        yield delay(1000)

        const { payload: { productOptionId } } = action

        if (!productOptionId) {
            return
        }

        yield put(
            yield call(actions.productOptionChange)
        )
    }),
    takeLatest(STORE_TOKENS_SUCCESS, function* (action: any): any {
        yield delay(1000)

        yield put(
            yield call(actions.userAuthenicated)
        )
    }),
    takeLatest(checkoutDefault.fulfilled, function* (action: any): any {
        yield delay(1000)

        yield put(
            yield call(actions.newPurchaseSuccess)
        )
    }),
    // takeLatest(setQueryParams, function* ({ payload: { productOptionId } }: any) {
    //     console.log('event123 productOptionId', productOptionId)
    //     yield put(
    //         yield call(actions.productOptionChange)
    //     )

    // }),
    // takeLatest(actions._appStart, function* (action: any): any {
    //     yield delay(500)

    //     yield put(
    //         yield call(actions.appStart)
    //     )
    // }),
]

// EXPORTS
export default eventsSlice.reducer

export {
    EventsInitialState as initialState,
    EventsReducer as reducer,
    takes,

    actions,
    _appStart,
    _appExit,
    _productOptionChange,
    _productChange,
    _userAuthenicated,
    _userUnauthenicated,
    _newPurchaseSuccess,
    _switchProductOptionSuccess,
    _purchaseGiftSuccess,
    _successMessageDisplayed,
    _paymentMethodAdded,

    appStartFired,
}
