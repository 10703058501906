import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { customPrice, productOptionId } from './dependencies'

import {
    selectAll,
    selectedProductOptionId,
    setProductOption,
} from './slice'

const useProductOptions = (setDefaultProductOptionId = false) => {
    const _selectedProductOptionId = useSelector(selectedProductOptionId)
    const productOptions = useSelector(selectAll)
    const dispatch = useDispatch()
    const _productOptionId = useSelector(productOptionId)
    const _customPrice = useSelector(customPrice)

    useEffect(() => {
        if (!setDefaultProductOptionId) {
            return
        }

        if (_selectedProductOptionId === _productOptionId) {
            return
        }

        dispatch(setProductOption({
            selectedProductOptionId: _productOptionId,
            isProductOptionsFormValid: true,
            selectedCustomPrice: _customPrice,
        }))
    }, [dispatch, _productOptionId])


    return productOptions
}

export default useProductOptions
