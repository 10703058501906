import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'

import { name, deauthorize } from '../dependencies'

import getMe from './getMe'

const initialState = {
    user: null,
    getUserInProgress: false,
    getUserError: null,
}

const userSlice = createSlice({
    name,
    initialState,
    reducers: {
    },
    extraReducers: {
        [`${name}/getMe`]: (state: any, { payload }: any) => {
            state.user = null
            state.getUserInProgress = true
            state.getUserError = null
        },
        [getMe.pending]: (state: any, { payload }: any) => {
            state.user = null
            state.getUserInProgress = true
            state.getUserError = null
        },
        [getMe.fulfilled]: (state: any, { payload: user }: any) => {
            state.user = user
            state.getUserInProgress = false
            state.getUserError = null
        },
        [getMe.rejected]: (state: any, { payload: error }: any) => {
            state.user = null
            state.getUserInProgress = false
            state.getUserError = error
        },
        [deauthorize]: (state: any, { payload }: any) => {
            state.user = initialState.user
            state.getUserInProgress = initialState.getUserInProgress
            state.getUserError = initialState.getUserError
        },
    },
})

// SELECTORS
const getUser = (state: any) => state[name]

const user = createDraftSafeSelector(
    getUser,
    (User: any) => User?.user,
)

const getUserInProgress = createDraftSafeSelector(
    getUser,
    (User: any) => User?.getUserInProgress,
)
const getUserError = createDraftSafeSelector(
    getUser,
    (User: any) => User?.getUserInProgress,
)

// GENERAL
const takes = [
    ...getMe.takes,
]

const UserInitialState = {
    [name]: initialState,
}

const UserReducer = {
    [name]: userSlice.reducer,
}

// EXPORTS
export default userSlice.reducer

export {
    takes,
    UserInitialState as initialState,
    UserReducer as reducer,

    getMe,

    user,
    getUserInProgress,
    getUserError,
}
