import logger from '@/lib/logger'
import type { AppProps } from 'next/app'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import SkeletonWrapper from '@/components/Skeleton'
import Checkout from '@/containers/Checkout'
import { persistor, store } from '@/utils/store'
import { useEffect } from 'react'
import datadogRumInit from '@/lib/rum'

import '@/styles/global.css'

const CheckoutApp = ({ Component, pageProps }: AppProps) => {
    useEffect(() => {
        logger.info('Initializing Datadog RUM')
        datadogRumInit()
    })

    return (
        <SkeletonWrapper showContents={true}>
            <Provider store={store}>
                <PersistGate loading={(<Checkout />)} persistor={persistor}>
                    <Component {...pageProps} />
                </PersistGate>
            </Provider>
        </SkeletonWrapper>
    )
}

export default CheckoutApp
