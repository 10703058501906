import { useEffect } from "react"
import { useSelector, useDispatch } from 'react-redux'
import { creator } from "./dependencies"
import { featureFlags, requestFeatureFlags, isFetched } from "./slice"

const useFeatureFlags = (fetch = false) => {
    const dispatch = useDispatch()
    const _featureFlags = useSelector(featureFlags)
    const _isFetched = useSelector(isFetched)
    const _creator = useSelector(creator)
    const publisherId = _creator?.id

    useEffect(() => {
        if (!fetch) {
            return
        }

        if (_isFetched) {
            return
        }

        if (publisherId) {
            dispatch(requestFeatureFlags({
                publisherId
            }))
        }
    }, [
        [publisherId, fetch, _isFetched]
    ])


    return {
        featureFlags: _featureFlags,
        isFetched: _isFetched,
    }
}

export default useFeatureFlags
