import { select } from 'redux-saga/effects'
import { subscribedNewsletters } from './index'
import { createAsyncSaga, name, endpoints } from '../dependencies'
import logger from '@/lib/logger'
import { addError } from '@/lib/rum'


const fetchNewsletters = createAsyncSaga(
    `${name}/fetchNewsletters`,
    function* ({
        payload: {
            productId = null,
            publisherId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            const _subscribedNewsletters = yield select(subscribedNewsletters)
            if (!productId) {
                throw new Error('productId not provided')
            }

            if (!publisherId) {
                throw new Error('publisherId not provided')
            }

            const { data: { newsletter: freeNewsletters = [] } = {} } = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                headers: {
                    publisherid: publisherId
                },
                url: `/query`,
                data: {
                    query: `{
                        newsletter(client_id:"${publisherId}", type:"free")
                        {
                            id,
                            description,
                            enabled,
                            name,
                            integration_label,
                            newsletter_order,
                            type
                        }
                    }`
                }
            })

            const { data: { newsletter: productNewsletters = [] } = {} } = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                headers: {
                    publisherid: publisherId
                },
                url: `/query`,
                data: {
                    query: `{
                        newsletter(product_id:"${productId}")
                        {
                            id,
                            description,
                            enabled,
                            name,
                            integration_label,
                            newsletter_order,
                            type
                        }
                    }`
                }
            })
            const paidProductNewsletters = productNewsletters?.filter((newsletter) => newsletter?.type === 'paid')
            const preSelectedFreeNewsletters = productNewsletters?.filter((newsletter) => newsletter?.type === 'free')

            const allNewsletters = [
                ...(paidProductNewsletters || []),
                ...(freeNewsletters || []),
            ].map((newsletter = {}) => ({
                ...newsletter,
                subscribed: _subscribedNewsletters?.[newsletter?.id] || !!preSelectedFreeNewsletters?.find((preSelected) => preSelected.id === newsletter.id)
            }))

            yield fulfilled(allNewsletters)
        } catch (error) {
            logger.log(`${name}/fetchNewsletters`, {payload}, error)
            addError(error, {payload})
            yield rejected({
                error: error?.message,
            })
        }
    }
)



export default fetchNewsletters
