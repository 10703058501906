import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'
import {
    STORE_TOKENS_SUCCESS,
    STORE_TOKENS_ERROR,
    REMOVE_TOKENS_SUCCESS,
    REMOVE_TOKENS_ERROR,
    REFRESH_TOKEN,
    REFRESH_TOKEN_SUCCESS,
    REFRESH_TOKEN_ERROR,
} from '@piconetworks/pkg-network-middleware'

import { name } from '../dependencies'

import register from './register'
import exchangeClaim from './exchangeClaim'
import checkHasPassword from './checkHasPassword'
import authWithEmailPassword from './authWithEmailPassword'
import authWithSocial from './authWithSocial'
import authWithPhonePassword from './authWithPhonePassword'
import requestOneTimeCode from './requestOneTimeCode'
import confirmOneTimeCode from './confirmOneTimeCode'
import generateClaim from './generateClaim'
import initializeAuthFlow from './initializeAuthFlow'

const initialState = {
    isAuthorized: false,
    info: null,
    hasPassword: null,
    checkHasPasswordInProgress: false,
    checkHasPasswordError: null,
    oneTimeCodeInProgress: false,
    oneTimeCodeSent: false,
    oneTimeCodeError: null,
    oneTimeCodeConfirmed: null,
    verifyFlowId: null,
    claim: null,
    exchangeClaimInProgress: false,
    exchangeClaimError: null,
    useRefreshTokenInProgress: false,
    useRefreshTokenError: null,
    outboundClaim: null,
    generateClaimError: null
}

const authorizationSlice = createSlice({
    name,
    initialState,
    reducers: {
        resetOTP: (draftState: any) => {
            draftState.info = null
            draftState.oneTimeCodeInProgress = false
            draftState.oneTimeCodeSent = false
            draftState.oneTimeCodeError = null
            draftState.oneTimeCodeConfirmed = null
            draftState.verifyFlowId = null
        },
        deauthorize: (draftState: any) => {
            draftState.isAuthorized = initialState.isAuthorized
            draftState.info = initialState.info
            draftState.hasPassword = initialState.hasPassword
            draftState.checkHasPasswordInProgress = initialState.checkHasPasswordInProgress
            draftState.checkHasPasswordError = initialState.checkHasPasswordError
            draftState.oneTimeCodeInProgress = initialState.oneTimeCodeInProgress
            draftState.oneTimeCodeSent = initialState.oneTimeCodeSent
            draftState.oneTimeCodeError = initialState.oneTimeCodeError
            draftState.oneTimeCodeConfirmed = initialState.oneTimeCodeConfirmed
            draftState.verifyFlowId = initialState.verifyFlowId
            draftState.claim = initialState.claim
            draftState.exchangeClaimInProgress = initialState.exchangeClaimInProgress
            draftState.exchangeClaimError = initialState.exchangeClaimError
            draftState.useRefreshTokenInProgress = initialState.useRefreshTokenInProgress
            draftState.useRefreshTokenError = initialState.useRefreshTokenError
            draftState.outboundClaim = initialState.outboundClaim
            draftState.generateClaimError = initialState.generateClaimError
        },
    },
    extraReducers: {
        [REFRESH_TOKEN]: (state: any) => {
            state.useRefreshTokenInProgress = true
            state.useRefreshTokenError = null
        },
        [REFRESH_TOKEN_SUCCESS]: (state: any) => {
            state.useRefreshTokenInProgress = false
        },
        [REFRESH_TOKEN_ERROR]: (state: any, { payload: error }: any) => {
            state.useRefreshTokenInProgress = false
            state.useRefreshTokenError = error
        },
        [STORE_TOKENS_SUCCESS]: (state: any) => {
            state.isAuthorized = true
        },
        [STORE_TOKENS_ERROR]: (state: any) => {
            state.isAuthorized = false
        },
        [REMOVE_TOKENS_SUCCESS]: (state: any) => {
            state.isAuthorized = false
        },
        [REMOVE_TOKENS_ERROR]: (state: any) => {
            state.isAuthorized = false
        },
        [exchangeClaim.pending]: (state: any, { payload: claim }: any) => {
            state.claim = claim
            state.exchangeClaimInProgress = true
            state.exchangeClaimError = null
        },
        [exchangeClaim.fulfilled]: (state: any, { payload: token }: any) => {
            state.claim = null
            state.exchangeClaimInProgress = false
            state.exchangeClaimError = null
        },
        [exchangeClaim.rejected]: (state: any, { payload: error }: any) => {
            state.claim = null
            state.exchangeClaimInProgress = false
            state.exchangeClaimError = error
        },
        [checkHasPassword.pending]: (state: any) => {
            state.hasPassword = null
            state.info = null
            state.checkHasPasswordInProgress = true
            state.checkHasPasswordError = null
        },
        [checkHasPassword.fulfilled]: (state: any, { payload: { has_password = null, ...info } }: any) => {
            state.hasPassword = has_password
            state.info = info
            state.checkHasPasswordInProgress = false
            state.checkHasPasswordError = null
        },
        [checkHasPassword.rejected]: (state: any, { payload: error }: any) => {
            state.hasPassword = null
            state.info = null
            state.checkHasPasswordInProgress = false
            state.checkHasPasswordError = error
        },
        [requestOneTimeCode.pending]: (state: any) => {
            state.oneTimeCodeInProgress = true
            state.oneTimeCodeError = null
            state.oneTimeCodeConfirmed = null
        },
        [requestOneTimeCode.fulfilled]: (state: any, { payload }: any) => {
            state.info = payload
            state.verifyFlowId = payload?.verifyFlowId
            state.oneTimeCodeSent = true
            state.oneTimeCodeInProgress = false
            state.oneTimeCodeError = null
            state.oneTimeCodeConfirmed = null
        },
        [requestOneTimeCode.rejected]: (state: any, { payload: error }: any) => {
            state.verifyFlowId = null
            state.oneTimeCodeSent = null
            state.oneTimeCodeInProgress = false
            state.oneTimeCodeError = error
            state.oneTimeCodeConfirmed = null
        },
        [generateClaim.pending]: (state: any) => {
            state.generateClaimError = null
            state.outboundClaim = null
        },
        [generateClaim.fulfilled]: (state: any, { payload }: any) => {
            state.outboundClaim = payload?.claim
        },
        [generateClaim.rejected]: (state: any, { payload: error }: any) => {
            state.generateClaimError = error
        },
        [confirmOneTimeCode.pending]: (state: any) => {
            state.oneTimeCodeConfirmed = null
        },
        [confirmOneTimeCode.fulfilled]: (state: any, { payload }: any) => {
            state.verifyFlowId = payload?.verifyFlowId
            state.claim = payload?.claim
            state.oneTimeCodeSent = false
            state.oneTimeCodeInProgress = false
            state.oneTimeCodeError = null
            state.oneTimeCodeConfirmed = true
        },
        [confirmOneTimeCode.rejected]: (state: any, { payload: error }: any) => {
            state.oneTimeCodeInProgress = false
            state.oneTimeCodeError = error
            state.oneTimeCodeConfirmed = false
        },
        [authWithEmailPassword.fulfilled]: (state: any) => {
            state.hasPassword = null
            state.info = null
        },
        [authWithPhonePassword.fulfilled]: (state: any) => {
            state.hasPassword = null
            state.info = null
        },
        [authWithSocial.fulfilled]: (state: any) => {
            state.hasPassword = null
            state.info = null
        },
        [register.fulfilled]: (state: any) => {
            state.hasPassword = null
            state.info = null
        },
    },
})

const { deauthorize } = authorizationSlice.actions

// SELECTORS
const getAuthorization = (state: any) => state[name]
const claim = createDraftSafeSelector(
    getAuthorization,
    (Authorization: any) => Authorization?.claim,
)
const email = createDraftSafeSelector(
    getAuthorization,
    (Authorization: any) => Authorization?.info?.email,
)
const phoneNumber = createDraftSafeSelector(
    getAuthorization,
    (Authorization: any) => Authorization?.info?.phone,
)
const hasPassword = createDraftSafeSelector(
    getAuthorization,
    (Authorization: any) => Authorization?.hasPassword,
)
const oneTimeCodeSent = createDraftSafeSelector(
    getAuthorization,
    (Authorization: any) => Authorization?.oneTimeCodeSent,
)
const oneTimeCodeError = createDraftSafeSelector(
    getAuthorization,
    (Authorization: any) => Authorization?.oneTimeCodeError,
)
const oneTimeCodeConfirmed = createDraftSafeSelector(
    getAuthorization,
    (Authorization: any) => Authorization?.oneTimeCodeConfirmed,
)
const oneTimeCodeInProgress = createDraftSafeSelector(
    getAuthorization,
    (Authorization: any) => Authorization?.oneTimeCodeInProgress,
)
const verifyFlowId = createDraftSafeSelector(
    getAuthorization,
    (Authorization: any) => Authorization?.verifyFlowId,
)
const isNewRegistration = createDraftSafeSelector(
    getAuthorization,
    (Authorization: any) => Authorization?.info?.is_new_registration,
)
const isAuthorized = createDraftSafeSelector(
    getAuthorization,
    (Authorization: any) => Authorization?.isAuthorized,
)
const exchangeClaimInProgress = createDraftSafeSelector(
    getAuthorization,
    (Authorization: any) => Authorization?.exchangeClaimInProgress,
)
const exchangeClaimError = createDraftSafeSelector(
    getAuthorization,
    (Authorization: any) => Authorization?.exchangeClaimError,
)
const useRefreshTokenInProgress = createDraftSafeSelector(
    getAuthorization,
    (Authorization: any) => Authorization?.useRefreshTokenInProgress,
)
const useRefreshTokenError = createDraftSafeSelector(
    getAuthorization,
    (Authorization: any) => Authorization?.useRefreshTokenError,
)
const checkHasPasswordInProgress = createDraftSafeSelector(
    getAuthorization,
    (Authorization: any) => Authorization?.checkHasPasswordInProgress,
)
const checkHasPasswordError = createDraftSafeSelector(
    getAuthorization,
    (Authorization: any) => Authorization?.checkHasPasswordError,
)

const generateClaimError = createDraftSafeSelector(
    getAuthorization,
    (Authorization: any) => Authorization?.generateClaimError,
)

const outboundClaim = createDraftSafeSelector(
    getAuthorization,
    (Authorization: any) => Authorization?.outboundClaim,
)

// GENERAL
const takes = [
    ...exchangeClaim.takes,
    ...checkHasPassword.takes,
    ...authWithEmailPassword.takes,
    ...authWithSocial.takes,
    ...authWithPhonePassword.takes,
    ...register.takes,
    ...requestOneTimeCode.takes,
    ...confirmOneTimeCode.takes,
    ...generateClaim.takes,
    ...initializeAuthFlow.takes
]

const AuthorizationInitialState = {
    [name]: initialState,
}

const AuthorizationReducer = {
    [name]: authorizationSlice.reducer,
}

const AuthorizationActions = {
    ...authorizationSlice.actions
}



// EXPORTS
export default authorizationSlice.reducer

export {
    takes,
    AuthorizationInitialState as initialState,
    AuthorizationReducer as reducer,
    AuthorizationActions as actions,

    deauthorize,

    register,
    exchangeClaim,
    checkHasPassword,

    requestOneTimeCode,
    confirmOneTimeCode,
    initializeAuthFlow,

    authWithEmailPassword,
    authWithPhonePassword,
    authWithSocial,

    isAuthorized,
    hasPassword,
    isNewRegistration,
    claim,
    email,
    phoneNumber,

    checkHasPasswordInProgress,
    checkHasPasswordError,
    exchangeClaimInProgress,
    exchangeClaimError,
    useRefreshTokenError,
    useRefreshTokenInProgress,

    oneTimeCodeSent,
    oneTimeCodeError,
    oneTimeCodeInProgress,
    oneTimeCodeConfirmed,
    verifyFlowId,

    generateClaim,
    outboundClaim,
    generateClaimError
}
