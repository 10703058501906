import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    fetchNewsletters,
    selectAll,
    freeNewsletters,
    paidNewsletters,
    subscribedNewsletters,
    fetchUserNewsletters
} from './slice'

import { productId, useCreator, isAuthorized } from './dependencies'

const useNewsletters = (fetch = false) => {
    const creator = useCreator()
    const publisherId = creator?.id
    const dispatch = useDispatch()

    const _productId = useSelector(productId)
    const _selectAll = useSelector(selectAll)
    const _freeNewsletters = useSelector(freeNewsletters)
    const _paidNewsletters = useSelector(paidNewsletters)
    const _subscribedNewsletters = useSelector(subscribedNewsletters)
    const _isAuthorized = useSelector(isAuthorized)

    useEffect(() => {
        if (!_productId) {
            return
        }

        if (!publisherId) {
            return
        }

        if (!fetch) {
            return
        }

        dispatch(fetchNewsletters({
            productId: _productId,
            publisherId: publisherId,
        }))
    }, [_productId, publisherId])


    useEffect(() => {
        if (!_productId) {
            return
        }

        if (!publisherId) {
            return
        }

        if (!fetch) {
            return
        }

        if(!_isAuthorized){
            return
        }

        dispatch(fetchUserNewsletters({
            publisherId: publisherId,
        }))
        
    }, [_productId, publisherId, _isAuthorized])

    return {
        freeNewsletters: _freeNewsletters,
        paidNewsletters: _paidNewsletters,
        all: _selectAll,
        subscribedNewsletters: _subscribedNewsletters,
    }
}

export default useNewsletters
