import { createAsyncSaga, name, endpoints } from '../dependencies'

const requestOneTimeCode = createAsyncSaga(
    `${name}/requestOneTimeCode`,
    function* ({
        payload: {
            email = null,
            phone_number = null,
            publisherId = null,
            verifyFlowId
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {

            if (!publisherId) {
                throw Error('publisher id must be provided')
            }

            if (!(!email || !phone_number)) {
                throw new Error('email or phone_number not provided')
            }

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: `${endpoints.AUTH_USER_CODE_INIT()}`,
                headers: {
                    publisherid: publisherId
                },
                data: {
                    ...(email ? { email } : {}),
                    ...(phone_number ? { phone: phone_number } : {}),
                    ...(verifyFlowId ? { verify_flow_id: verifyFlowId } : {}),

                }
            })

            yield fulfilled({
                ...response, 
                ...(email ? { email } : {}),
                ...(phone_number ? { phone: phone_number } : {})
            })
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default requestOneTimeCode
