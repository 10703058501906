import { useEffect, useState } from "react"
import { useGift, usePaymentHistory } from "./dependencies"

const checkoutModes = {
    gift: 'GIFT',
    switch: 'SWITCH',
    default: null,
}

const useIsGift = () => {
    const [ isGift, setIsGift ]: any = useState(false)

    const gift = useGift()
    const giftModeEnabled = gift?.giftModeEnabled

    useEffect(() => {
        if (giftModeEnabled) {
            setIsGift(true)
        } else {
            setIsGift(false)
        }
    }, [giftModeEnabled])
    
    return isGift
}

const useIsSwitch = () => {
    const [ isSwitch, setIsSwitch ]: any = useState(false)

    const { currentProductOptionId } = usePaymentHistory()

    useEffect(() => {
        if (!!currentProductOptionId) {
            setIsSwitch(true)
        } else {
            setIsSwitch(false)
        }
    }, [currentProductOptionId])
    
    return isSwitch
}

const useCheckoutMode = () => {
    const [ checkoutMode, setCheckoutMode ]: any = useState(checkoutModes.default)

    const isSwitch = useIsSwitch()
    const isGift = useIsGift()

    useEffect(() => {
        let newCheckoutMode

        if (isSwitch) {
            newCheckoutMode = checkoutModes.switch
        }

        if (isGift) {
            newCheckoutMode = checkoutModes.gift
        }

        if (!isSwitch && !isGift) {
            newCheckoutMode = checkoutModes.default
        }

        if (checkoutMode !== newCheckoutMode) {
            setCheckoutMode(newCheckoutMode)
        }
    }, [
        isSwitch,
        isGift,
        setCheckoutMode,
        checkoutMode,
    ])

    return {
        checkoutMode,
        checkoutModes,
    }
}

export default useCheckoutMode
