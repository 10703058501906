import { select } from 'redux-saga/effects'
import { call, put, take } from 'redux-saga/effects'
import {
    current,
    createAsyncSaga,
    name,
    saveCustomFields,
    saveNewsletters,
    updatePaymentMethod,
} from '../dependencies'

import switchProduct from './switchProduct'

import { selectedSwitchNow } from './index'

const checkoutSwitch = createAsyncSaga(
    `${name}/checkoutSwitch`,
    function* ({
        payload: {
            productOptionId = null,
            publisherId = null,
            customPrice = null,
            sourceId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            // update default payment method
            yield put(yield call(updatePaymentMethod, {
                sourceId,
                publisherId,
            }))

            yield take(updatePaymentMethod.pending)
            const updateDefaultPaymentResult = yield take([updatePaymentMethod.fulfilled, updatePaymentMethod.rejected])

            if (updateDefaultPaymentResult.type === updatePaymentMethod.rejected) {
                throw new Error('update default payment method failed')
            }

            // check switchability
            const _current = yield select(current)
            if (!_current?.product_option_id) {
                throw new Error('current product_option_id is not found')
            }

            const payNow = yield select(selectedSwitchNow)

            // make switch
            yield put(yield call(switchProduct, {
                productOptionId,
                publisherId,
                customPrice,
                payNow,
            }))

            yield take(switchProduct.pending)
            const switchResult = yield take([switchProduct.fulfilled, switchProduct.rejected])

            if (switchResult.type === switchProduct.rejected) {
                throw new Error('switch failed')
            }

            // update custom fields
            yield put(yield call(saveCustomFields, {
                publisherId,
            }))

            yield take(saveCustomFields.pending)
            yield take([saveCustomFields.fulfilled, saveCustomFields.rejected])


            // update newsletters
            yield put(yield call(saveNewsletters, {
                publisherId,
            }))

            yield take(saveNewsletters.pending)
            yield take([saveNewsletters.fulfilled, saveNewsletters.rejected])

            // complete flow
            yield fulfilled()
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default checkoutSwitch
