import createAsyncSaga from '@/utils/createAsyncSaga'
import * as endpoints from '@piconetworks/pkg-endpoints'

import { isAuthorized, deauthorize } from '@/entities/Authorization'
import { useCreator, creator } from '@/entities/Creator'

const name = 'paymentMethods'

export {
    name,
    createAsyncSaga,
    endpoints,
    isAuthorized,
    useCreator,
    creator,
    deauthorize,
}
