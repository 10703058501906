import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js'

import { useCreator, usePaymentMethods, useUser } from './dependencies'

import { checkoutDefaultError, checkoutGiftError } from '@/entities/Cart/slice'

import AddPaymentMethod from './components/AddPaymentMethod'
import CardList from './components/CardList'
import StripeBrandMark from './components/StripeBrandMark'
import PayButton from './components/PayButton'
import ApplePay from './components/ApplePay';

// @ts-ignore
const PaymentCard = (_: any, ref: any) => {
    const creator = useCreator()
    const user = useUser()
    const { paymentMethods } = usePaymentMethods()

    const _checkoutGiftError = useSelector(checkoutGiftError)
    const _checkoutDefaultError = useSelector(checkoutDefaultError)

    const creatorDisplayName = creator?.name
    const creatorTerms = creator?.url_terms

    const hasGiftPurchaseError = _checkoutGiftError?.error && _checkoutGiftError.error
    const hasDefaultPurchaseError = _checkoutDefaultError?.error && _checkoutDefaultError.error

    const error = hasGiftPurchaseError || hasDefaultPurchaseError

    const hasPaymentMethod = paymentMethods.length > 0

    const [_stripePromise, setStripePromise] = useState<any>(null)

    useEffect(() => {
        async function createStripeInstance() {
            const loadStripe = (await import('@stripe/stripe-js')).loadStripe

            const stripePromise = loadStripe(process.env.STRIPE_API_KEY || '')

            setStripePromise(stripePromise)
        }

        if (_stripePromise) {
            return
        }

        createStripeInstance()
    }, [_stripePromise, setStripePromise])

    return (
        <Elements stripe={_stripePromise}>
            <div className='flex items-center justify-between mb-5'>
                <h1 className='heading-1'>Payment</h1>
                <StripeBrandMark />
            </div>

            {user && (
                <>
                    <ApplePay />
                    <div className={`${hasPaymentMethod ? 'card' : 'py-5'} relative overflow-hidden`}>
                        <CardList />
                        <AddPaymentMethod />
                    </div>
                </>
            )}
            {error && <p className='input-error-description p-2 border-red-500 border-1 rounded-lg bg-red-100 text-center my-2'>{error}</p>}
            <PayButton />
            <p className='text-gray-700 text-xs leading-[14px] text-center '>
                {`Your payment is subject to Hype's `}
                <a className='underline' rel='noreferrer' href='https://hype.co/terms/user-terms/' target='_blank'>terms</a>
                {creatorTerms && <> and the <a className='underline' rel='noreferrer' href={creatorTerms} target='_blank'>terms</a> of <span>{creatorDisplayName}</span></>}
                {`. Accounts are powered by Hype.`}
            </p>
        </Elements>
    )
}

export default PaymentCard
