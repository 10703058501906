import { createAsyncSaga, name, endpoints } from '../dependencies'

const requestFeatureFlags = createAsyncSaga(
    `${name}/requestFeatureFlags`,
    function* ({
        payload: {
            publisherId = null
        },
        fulfilled,
        rejected,
        request,
    }) {
        try {
             
            if (!publisherId) {
                throw Error('publisher id must be provided')
            }

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: `${endpoints.FEATURE_FLAGS()}`,
                headers: {
                    publisherid: publisherId
                },
            })

            yield fulfilled(response)
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default requestFeatureFlags
