import { Field } from 'react-final-form'


const Radio = ({ id, input, children }: any) => (
    <div className="flex items-center mr-4 mb-4">
        <input id={id} type="radio" className="hidden"  {...input} />
        <label htmlFor={id} className="flex items-center cursor-pointer">
            <span className="radio-check w-[16px] h-[16px] inline-block rounded-full"></span>
            <span className='ml-1 capitalize'>
                {children}
            </span>
        </label>
    </div>
);


const Boolean = ({ id, label, required, description, options }: any) => {
    return (
        <>
            <div className='mb-3'>
                <label className='label' htmlFor={id}>{label}{required && '*'}</label>
                <p className="text-xs select-none font-normal text-gray-600  ">{description}</p>
            </div>
            {options.map((option: any, index: any) => (
                <div key={option.value + index}>
                    <Field name={id} type="radio" id={option.value} value={option.value} component={Radio}>
                        {option.label}
                    </Field>
                </div>
            ))}


        </>
    )
}

export default Boolean 