import { useState, useEffect } from 'react'

declare global {
  interface Window {
    detectIncognito: any
  }
}

interface IncognitoResponse {
  isPrivate: boolean
  browserName: string
}

const Favicon = () => {
  const [useDark, setUseDark] = useState(false)
  const [isPrivate, setIsPrivate] = useState(false)
  const [isDark, setIsDark] = useState(false)

  const defaultStage = 'development'
  const stage =
    typeof process.env.STAGE === 'undefined' ? defaultStage : process.env.STAGE

  const faviconSettings: any = {
    production: {
      faviconColor: '#000000',
      faviconFolder: 'cloud/productionFavicon',
    },
    qa: {
      faviconColor: '#FCAF2F',
      faviconFolder: 'cloud/qaFavicon',
    },
    staging: {
      faviconColor: '#FCAF2F',
      faviconFolder: 'cloud/stagingFavicon',
    },
    development: {
      faviconColor: '#FF4440',
      faviconFolder: 'cloud/developmentFavicon',
    },
  }

  const favicon = faviconSettings[stage]
  const faviconFolder = favicon?.faviconFolder
  const faviconColor = favicon?.faviconColor

  useEffect(() => {
    if (typeof window === 'undefined') {
      return
    }

    window?.detectIncognito().then((res: IncognitoResponse) => {
      setIsPrivate(res?.isPrivate)
    })

    setIsDark(
      (window?.matchMedia('(prefers-color-scheme: dark)') as any)?.matches
    )
  }, [setIsPrivate, setIsDark])

  useEffect(() => {
    if ((isPrivate || isDark) && stage === 'production') {
      setUseDark(true)
    }
  }, [setUseDark, isPrivate, isDark, favicon, stage])

  if (!faviconFolder) {
    return null
  }

  return (
    <>
      {useDark && (
        <link
          rel="icon"
          href={`/${faviconFolder}/favicon-dark.ico`}
          type="image/png"
        />
      )}
      {!useDark && (
        <link
          rel="icon"
          href={`/${faviconFolder}/favicon.ico`}
          type="image/png"
        />
      )}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`/${faviconFolder}/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`/${faviconFolder}/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`/${faviconFolder}/favicon-16x16.png`}
      />
      {faviconColor && (
        <link
          rel="mask-icon"
          href={`/${faviconFolder}/safari-pinned-tab.svg`}
          color={`${faviconColor}`}
        ></link>
      )}
    </>
  )
}

export default Favicon
