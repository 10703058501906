import { useGift } from '@/entities/Gift'
import { useEffect } from 'react'
import { useFormState, Field } from 'react-final-form'
import { useDispatch } from 'react-redux'

const { required, composeValidators, email } = require('@/utils/rffValidations')

const GiftForm = () => {
    const dispatch = useDispatch()

    const formState = useFormState()
    const isGiftFormValid = formState.valid
    const formValues = formState.values

    const { setGiftFields } = useGift()

    useEffect(() => {
        setGiftFields({
            ...formValues,
            isGiftFormValid,
        })
    }, [
        dispatch,
        isGiftFormValid,
        formValues,
        setGiftFields,
    ])

    return (
        <div>
            <h1 className='heading-1 mb-3'>Gift info</h1>
            <p className="text-sm select-none font-normal text-gray-800 mb-6">
                We will send this gift to the recipient on your behalf. The subscription will not begin until the recipient chooses to redeem the gift. The gift will not expire.
            </p>

            <div className='my-3'>
                <div className='mb-3'>
                    <label className='label' htmlFor='senderFirstName'>Your name *</label>
                </div>
                <Field
                    name='senderFirstName'
                    validate={required}
                >
                    {({ input, meta }) => (
                        <>
                            <input {...input} type="text" className={`input ${meta.error && meta.touched && 'input-error'}`} placeholder='Enter your name' />
                            {meta.error && meta.touched && <span className='input-error-description'>{meta.error}</span>}
                        </>
                    )}
                </Field>
            </div>
            <div className='my-3'>
                <div className='mb-3'>
                    <label className='label' htmlFor='recipientFirstName'>Recipient's name *</label>
                </div>
                <Field
                    name='recipientFirstName'
                    validate={required}
                >
                    {({ input, meta }) => (
                        <>
                            <input {...input} type="text" className={`input ${meta.error && meta.touched && 'input-error'}`} placeholder='Enter recipient name' />
                            {meta.error && meta.touched && <span className='input-error-description'>{meta.error}</span>}
                        </>
                    )}
                </Field>
            </div>
            <div className='my-3'>
                <div className='mb-3'>
                    <label className='label' htmlFor='recipientEmail'>Recipient's email *</label>
                </div>
                <Field
                    name='recipientEmail'
                    validate={composeValidators(required)}
                >
                    {({ input, meta }) => (
                        <>
                            <input {...input} type="text" className={`input ${meta.error && meta.touched && 'input-error'}`} placeholder='Enter recipient email' />
                            {meta.error && meta.touched && <span className='input-error-description'>{meta.error}</span>}
                        </>
                    )}
                </Field>
            </div>
            <div className='my-3'>
                <div className='mb-3'>
                    <label className='label' htmlFor='note'>Gift note</label>
                </div>
                <Field
                    className='input'
                    component='textarea'
                    name='giftNote'
                    placeholder='Enter gift note'

                />
            </div>
        </div>
    )
}

export default GiftForm