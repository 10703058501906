import createAsyncSaga from '@/utils/createAsyncSaga'
import * as endpoints from '@piconetworks/pkg-endpoints'
import { fetchProduct } from '@/entities/Product'
import { productOptionId, customPrice } from '@/entities/QueryParams'
import { addDays, addMonths, addWeeks, addYears, intervals } from '@/utils/dateUtils'
const name = 'productOptions'

export {
    name,
    fetchProduct,
    productOptionId,
    createAsyncSaga,
    endpoints,

    intervals,
    addDays,
    addMonths,
    addWeeks,
    addYears,
    customPrice
}
