import { useCallback, useEffect, useRef, useState } from 'react'
const TURNSTILE_INVISIBLE_SITE_KEY = process.env.TURNSTILE_INVISIBLE_SITE_KEY

declare global {
    interface Window {
        turnstile: any;
    }
}

const id = 'bt_ts_8768'

const BotTester = ({ onValidated = (_token: any) => {} }) => {
    const [token, setToken] = useState(null)

    const _callback = useCallback((token = null) => {
        setToken(token)
        onValidated(token)
    }, [setToken])

    useEffect(() => {
        if (!token) {
            return
        }

        // TODO: add token so that it's used in all subsequent ajax requests
        // console.log('token', token)
    }, [token])

    const turnstileRef = useRef(null)
    const isTurnstileLoaded = (typeof window !== 'undefined' && window.turnstile) 

    useEffect(() => {
        if (!isTurnstileLoaded) {
            return
        }

        try {
            window.turnstile.render(turnstileRef.current, {
                sitekey: TURNSTILE_INVISIBLE_SITE_KEY,
                callback: _callback
            })
        } catch (error) {
            
        }

        return () => {
            try {
                window.turnstile.remove()
            } catch (error) {
                
            }
        }
    }, [_callback, turnstileRef, isTurnstileLoaded])


    return (
        <div ref={turnstileRef} id={id}></div>
    )
}

const useBotTester = () => {
    const [isNotBot, setIsNotBot] = useState(false)
    return {
        BotTester: () => <BotTester onValidated={() => setIsNotBot(true)} />,
        isNotBot,
    }
}

export default useBotTester