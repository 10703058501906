import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    selectAll,
    fetchPaymentMethods,
    addPaymentMethod,
    updateCardValidation,
    isNewPaymentMethodUpdated,
    addPaymentInProgress,
    addPaymentMethodSuccess,
    addPaymentMethodError,
    getDefaultPaymentMethod,
} from './slice'
import { isAuthorized, useCreator } from './dependencies'

const usePaymentMethods = (fetch = false) => {
    const paymentMethods = useSelector(selectAll)
    const _isAuthorized = useSelector(isAuthorized)
    const _isNewPaymentMethodUpdated = useSelector(isNewPaymentMethodUpdated)
    const _addPaymentInProgress = useSelector(addPaymentInProgress)
    const _addPaymentMethodSuccess = useSelector(addPaymentMethodSuccess)
    const _addPaymentMethodError = useSelector(addPaymentMethodError)
    const _getDefaultPaymentMethod = useSelector(getDefaultPaymentMethod)
    const dispatch = useDispatch()
    const _creator = useCreator()
    const publisherId = _creator?.id

    useEffect(() => {
        if (!_isAuthorized) {
            return
        }

        if (!publisherId) {
            return
        }

        const t = setTimeout(() => {
            if (fetch) {
                dispatch(fetchPaymentMethods({
                    publisherId,
                }))
            }
        }, 1000)

        return () => {
            clearTimeout(t)
        }
    }, [publisherId, _isAuthorized])


    const _addPaymentMethod = useCallback(({ token }: any) => {
        dispatch(addPaymentMethod({
            id: token.id,
            type: token.type,
            publisherId: _creator.id
        }))

    }, [_creator, dispatch])


    const _updateCardValidation = useCallback((payload: any) => {
        dispatch(updateCardValidation(payload))
    }, [])

    return {
        paymentMethods,
        addPaymentMethod: _addPaymentMethod,
        updateCardValidation: _updateCardValidation,
        isNewPaymentMethodUpdated: _isNewPaymentMethodUpdated,
        addPaymentInProgress: _addPaymentInProgress,
        addPaymentMethodSuccess: _addPaymentMethodSuccess,
        addPaymentMethodError: _addPaymentMethodError,
        getDefaultPaymentMethod: _getDefaultPaymentMethod,
    }
}

export default usePaymentMethods
