const name = 'events'

const events = {
    appStart: 'app_start',
    appExit: 'app_exit',
    
    productOptionChange: 'product_option_change',
    
    productChange: 'product_change',
    
    userAuthenicated: 'user_authenticated',
    userUnauthenicated: 'user_unauthenticated',
    
    newPurchaseSuccess: 'new_purchase_success',
    
    switchProductOptionSuccess: 'switch_product_option_success',
    
    purchaseGiftSuccess: 'purchase_gift_success',
    
    successMessageDisplayed: 'success_message_displayed',
    
    paymentMethodAdded: 'payment_method_added',
}

export {
    name,
    events,
}
