import { useCreator, usePaymentMethods } from "../../dependencies"
import CardBrandImage from '@piconetworks/pkg-card-brand-image'
import { useSelector, useDispatch } from "react-redux"
import { getSelectedPaymentMethod, setSelectedPaymentMethod, updatePaymentMethod, getDefaultPaymentMethod } from "@/entities/PaymentMethods"
import { useCallback } from "react"

const CardList = () => {
    const dispatch = useDispatch()
    const selectedPaymentMethod = useSelector(getSelectedPaymentMethod)
    const _getDefaultPaymentMethod = useSelector(getDefaultPaymentMethod)
    const { paymentMethods } = usePaymentMethods()
    const creator = useCreator()
    const publisherId = creator?.id

    const onClick = useCallback((paymentMethod: any) => {
        dispatch(setSelectedPaymentMethod(paymentMethod))
    }, [dispatch])

    const setDefaultPaymentMethod = useCallback((paymentMethodId: string) => {
        dispatch(updatePaymentMethod({
            sourceId: paymentMethodId,
            publisherId,
        }))
    }, [dispatch, publisherId])

    return (
        <div>
            {paymentMethods?.length > 0 &&
                paymentMethods.map((paymentMethod: any) =>
                    <div key={paymentMethod.id} className="mr-4 mb-4 text-picoblack font-bold">
                        <input onChange={(event) => onClick({id: event.target.value})} id={paymentMethod.id} name='paymentMethod' checked={paymentMethod.id === selectedPaymentMethod} value={paymentMethod.id} type="radio" className="hidden" />
                        <label htmlFor={paymentMethod.id} className="cursor-pointer flex justify-start items-center">
                            <span className="radio-check w-[16px] h-[16px] rounded-full"></span>
                            <span className="w-[40px] h-full">
                                {paymentMethod?.brand && <CardBrandImage brand={paymentMethod.brand.toLowerCase()} />}
                            </span>
                            <div className="flex flex-col ml-2">
                                <span className='ml-1 capitalize text-sm text-picoblack'>
                                    {paymentMethod.brand}
                                    <span className="tracking-widest mx-1.5">{`••••`}</span>
                                    {paymentMethod.last4}&nbsp;
                                    {paymentMethod.id === _getDefaultPaymentMethod && <span className="rounded-lg text-xs bg-gray-400 inline-block py-1 px-2 font-medium text-gray-700 tracking-pill">
                                        DEFAULT
                                    </span>}
                                </span>
                                <span className='ml-1 capitalize text-xs text-gray-700'>
                                    {`Expires ${paymentMethod.exp_month}/${paymentMethod?.exp_year % 1000}`}
                                </span>
                                {paymentMethod.id !== _getDefaultPaymentMethod && <span className='mt-1 ml-1 capitalize text-xs text-violet-400' onClick={(e) => {
                                    e.stopPropagation()
                                    e.preventDefault()

                                    setDefaultPaymentMethod(paymentMethod.id)
                                }}>
                                    Make Default
                                </span>}
                            </div>
                        </label>
                    </div>
                )
            }
        </div>
    )
}

export default CardList
