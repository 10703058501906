import {
    initialState,
    reducer,
    takes,
    fetchProduct,
    creatorId,
    isPledgeMode,
    isPass,
    isGroup,
    isSubscription,
    isTrial,
    isDonation,
    isUnknown,
    product,
    productId,
    selectAll,
    changeWording,
    getProductByOptionId,
} from './slice'

import {
    productTypes,
} from './dependencies'

import useProduct from './useProduct'

export default {
    initialState,
    reducer,
    takes,
}

export {
    useProduct,
    fetchProduct,
    creatorId,
    productTypes,
    isPledgeMode,
    isPass,
    isGroup,
    isSubscription,
    isTrial,
    isDonation,
    isUnknown,
    product,
    productId,
    selectAll,
    changeWording,
    getProductByOptionId,
}
