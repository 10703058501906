import createAsyncSaga from '@/utils/createAsyncSaga'
import * as endpoints from '@piconetworks/pkg-endpoints'

const name = 'authorization'

import { creator } from '@/entities/Creator'
import { claim as queryParamClaim } from '@/entities/QueryParams'

export {
    name,
    createAsyncSaga,
    endpoints,
    creator,
    queryParamClaim,
}