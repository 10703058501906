import { createAsyncSaga, name } from '../dependencies'

const authWithSocial = createAsyncSaga(
    `${name}/authWithSocial`,
    function* ({
        payload: {
            token = null,
            refresh_token = null
        },
        fulfilled,
        rejected,
    }) {
        try {
            if (!token) {
                throw Error('token must be provided')
            }

            if (!refresh_token) {
                throw Error('refresh_token must be provided')
            }

            yield fulfilled({
                token,
                refresh_token
            })

        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default authWithSocial

