import { createAsyncSaga, name, endpoints } from '../dependencies'
import logger from '@/lib/logger'
import { addError } from '@/lib/rum'


const fetchCustomFields = createAsyncSaga(
    `${name}/fetchCustomFields`,
    function* ({
        payload: {
            productId = null,
            publisherId = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!productId) {
                throw new Error('productId not provided')
            }

            const { data } = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: `/query`,
                headers: {
                    publisherid: publisherId,
                },
                data: {
                    query: `{
                        field(product_id:"${productId}")
                        {
                            id,
                            description,
                            label,
                            order,
                            required,
                            connected_property{
                                id
                                label
                                type
                                elastic_reference_id
                                options{
                                    label,
                                    value
                                },
                                class
                            }
                        }
                    }`
                }
            })

            yield fulfilled(data)
        } catch (error) {
            logger.log(`${name}/fetchCustomFields`, {payload}, error)
            addError(error, {payload})
            yield rejected({
                error: error?.message,
            })
        }
    }
)



export default fetchCustomFields
