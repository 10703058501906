import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { isProductOptionsFormValid, selectedProductOptionId, selectedCustomPrice, getSwitchProration, isSwitchableNow } from '@/entities/ProductOptions'
import { isCustomFieldsFormValid as customFieldsFormValid, useCustomFields } from '@/entities/CustomFields'
import { getSelectedPaymentMethod } from '@/entities/PaymentMethods'
import { useAuthorization } from '@/entities/Authorization'
import { useUser } from '@/entities/User'
import { useCreator } from '@/entities/Creator'
import { usePaymentHistory } from '@/entities/PaymentHistory'
import { useGift } from '@/entities/Gift'

import {
    checkoutDefault,
    checkoutDefaultInProgress,
    checkoutSwitch,
    checkoutSwitchInProgress,
    checkoutGift,
    checkoutGiftInProgress,
    selectedSwitchNow,
    checkoutSucceeded,
    checkoutError,
} from './slice'

import useCheckoutMode from './useCheckoutMode'

const useCart = () => {
    const dispatch = useDispatch()

    const _selectedSwitchNow = useSelector(selectedSwitchNow)

    const isAuthorized = useAuthorization()
    const user = useUser()
    const creator = useCreator()
    const creatorId = creator?.id

    const customFields = useCustomFields()

    const { currentProductOptionId, nextPeriodsProductOptionId } = usePaymentHistory()
    const {
        giftModeEnabled,
        isGiftFormValid,
        redeemUrl,
        senderFirstName,
        recipientFirstName,
        recipientEmail,
        giftNote,
    } = useGift()

    const { checkoutMode, checkoutModes } = useCheckoutMode()

    const _customFieldsFormValid = useSelector(customFieldsFormValid) || customFields?.length === 0
    const _selectedProductOptionId = useSelector(selectedProductOptionId)
    const _selectedCustomPrice = useSelector(selectedCustomPrice)
    const _isProductOptionsFormValid = useSelector(isProductOptionsFormValid)
    const _selectedPaymentMethod = useSelector(getSelectedPaymentMethod)
    const _checkoutDefaultInProgress = useSelector(checkoutDefaultInProgress)
    const _checkoutSwitchInProgress = useSelector(checkoutSwitchInProgress)
    const _checkoutGiftInProgress = useSelector(checkoutGiftInProgress)
    const _isSwitchableNow = useSelector(isSwitchableNow)
    const _checkoutSucceeded = useSelector(checkoutSucceeded)
    const _checkoutError = useSelector(checkoutError)

    const isGiftCartValid = isAuthorized
        && user?.id
        && giftModeEnabled
        && isGiftFormValid
        && _selectedPaymentMethod

    const isSwitchCartValid = isAuthorized
        && !(_selectedSwitchNow && !_isSwitchableNow)
        && user?.id
        && _customFieldsFormValid
        && _isProductOptionsFormValid
        && _selectedPaymentMethod
        && !!currentProductOptionId
        && currentProductOptionId !== _selectedProductOptionId

    const isDefaultCartValid = isAuthorized
        && user?.id
        && _customFieldsFormValid
        && _isProductOptionsFormValid
        && _selectedPaymentMethod
        && !currentProductOptionId

    const isCartValid = (
        checkoutMode === checkoutModes.gift && isGiftCartValid
        || checkoutMode === checkoutModes.switch && isSwitchCartValid
        || checkoutMode === checkoutModes.default && isDefaultCartValid
    )

    const willBeSubscribed = nextPeriodsProductOptionId === _selectedProductOptionId

    const enablePayButton = isCartValid 
        && (
            giftModeEnabled 
            || (
                !_checkoutDefaultInProgress
                && !_checkoutSwitchInProgress
                && !_checkoutGiftInProgress
                && !willBeSubscribed
            )
        )

    useEffect(() => {
        if (!currentProductOptionId) {
            return
        }

        if (!creatorId) {
            return
        }

        if (!_selectedProductOptionId) {
            return
        }

        dispatch(getSwitchProration({
            productOptionId: _selectedProductOptionId,
            publisherId: creatorId,
            customPrice: _selectedCustomPrice,
        }))
    }, [
        _selectedProductOptionId,
        _selectedCustomPrice,
        creatorId,
        dispatch,
        currentProductOptionId,
    ])
    
    const executePurchaseDefault = useCallback(() => {
        if (!isCartValid) {
            return
        }

        dispatch(checkoutDefault({
            productOptionId: _selectedProductOptionId,
            publisherId: creatorId,
            customPrice: _selectedCustomPrice,
            sourceId: _selectedPaymentMethod
        }))
    }, [
        _selectedCustomPrice,
        _selectedProductOptionId,
        _selectedPaymentMethod,
        creatorId,
        isCartValid,
        checkoutDefault,
        dispatch,
    ])

    const executePurchaseSwitch = useCallback(() => {
        if (!isCartValid) {
            return
        }

        dispatch(checkoutSwitch({
            productOptionId: _selectedProductOptionId,
            publisherId: creatorId,
            customPrice: _selectedCustomPrice,
            sourceId: _selectedPaymentMethod
        }))
    }, [
        _selectedCustomPrice,
        _selectedProductOptionId,
        _selectedPaymentMethod,
        creatorId,
        isCartValid,
        dispatch,
        checkoutSwitch,
    ])

    const executePurchaseGift = useCallback(() => {
        if (!isCartValid) {
            return
        }

        dispatch(checkoutGift({
            productOptionId: _selectedProductOptionId,
            publisherId: creatorId,
            sourceId: _selectedPaymentMethod,
            redeemUrl,
            senderFirstName,
            recipientFirstName,
            recipientEmail,
            giftNote,        
        }))
    }, [
        _selectedProductOptionId,
        _selectedPaymentMethod,
        creatorId,
        isCartValid,
        checkoutGift,
        dispatch,
        redeemUrl,
        senderFirstName,
        recipientFirstName,
        recipientEmail,
        giftNote,
    ])

    let executePurchase = () => {}
    if (checkoutMode === checkoutModes.gift) {
        executePurchase = executePurchaseGift
    } else if (checkoutMode === checkoutModes.switch) {
        executePurchase = executePurchaseSwitch
    } else if (checkoutMode === checkoutModes.default) {
        executePurchase = executePurchaseDefault
    }

    return {
        executePurchase,
        enablePayButton,
        selectedSwitchNow: _selectedSwitchNow,
        executePurchaseSuccess: _checkoutSucceeded,
        executePurchaseError: _checkoutError,
    }
}

export default useCart
