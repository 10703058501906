import { createAsyncSaga, name, endpoints } from '../dependencies'

const purchaseProduct = createAsyncSaga(
    `${name}/purchaseProduct`,
    function* ({
        payload: {
            productOptionId = null,
            publisherId = null,
            customPrice = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            if (!publisherId) {
                throw new Error('publisherId not provided')
            }

            if (!productOptionId) {
                throw new Error('productOptionId not provided')
            }

            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: `${endpoints.PURCHASE_PRODUCT()}/${productOptionId}`,
                headers: {
                    publisherid: publisherId
                },
                data: {
                    ...(customPrice && {custom_price: parseInt(customPrice)})
                },
            })

            yield fulfilled(response)
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default purchaseProduct
