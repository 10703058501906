import {
    createDraftSafeSelector,
    createSlice,
} from '@reduxjs/toolkit'

import { name } from '../dependencies'

import fetchCreator from './fetchCreator'

const initialState = {
    creatorId: null,
    creator: null,
    fetchCreatorInProgress: false,
    fetchCreatorError: null,
}

const creatorSlice = createSlice({
    name,
    initialState,
    reducers: {
    },
    extraReducers: {
        [fetchCreator.pending]: (state: any, { payload: creatorId }: any) => {
            state.creatorId = creatorId
            state.fetchCreatorInProgress = true
            state.fetchCreatorError = null
        },
        [fetchCreator.fulfilled]: (state: any, { payload: creator }: any) => {
            state.creator = creator
            state.fetchCreatorInProgress = false
            state.fetchCreatorError = null
        },
        [fetchCreator.rejected]: (state: any, { payload: error }: any) => {
            state.creatorId = null
            state.creator = null
            state.fetchCreatorInProgress = false
            state.fetchCreatorError = error
        },
    },
})

// SELECTORS
const getCreator = (state: any) => state[name]
const creator = createDraftSafeSelector(
    getCreator,
    (Creator: any) => Creator?.creator,
)
const fetchCreatorInProgress = createDraftSafeSelector(
    getCreator,
    (Creator: any) => Creator?.fetchCreatorInProgress,
)
const fetchCreatorError = createDraftSafeSelector(
    getCreator,
    (Creator: any) => Creator?.fetchCreatorError,
)
const contributionType = createDraftSafeSelector(
    creator,
    (_creator: any) => _creator?.contribution_type,
)

// GENERAL
const takes = [
    ...fetchCreator.takes,
]

const CreatorInitialState = {
    [name]: initialState,
}

const CreatorReducer = {
    [name]: creatorSlice.reducer,
}

// EXPORTS
export default creatorSlice.reducer

export {
    takes,
    CreatorInitialState as initialState,
    CreatorReducer as reducer,

    fetchCreator,

    creator,
    fetchCreatorInProgress,
    fetchCreatorError,
    contributionType,
}
