import { useCallback, useEffect, useState } from "react"
import { isEmpty, useCreator } from '../dependencies'
import { initializeAuthFlow, useOneTimeCode } from "@/entities/Authorization"
import { useDispatch, useSelector } from 'react-redux'

enum FormTypes {
    email = "email",
    sms = "sms",
}


interface Payload {
    publisherId: string;
    creatorUsername: string;
    email?: string;
    phone_number?: string;
}

const useAuthForm = () => {
    const creator = useCreator()
    const dispatch = useDispatch()

    const [type, setType] = useState<FormTypes>(creator?.default_authentication_method)
    const { oneTimeCodeSent, oneTimeCodeConfirmed, oneTimeCodeInProgress, requestOneTimeCode } = useOneTimeCode({ publisherId: creator?.id })


    const onSubmit = useCallback((e: any) => {
        e.preventDefault()

        const payload: Payload = {
            publisherId: creator?.id,
            creatorUsername: creator?.username
        }

        const formData = new FormData(e.target);
        const values: any = Object.fromEntries(formData);

        if (isEmpty(values)) {
            return
        }

        if (type === FormTypes.email) {
            if (!values?.email) return
            payload.email = values.email
        }

        if (type === FormTypes.sms) {
            if (!values?.phone_number) return
            payload.phone_number = values.phone_number
        }

        dispatch(initializeAuthFlow(payload))


    }, [creator, type])


    useEffect(() => {
        setType(creator?.default_authentication_method)
    }, [creator])


    return {
        type,
        oneTimeCodeSent,
        oneTimeCodeConfirmed,
        oneTimeCodeInProgress,
        setType,
        onSubmit,
    }
}

export {
    FormTypes
}

export default useAuthForm