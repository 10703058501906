import { useState, useEffect } from 'react'

import { useCreator } from '@/entities/Creator'
import { useCustomFields } from '@/entities/CustomFields'
import { useGift } from '@/entities/Gift'
import { useNewsletters } from '@/entities/Newsletters'
import { useProduct } from '@/entities/Product'
import { useUser } from '@/entities/User'
import Head from 'next/head'

import AccountSummary from '@/features/AccountSummary'
import AdditonalInfo from '@/features/AdditonalInfo'
import ContactInfo, { useShowAuthForm } from '@/features/ContactInfo'
import DueToday from '@/features/DueToday'
import GiftForm from '@/features/GiftForm'
import Newsletters from '@/features/Newsletters'
import OrderSummary from '@/features/OrderSummary'
import PaymentCard from '@/features/PaymentCard'
import ProductOptionPicker from '@/features/ProductOptionPicker'
import SuccessMessage from '@/features/SuccessMessage'
import TrialAfter from '@/features/TrialAfter'
import useSuccessMessage from '@/features/SuccessMessage/hooks/useSuccessMessage'

import Skeleton from '@/components/Skeleton'
import Favicon from '@/components/Favicon'

import useBotTester from '@/features/BotTester/useBotTester'
import useAnalytics from '@/features/Analytics/useAnalytics'
import { useQueryParams } from '@/entities/QueryParams'

const Checkout = () => {
    const [orderSummaryVisible, setOrderSummaryVisible] = useState(false)
    const [productOptionPickerVisible, setProductOptionPickerVisible] = useState(false)

    const product = useProduct()

    const creator = useCreator()

    const newsletters = useNewsletters()

    const user = useUser()

    const successMessage = useSuccessMessage(true)

    const customFields = useCustomFields()

    const showAuthForm = useShowAuthForm()

    const { giftModeEnabled } = useGift()

    const { track } = useAnalytics()

    const { conversationId } = useQueryParams()

    const showAccountSummaryToggle = productOptionPickerVisible

    const headerName = creator?.name

    useEffect(() => {
        if (window?.innerWidth > 767) {
            setOrderSummaryVisible(true)
        }
    }, [])

    useEffect(() => {
        if(creator.id) {
            track({ 
                eventType: "page_view", 
                publisherId: creator.id, 
                eventData: { 
                    conversation_id: conversationId 
                }})
        }
    },[creator])

    const { BotTester, isNotBot } = useBotTester()

    return (
        <main>
            <Head>
                <Favicon />
                <title>
                    Checkout {headerName ? `- ${headerName}` : ''}
                </title>
                {process.env.STAGE === 'production' && <script defer data-domain="pay.hype.co" src="https://plausible.io/js/script.js"></script>}
            </Head>
            <BotTester />
            <div className='container mx-auto px-0 md:px-10'>
                <div className='grid grid-cols-1 gap-1 md:grid-cols-2 md:gap-12'>
                    <section className='sticky z-10 top-0 md:h-[calc(100vh-5rem)] md:overflow-y-scroll md:scrollbar-hide'>
                        <div className='md:mt-8 px-8 py-6 bg-picoblack bg-opacity-3 backdrop-blur-[30px] rounded-b-2xl w-full md:rounded-2xl'>
                            <div className={`top-0`}>
                                <Skeleton showContents={!!product}>
                                    <AccountSummary orderSummaryVisible={orderSummaryVisible} showToggle={showAccountSummaryToggle} onClickToggle={() => setOrderSummaryVisible(!orderSummaryVisible)} />
                                </Skeleton>
                            </div>
                            {orderSummaryVisible && <div className='mt-5'>
                                <Skeleton showContents={!!product}>
                                    <OrderSummary onClickChangeSubscription={() => {
                                        setProductOptionPickerVisible(true)
                                        setOrderSummaryVisible(false)
                                    }} />
                                </Skeleton>
                            </div>}
                            {productOptionPickerVisible && <div className='mt-5'>
                                <Skeleton showContents={!!product}>
                                    <ProductOptionPicker onClickCloseChangeSubscription={() => {
                                        setProductOptionPickerVisible(false)
                                        setOrderSummaryVisible(true)
                                    }} />
                                </Skeleton>
                            </div>}

                            {!productOptionPickerVisible && <div className='mt-5'>
                                <>
                                    <Skeleton showContents={!!product}>
                                        <TrialAfter />
                                    </Skeleton>
                                    <Skeleton showContents={!!product}>
                                        <DueToday />
                                    </Skeleton>
                                </>
                            </div>}
                        </div>
                    </section>
                    <section className='relative bg-white sm:bg-transparent'>

                        <div className='my-8'>

                            <div className={`${successMessage.show ? 'block' : 'hidden'}`}>
                                <div className={`card`}>
                                    <SuccessMessage />
                                </div>
                            </div>
                            <div className={`${!successMessage.show ? 'block' : 'hidden'}`}>
                                {/* <div className={`card`}>
                                    <GiftWrapper />
                                </div> */}
                                <div className='card'>
                                    <Skeleton showContents={isNotBot && ((!!creator.id && showAuthForm) || user?.id)}>
                                        <ContactInfo />
                                    </Skeleton>
                                </div>
                                {!!user && giftModeEnabled && <div className='card'>
                                    <Skeleton showContents={((!!creator.id && showAuthForm) || user?.id) && giftModeEnabled}>
                                        <GiftForm />
                                    </Skeleton>
                                </div>}
                                {!!user && !!creator.id && !giftModeEnabled && customFields?.length !== 0 && <div className='card'>
                                    <Skeleton showContents={!!creator.id}>
                                        <AdditonalInfo />
                                    </Skeleton>
                                </div>}
                                {!!user && !!creator.id && !giftModeEnabled && newsletters?.all?.length !== 0 && <div className='card'>
                                    <Skeleton showContents={!!creator.id}>
                                        <Newsletters />
                                    </Skeleton>
                                </div>}
                                {!!user && (
                                    <div className='card'>
                                        <Skeleton showContents={!!creator.id}>
                                            <PaymentCard />
                                        </Skeleton>
                                    </div>
                                )}
                            </div>
                        </div>
                    </section>
                </div>
                <div className='md:bottom-0 md:fixed h-20 flex ml-0'>
                    <p className='text-gray-800 font-semibold text-sm bottom-6 flex items-center justify-center w-screen md:w-auto'>
                        <span>
                            Powered by
                        </span>
                        <a href="https://hype.co/" className="ml-2" target="_blank">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1318 305" width="48"><g fill="#000"><path d="m108.779 304.002h-108.779v-304.002h108.779v106.504h98.645v-106.504h108.779v304.002h-108.779v-106.504h-98.645z"></path><path d="m567.185 304.002h-108.779v-110.434l-136.904-193.568h120.567l46.324 69.0725 25.644 38.0515h.207l70.933-107.124h118.292l-136.284 193.568z"></path><path clipRule="evenodd" d="m817.873 304.002h-108.778v-304.002h171.44c21.232 0 40.671 1.65442 58.319 4.96327 17.647 3.17099 32.813 8.75473 45.497 16.75113 12.821 7.9964 22.749 18.8191 29.779 32.4682 7.03 13.5112 10.55 30.6069 10.55 51.2874v.414c0 20.68-3.45 37.845-10.34 51.494-6.9 13.649-16.616 24.471-29.162 32.468-12.409 7.996-27.367 13.649-44.877 16.958-17.509 3.171-36.88 4.756-58.112 4.756h-64.316zm0-224.5894v52.9414h56.872c6.204 0 11.649-.482 16.337-1.447 4.688-1.103 8.548-2.758 11.581-4.964 3.033-2.205 5.308-4.963 6.824-8.272 1.517-3.309 2.275-7.238 2.275-11.787v-.414c0-4.55-.758-8.479-2.275-11.7879-1.516-3.3089-3.791-5.9973-6.824-8.0653-3.033-2.0681-6.893-3.5846-11.581-4.5497-4.688-1.103-10.133-1.6545-16.337-1.6545z" fillRule="evenodd"></path><path d="m1039.44 304.002v-304.002h278.56v80.4461h-169.7v31.3319h147.02v78.329h-147.02v33.448h169.7v80.447z"></path></g></svg>
                        </a>
                    </p>
                </div>
            </div>
        </main>
    )
}

export default Checkout
