import { call, put, take } from 'redux-saga/effects'
import {
    createAsyncSaga,
    name,
    saveCustomFields,
    saveNewsletters,
    updatePaymentMethod,
} from '../dependencies'

import purchaseGift from './purchaseGift'

const checkoutGift = createAsyncSaga(
    `${name}/checkoutGift`,
    function* ({
        payload: {
            productOptionId = null,
            publisherId = null,
            sourceId = null,
            redeemUrl = null,
            senderFirstName = null,
            recipientFirstName = null,
            recipientEmail = null,
            giftNote = null,
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            // update default payment method
            yield put(yield call(updatePaymentMethod, {
                sourceId,
                publisherId,
            }))

            yield take(updatePaymentMethod.pending)
            const updateDefaultPaymentResult = yield take([updatePaymentMethod.fulfilled, updatePaymentMethod.rejected])

            if (updateDefaultPaymentResult.type === updatePaymentMethod.rejected) {
                throw new Error('update default payment method failed')
            }

            // make purchase
            yield put(yield call(purchaseGift, {
                productOptionId,
                publisherId,
                redeemUrl,
                senderFirstName,
                recipientFirstName,
                recipientEmail,
                giftNote,
            }))

            yield take(purchaseGift.pending)
            const purchaseResult = yield take([purchaseGift.fulfilled, purchaseGift.rejected])

            if (purchaseResult.type === purchaseGift.rejected) {
                throw new Error('There was an issue trying to complete your purchase. Please try again or contact support if this persists.')
            }

            yield fulfilled()
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default checkoutGift
