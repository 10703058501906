import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchProduct, product, getSwitchable, selectAll } from './slice'
import { productOptionId } from './dependencies'

const useProduct = (fetchData = false) => {
    const dispatch = useDispatch()

    const allProducts = useSelector(selectAll)
    const _productOptionId = useSelector(productOptionId)
    const _product = useSelector(product)

    const productDownloaded = allProducts.find((product: any) => (
        product?.options.find((option: any) => option?.id === _productOptionId)
    ))

    useEffect(() => {
        if (_product?.options?.find((option: any) => option?.id === _productOptionId)) {
            return
        }

        if (!fetchData) {
            return
        }

        if (!_productOptionId) {
            return
        }

        // if (productDownloaded) {
        //     return
        // }

        dispatch(fetchProduct({
            productOptionId: _productOptionId,
        }))
    }, [_productOptionId, _product?.options, productDownloaded])

    useEffect(() => {
        if (productDownloaded) {
            return
        }
        if (!_product?.id) {
            return
        }

        dispatch(getSwitchable({
            publisherId: _product?.publisher_id,
            productId: _product?.id
        }))
    }, [_product?.id, productDownloaded, _product?.id, _product?.publisher_id])

    return _product
}

export default useProduct
