import { datadogRum } from '@datadog/browser-rum'

const allowedStages = ['production', 'staging', 'qa']

const STAGE = process.env.STAGE ?? 'development'
const APP_VERSION = process.env.VERSION ?? 'local'

const DD_ENABLED = process.env.DATADOG_CLIENT_TOKEN && process.env.DATADOG_APPLICATION_ID && allowedStages.includes(STAGE)

const PICO_API_URL = process.env.PICO_API_URL ?? 'https://api'

const datadogRumInit = () => {
    if (!DD_ENABLED || typeof window !== "object") {
        return
    }
    datadogRum.init({
        applicationId: process.env.DATADOG_APPLICATION_ID ?? '',
        clientToken: process.env.DATADOG_CLIENT_TOKEN ?? '',
        site: 'datadoghq.com',
        service: 'pay',
        env: STAGE,
        version: APP_VERSION,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        trackFrustrations: true,
        defaultPrivacyLevel: 'mask-user-input',
        silentMultipleInit: true,
        trackSessionAcrossSubdomains: true,
        allowedTracingUrls: [PICO_API_URL]
    })

    datadogRum.startSessionReplayRecording()
}

export const addError = (error: any, context?: any) => {
    if (!DD_ENABLED || typeof window !== "object") {
        return
    }
    return datadogRum.addError(error, context)
}

export default datadogRumInit
