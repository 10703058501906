import { useSelector } from 'react-redux'
import { format } from '@piconetworks/pkg-currency'

import { selectById } from '@/entities/ProductOptions'
import { useCallback, useEffect, useState } from 'react'

const DonationProductOption = ({
    id = null,
    hide = false,
    onChange = ({id, customValue = null}: any) => {},
    checked = false,
}) => {
    const productOption = useSelector((state: any) => selectById(state, id))

    const amount = productOption?.price
    const currency = productOption?.currency
    const formattedValue = format({ amount, currency, symbol: true })

    const onClick = useCallback(() => {
        onChange({id})
    }, [id])

    return (
        <div
            onClick={onClick}
            key={id}
            data-ui={checked && 'checked'}
            className={`
                cursor-default
                w-full
                first:ml-0
                last:mr-0
                mx-1.5
                items-center
                flex
                justify-center
                ${hide && 'hidden'}
                bg-white
                rounded-lg
                p-4
                font-medium
                border
                border-picoblack
                border-opacity-5
                data-checked:bg-violet-100
                data-checked:text-violet-500
                data-checked:border
                data-checked:border-violet-500
            `}
        >
            {!!amount && <div>{formattedValue}</div>}
        </div>
    )
}

export default DonationProductOption
