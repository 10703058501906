import { useSelector } from 'react-redux'
import { format } from '@piconetworks/pkg-currency'

import { selectAll } from '@/entities/Product'
import { selectById } from '@/entities/ProductOptions'

const GenericProductOption = ({
    id = null,
    hide = false,
    onChange = ({id}: any) => {},
    checked = false,
}) => {
    const allProducts = useSelector(selectAll)

    const product = allProducts.find((product: any) => (
        product?.options.find((option: any) => option?.id === id)
    ))

    const productTitle = product?.title
    const productOption = useSelector((state: any) => selectById(state, id))

    const amount = productOption?.price
    const currency = productOption?.currency
    const formattedValue = format({ amount, currency, symbol: true })

    return (
        <div
            onClick={() => onChange({id})}
            key={id}
            data-ui={checked && 'checked'}
            className={`
                w-full
                flex
                justify-between
                ${hide && 'hidden'}
                bg-white
                rounded-lg
                p-4
                font-medium
                border
                my-2
                border-picoblack
                border-opacity-5
                data-checked:bg-violet-100
                data-checked:text-violet-500
                data-checked:border
                data-checked:border-violet-500
            `}
        >
            <div>{productTitle}</div>
            <div>{formattedValue}</div>
        </div>
    )
}

export default GenericProductOption
