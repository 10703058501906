import { useCallback, useEffect, useState } from 'react'
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js'
import { useSelector } from 'react-redux'

import logger from '@/lib/logger'
import { addError } from '@/lib/rum'

import { selectedProductOption, useProduct, usePaymentMethods } from '../../dependencies'
import { useCart } from '@/entities/Cart'


const ApplePay = (_: any, ref: any) => {
    const stripe = useStripe()
    const product = useProduct()

    const { executePurchase, executePurchaseSuccess, executePurchaseError } = useCart()
    const { addPaymentMethod, addPaymentMethodSuccess, addPaymentMethodError } = usePaymentMethods()

    const [_complete, setComplete] = useState<any>(null)
    const [ paymentRequest, setPaymentRequest ] = useState<any>(null)

    const _selectedProductOption = useSelector(selectedProductOption)

    useEffect(() => {
        if (!_complete) {
            return
        }

        if (addPaymentMethodSuccess) {
            executePurchase()
        }

        if (addPaymentMethodError) {
            logger.error('ApplePay', addPaymentMethodError)
            _complete?.('fail')
        }
    }, [addPaymentMethodSuccess, addPaymentMethodError, executePurchase, _complete])

    useEffect(() => {
        if (executePurchaseSuccess && _complete) {
            _complete('success')
            setComplete(null)
        }

        if (executePurchaseError && _complete) {
            _complete('fail')
            setComplete(null)
        }
    }, [executePurchaseSuccess, executePurchaseError, _complete, setComplete])

    const onTokenEvent = useCallback(async (event: any) => {
        addPaymentMethod({
            token: event?.token,
        })
        setComplete(() => event.complete)
    }, [setComplete, addPaymentMethod])

    useEffect(() => {
        try {
            if (stripe && _selectedProductOption) {
                const pr = stripe.paymentRequest({
                    country: 'US',
                    currency: _selectedProductOption?.currency.toLowerCase(),
                    total: {
                        label: product?.title || 'product',
                        amount: _selectedProductOption?.price || 0,
                    }
                })

                pr.canMakePayment().then((result: any) => {
                    if (result) {
                        setPaymentRequest(pr)
                    }
                })

                pr.on('token', onTokenEvent)
            }
        } catch (error) {
            logger.error('ApplePay', {stripe, product, selectedProductOption: _selectedProductOption}, error)
            addError(error, {stripe, product, selectedProductOption: _selectedProductOption})
        }
    }, [
        onTokenEvent,
        stripe,
        product,
        _selectedProductOption,
    ])

    return (
        <>
            {paymentRequest && (
                <div className='mb-5'>
                    <PaymentRequestButtonElement options={{ paymentRequest }} />
                    <div className="inline-flex relative items-center justify-center w-full">
                        <hr className="w-full h-px my-5 bg-gray-500 border-0 " />
                        <span className="absolute px-3 font-bold text-sm text-gray-700 -translate-x-1/2 bg-white left-1/2 ">OR PAY WITH</span>
                    </div>
                </div>
            )}
        </>
    )
}

export default ApplePay
