import { createAsyncSaga, name, endpoints } from '../dependencies'
import {  putResolve } from 'redux-saga/effects'
import { STORE_TOKENS } from '@piconetworks/pkg-network-middleware'
const MARKETING_SITE_URL = process.env.MARKETING_SITE_URL

const register = createAsyncSaga(
    `${name}/register`,
    function* ({
        payload: {
            email = null,
            phone_number = null,
            creatorUsername = null,
            publisherId = null
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
             
            if (!publisherId) {
                throw Error('publisher id must be provided')
            }

            if (!(!email || !phone_number)) {
                throw new Error('email or phone_number not provided')
            }

            if (!creatorUsername){
                throw new Error('creatorUsername not provided')
            }
            
            const response = yield request({
                method: 'post',
                baseURL: endpoints.PICO_API_URL(),
                url: `${endpoints.OAUTH_USER_LINK()}`,
                headers: {
                    publisherid: publisherId
                },
                data: {
                    is_otp_enabled: true,
                    return_token: true,
                    redirect_url: `${MARKETING_SITE_URL}/${creatorUsername}/login/token`,
                    ...(email ? { email } : {}),
                    ...(phone_number ? { phone_number } : {}),
                }
            })

            yield putResolve({
                type: STORE_TOKENS,
                access_token: response?.token,
                refresh_token: response?.refresh_token
            })

            yield fulfilled(response)
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default register
