import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { fetchCreator, creator } from './slice'
import { creatorId } from './dependencies'

const useCreator = (fetchData = false) => {
    const dispatch = useDispatch()
    
    const _creatorId = useSelector(creatorId)
    const _creator = useSelector(creator) || {
        id: null,
    }

    useEffect(() => {
        if (!fetchData) {
            return
        }

        if (!_creatorId) {
            return
        }

        dispatch(fetchCreator({
            creatorId: _creatorId,
        }))
    }, [_creatorId])

    return _creator
}

export default useCreator
