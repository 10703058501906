import createAsyncSaga from '@/utils/createAsyncSaga'
import * as endpoints from '@piconetworks/pkg-endpoints'

import { productOptionId } from '@/entities/QueryParams'

const name = 'product'

const productTypes = {
    pledgeMode: 'pledgeMode',
    pass: 'pass',
    group: 'group',
    subscription: 'subscription',
    trial: 'trial',
    donation: 'donation',
    unknown: 'unknown',
}

const productTypeSuccessMessages = {
    [productTypes.subscription]: {
        trial: () => 'Your subscription trial has been successfully activated.',
        regular: () => 'Your subscription has been successfully activated.'
    },
    [productTypes.pass]: {
        regular: () => 'Your pass has been successfully activated.'
    },
    [productTypes.donation]: {
        regular: () => 'Your payment was successfully processed.',
    },
    [productTypes.unknown]: {
        regular: () => 'Your payment was successfully processed.',
    },
}

export {
    name,
    createAsyncSaga,
    endpoints,

    productOptionId,
    productTypes,

    productTypeSuccessMessages,
}
