import {
    initialState,
    reducer,
} from './slice'

import useGift from './useGift'

export default {
    initialState,
    reducer,
}

export {
    useGift,
}
