import {
    initialState,
    reducer,
    takes,
    exchangeClaim,
    exchangeClaimInProgress,
    authWithEmailPassword,
    authWithPhonePassword,
    register,
    checkHasPassword,
    isAuthorized,
    actions,
    authWithSocial,
    isNewRegistration,
    useRefreshTokenInProgress,
    generateClaim,
    deauthorize,
    outboundClaim,
    generateClaimError,
    initializeAuthFlow
} from './slice'

import useClaim from './useClaim'
import useOneTimeCode from './useOneTimeCode'
import useCheckHasPassword from './useCheckHasPassword'
import useAuthorization from './useAuthorization'

const Authorization = {
    initialState,
    reducer,
    takes,
    actions,
}

export default Authorization

export {
    useClaim,
    useCheckHasPassword,
    useOneTimeCode,

    deauthorize,
    initializeAuthFlow,
    register,
    authWithEmailPassword,
    authWithPhonePassword,
    authWithSocial,
    checkHasPassword,

    exchangeClaim,
    exchangeClaimInProgress,
    useRefreshTokenInProgress,
    
    isNewRegistration,
    isAuthorized,
    generateClaim,
    useAuthorization,
    outboundClaim,
    generateClaimError
}
