const Modal = ({ children, open = false, setOpen }: any) => {
    return (
        <div>
            <div className={`relative z-10 ${!open && 'hidden'}`} aria-labelledby="modal-title" role="dialog" aria-modal="true">
                <div className="fixed inset-0 bg-picoblack bg-opacity-10 transition-opacity" onClick={() => setOpen(false)}></div>
                <div className="
                        fixed
                        left-0
                        bottom-0
                        sm:top-1/2
                        sm:left-1/2
                        z-10
                        overflow-y-auto
                        w-full
                        h-full
                        sm:translate-x-[-50%]
                        sm:translate-y-[-50%]	
                        sm:w-[470px]
                    "
                >
                    <div className="
                            flex
                            min-h-full
                            items-end
                            justify-center
                            p-0
                            text-center
                            sm:items-center
                        "
                    >
                        <div className="
                                relative
                                transform
                                overflow-hidden
                                rounded-t-xl
                                sm:rounded-xl
                                bg-white
                                text-left
                                shadow-modal
                                transition-all
                                w-full
                                my-0
                                sm:my-8
                                sm:w-[432px]

                            "
                        >
                            <div className="bg-white">
                                {children}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default Modal
