import {
    initialState,
    reducer,
    takes,
    actions,
} from './slice'

import useEvents from './useEvents'

export default {
    initialState,
    reducer,
    takes,
    actions,
}

export {
    useEvents,
}
