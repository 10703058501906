// @ts-nocheck
import React from 'react'
import logger from '@/lib/logger'
import { addError } from '@/lib/rum'

class ErrorBoundary extends React.Component {

    static getDerivedStateFromError(error = {}) {
        return { hasError: true }
    }

    constructor(props = {}){
        super(props)
        this.state = { hasError: false }
    }

    componentDidCatch(error = {}) {
        logger.error('Errorboudary', {}, error)
        addError(error)
    }

    render() {
        const { hasError = false } = this.state
        const { children, RenderError } = this.props

        if (hasError) {
            return (
                <RenderError />
            )
        }

        return children
    }
}

ErrorBoundary.defaultProps = {
    RenderError: (error) => `${error.message}`,
}

export default ErrorBoundary
