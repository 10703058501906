import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import {
    selectedProductOption,
    useProductOptions,
    isTrial,
    formattedValue,
    formattedTrialValue,
    isFreeTrial,
    formattedTrialDuration,
    formattedPassDuration,
    formattedTrialEndDate,
    isSwitchableNow,
    formattedSwitchProration,
} from "@/entities/ProductOptions"
import { useProduct, productTypes, isDonation, changeWording } from '@/entities/Product'
import { useGift } from '@/entities/Gift'

import SwitchPicker from './SwitchPicker'
import InfomaticPill from './InfomaticPill'
import { selectedSwitchNow } from '@/entities/Cart'
import SwitchNowError from './SwitchError'
import useCheckoutMode from '@/entities/Cart/useCheckoutMode'
import { contributionType } from '@/entities/Creator'

const billingIntervalMap: any = {
    month: 'monthly',
    year: 'annually',
    day: 'daily',
}

const OrderSummary = ({
    onClickChangeSubscription = () => { },
}) => {
    const [today, setToday] = useState('')
    const [futureDate, setFutureDate] = useState('')
    const product = useProduct()
    const productOptions = useProductOptions()
    const { giftModeEnabled } = useGift()
    const { checkoutMode, checkoutModes } = useCheckoutMode()

    const _selectedProductOption = useSelector(selectedProductOption)
    const _formattedValue = useSelector(formattedValue)

    const _isSwitchMode = checkoutMode === checkoutModes.switch
    const _isTrial = useSelector(isTrial)
    const _isDonation = useSelector(isDonation)
    const _contributionType = useSelector(contributionType)
    const __changeWording = useSelector(changeWording)
    const _changeWording = (_isDonation && _contributionType === 'tip') ? 'tip' : __changeWording
    const _isFreeTrial = useSelector(isFreeTrial)
    const _isSwitchableNow = useSelector(isSwitchableNow)
    const _selectedSwitchNow = useSelector(selectedSwitchNow)

    const _formattedTrialValue = useSelector(formattedTrialValue)
    const _formattedTrialDuration = useSelector(formattedTrialDuration)
    const _formattedPassDuration = useSelector(formattedPassDuration)
    const _formattedTrialEndDate = useSelector(formattedTrialEndDate)
    const _formattedSwitchProration = useSelector(formattedSwitchProration)

    const productTitle = product?.title
    const productType = product?.type
    const interval = _selectedProductOption?.interval
    const billingInterval = billingIntervalMap?.[interval]
    const renewsDays = interval === 'month' ? 30 : 365

    useEffect(() => {
        const future = new Date();
        const _today = future.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })

        future.setDate(future.getDate() + renewsDays)

        const renewalDate = future.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' })

        setFutureDate(renewalDate)
        setToday(_today)
    }, [renewsDays, setFutureDate, setToday])

    const donationType = productType === productTypes.donation

    const oneTimeDonationType = donationType && interval === null
    const passType = productType === productTypes.pass

    const otherTypes = !passType && !oneTimeDonationType

    let payWording: any = ''

    if (_isTrial) {
        if (_isFreeTrial) {
            payWording = (
                <span className='leading-none'>
                    <span className='text-picoblack font-medium text-sm'><span className='capitalize'>{_formattedTrialDuration}</span> free</span><br />
                    <span className='text-xs text-picoblack text-opacity-50 font-medium'>after {_formattedValue}</span>
                </span>
            )
        } else {
            payWording = (
                <span className='leading-none'>
                    <span className='text-picoblack font-medium text-sm'>{_formattedTrialValue} for <span className='capitalize'>{_formattedTrialDuration}</span></span><br />
                    <span className='text-xs text-picoblack text-opacity-50 font-medium'>after {_formattedValue}</span>
                </span>
            )
        }
    }

    if (_isSwitchMode) {
        if (_selectedSwitchNow && _isSwitchableNow) {
            payWording = _formattedSwitchProration?.prorateAmount
        } else {
            payWording = _formattedValue
        }
    }
    
    return (
        <>
            <hr className='mb-4 w-full' />
            <h1 className='heading-1 mt-0 mb-4 tracking-pico'>Order Summary</h1>
            {!giftModeEnabled && <SwitchPicker />}
            {_selectedSwitchNow && !_isSwitchableNow ? (
                <>
                    <SwitchNowError />
                    {productOptions?.length > 1 && <button onClick={onClickChangeSubscription} className='mt-2 text-xs text-violet-500'>Change <span className='capitalize'>{_changeWording}</span></button>}

                </>
            ) : (
                <>
                    <InfomaticPill />
                    <div className='flex justify-between items-start'>
                        <div>
                            <h2 className='font-medium text-black text-sm text-opacity-70 font-antartica'>{giftModeEnabled && 'Gift'} {productTitle}</h2>
                            {otherTypes && (
                                <p className='mt-0.5 text-xs text-gray-800 font-antartica font-normal'>
                                    {_isTrial && !_isSwitchMode ? _formattedTrialEndDate : (
                                        <span>
                                            <span>Billed </span>
                                            <span className='capitalize'>
                                                {billingInterval}
                                            </span>
                                            <span> | Renews {futureDate}</span>
                                        </span>
                                    )}
                                </p>
                            )}
                            {passType && <p className='mt-0.5 text-xs text-gray-800 font-antartica font-normal capitalize'>{_formattedPassDuration} Pass | Starts {today}</p>}
                            {oneTimeDonationType && <p className='mt-0.5 text-xs text-gray-800 font-antartica font-normal'>Billed {today}</p>}
                            {productOptions?.length > 1 && <button onClick={onClickChangeSubscription} className='mt-2 text-xs text-violet-500'>Change <span className='capitalize'>{_changeWording}</span></button>}
                        </div>
                        <span className='text-right leading-none'>
                            <span className='font-medium font-antartica text-right text-sm'>{payWording}</span><br />
                            {_selectedSwitchNow && _isSwitchableNow && (<span className='text-picoblack text-opacity-50 font-antartica text-xs font-medium line-through'>{_formattedValue}</span>)}
                        </span>
                    </div>
                </>
            )}

        </>
    )
}

export default OrderSummary
