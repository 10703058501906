import { createAsyncSaga, name, endpoints } from '../dependencies'
import { register, requestOneTimeCode } from './'

import { call, put, take } from 'redux-saga/effects'

const OTP_STATE = {
    required: 'required',
    hide: 'hide',
    optional: 'optional'
}

const initializeAuthFlow = createAsyncSaga(
    `${name}/initializeAuthFlow`,
    function* ({
        payload: {
            email = null,
            phone_number = null,
            creatorUsername = null,
            publisherId = null
        } = {},
        fulfilled,
        rejected,
        request,
    }) {
        try {
            let response;
            if (!publisherId) {
                throw Error('publisher id must be provided')
            }

            if (!(!email || !phone_number)) {
                throw new Error('email or phone_number not provided')
            }

            if (email) {
                response = yield request({
                    method: 'get',
                    baseURL: endpoints.PICO_API_URL(),
                    url: `${endpoints.OAUTH_USER()}`,
                    headers: {
                        publisherid: publisherId
                    },
                    params: {
                        email,
                    }
                })
            }

            if (response?.is_new_registration || (response?.otp === OTP_STATE.optional || response?.otp === OTP_STATE.hide)) {
                yield put(yield call(register, {
                    email,
                    phone_number,
                    creatorUsername,
                    publisherId
                }))
            }

            if (phone_number || (response?.otp === OTP_STATE.required || response?.otp === OTP_STATE.optional)) {
                yield put(yield call(requestOneTimeCode, {
                    email,
                    phone_number,
                    publisherId
                }))
            }

            yield fulfilled(response)
        } catch (error) {
            yield rejected({
                error: error?.message,
            })
        }
    }
)

export default initializeAuthFlow
