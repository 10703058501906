import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { exchangeClaim, claim, exchangeClaimError, isAuthorized } from './slice'
import { creator, queryParamClaim } from './dependencies'

const useClaim = (fetch = false) => {
    const _claim = useSelector(claim)
    const _exchangeClaimError = useSelector(exchangeClaimError)
    const _queryParamClaim = useSelector(queryParamClaim)
    const _creator = useSelector(creator)
    const dispatch = useDispatch()

    useEffect(() => {
        if (_exchangeClaimError) {
            return
        }

        if (!_creator) {
            return
        }

        if (!_queryParamClaim) {
            return
        }

        if (!fetch) {
            return
        }

        dispatch(exchangeClaim({
            claim: _queryParamClaim
        }))
    }, [_queryParamClaim, _creator, _exchangeClaimError])

    return {
        claim: _claim,
        exchangeClaimError: _exchangeClaimError,
    }
}

export default useClaim
