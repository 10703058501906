import { STORE_TOKENS } from '@piconetworks/pkg-network-middleware'

import {
    createDraftSafeSelector,
    createSlice,
    createEntityAdapter,
} from '@reduxjs/toolkit'

import { name, deauthorize } from '../dependencies'

const refreshTokensAdapter = createEntityAdapter({
    selectId: (refreshToken: any) => refreshToken?.id,
    sortComparer: (a: any, b: any) => a?.id - b?.id,
})

const initialState = refreshTokensAdapter.getInitialState({
    id: null,
    value: null,
})

const refreshTokenSlice = createSlice({
    name,
    initialState,
    reducers: {
        setOne: refreshTokensAdapter.setOne,
        removeOne: (state: any, { payload }: any) => {
            refreshTokensAdapter.removeOne(state, payload?.id)
        },
    },
    extraReducers: {
        [deauthorize]: (state: any, { payload }: any) => {
            refreshTokensAdapter.removeAll(state)
        }
        // [STORE_TOKENS]: () => {
        //     refreshTokensAdapter.setOne()
        // },
        // [fetchProduct.fulfilled]: (state: any, { payload: product }: any) => {
        //     refreshTokensAdapter.setAll(state, product?.options)
        // },
    },
})

const { setOne, removeOne } = refreshTokenSlice.actions

// SELECTORS
const getRefreshTokens = (state: any) => state[name]
const {
    selectAll,
    selectById,
    selectEntities,
    selectIds,
    selectTotal,
} = refreshTokensAdapter.getSelectors((state: any) => state[name])

const refreshTokens = createDraftSafeSelector(
    getRefreshTokens,
    (refreshTokens: any) => refreshTokens?.options,
)

// GENERAL
const refreshTokenInitialState = {
    [name]: initialState,
}

const refreshTokenReducer = {
    [name]: refreshTokenSlice.reducer,
}

// EXPORTS
export default refreshTokenSlice.reducer

export {
    refreshTokenInitialState as initialState,
    refreshTokenReducer as reducer,

    refreshTokens,
    selectAll,
    selectById,
    selectEntities,
    selectIds,
    selectTotal,

    setOne,
    removeOne,
}
