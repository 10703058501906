import { all, takeEvery, select, put, call, putResolve } from 'redux-saga/effects'

import { STORE_TOKENS, REFRESH_TOKEN_ERROR, REMOVE_TOKENS } from '@piconetworks/pkg-network-middleware'

import Authorization, { authWithSocial, deauthorize, exchangeClaim, generateClaim } from '@/entities/Authorization'
import Creator, { creator } from '@/entities/Creator'
import Product from '@/entities/Product'
import PaymentMethods from '@/entities/PaymentMethods'
import Cart from '@/entities/Cart'
import User from '@/entities/User'
import FeatureFlags from '@/entities/FeatureFlags'
import Newsletters from '@/entities/Newsletters'
import {
    setOne as setOneRefreshToken,
    removeOne as removeOneRefreshToken,
    selectById,
} from '@/entities/RefreshTokens'
import CustomFields from '@/entities/CustomFields'
import PaymentHistory from '@/entities/PaymentHistory'
import ProductOptions from '@/entities/ProductOptions'
import Events from '@/entities/Events'

import analyticTakes from './analyticTakes'

import logger from '@/lib/logger'
import { addError } from '@/lib/rum'


function* storeTokens (response: any): any {
    yield putResolve({
        type: STORE_TOKENS,
        access_token: response?.payload?.token,
        refresh_token: response?.payload?.refresh_token
    })
}

function* saveRefreshToken (action: any): any {
    const _creator = yield select(creator)
    const currentRefreshToken = yield select((state: any) => selectById(state, _creator?.id))

    if (action?.refresh_token !== currentRefreshToken?.value) {
        yield put(
            yield call(setOneRefreshToken, {
                id: _creator?.id,
                value: action?.refresh_token
            })
        )
    }
}

function* removeRefreshToken (action: any): any {
    try {
        const _creator = yield select(creator)

        yield put(
            yield call(removeOneRefreshToken, {
                id: _creator?.id,
            })
        )

        // window.location.reload()
    } catch (error) {
        logger.error('removeRefreshToken', {}, error)
        addError(error)
    }
}

const removeTokens = function* () {
    yield put({ type: REMOVE_TOKENS })
}

function* generateOutboundClaim (): any {
    const _creator = yield select(creator)

    yield put(
        yield call(generateClaim, {
            publisherId: _creator?.id,
        })
    )
}

export default function* rootSagas() {
    yield all([
        ...Authorization.takes,
        ...Creator.takes,
        ...Product.takes,
        ...PaymentMethods.takes,
        ...Cart.takes,
        ...User.takes,
        ...FeatureFlags.takes,
        ...CustomFields.takes,
        ...Newsletters.takes,
        ...PaymentHistory.takes,
        ...ProductOptions.takes,
        takeEvery(exchangeClaim.fulfilled, storeTokens),
        takeEvery(authWithSocial.fulfilled, storeTokens),
        takeEvery(STORE_TOKENS, saveRefreshToken),
        takeEvery(STORE_TOKENS, generateOutboundClaim),
        takeEvery(REFRESH_TOKEN_ERROR, removeRefreshToken),
        takeEvery(deauthorize, removeTokens),
        ...Events.takes,
        ...analyticTakes,
    ])
}
